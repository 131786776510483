/* eslint-disable */
import React, { Component } from 'react';
import * as styles from 'styles/index.scss';

class CountdownTimer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: null,
      hours: null,
      minutes: null,
      seconds: null
    };
    this.tick = this.tick.bind(this);
  }

  tick() {
    let diff = this.props.time - new Date().getTime();
    if (diff / 1000 < 1) {
      if (typeof this.props.onComplete === 'function') {
        return this.props.onComplete();
      } else {
        return;
      }
    }
    const days = Math.floor(diff / 86400000);
    diff = diff % 86400000;
    const hours = Math.floor(diff / 3600000);
    diff = diff % 3600000;
    const minutes = Math.floor(diff / 60000);
    const seconds = Math.floor((diff % 60000) / 1000);
    this.setState({ days, hours, minutes, seconds });
    setTimeout(this.tick, 1000);
  }

  componentDidMount() {
    this.tick();
  }

  render() {
    const { days, hours, minutes, seconds } = this.state;
    const { color } = this.props;
    const className = this.props.className ? this.props.className : '';
    return (
      <div className={`countdown-holder ${color ? ` countdown-${color} ` : ''}${className}`}>
        <div className="countdown-timer text-center">
          {days ? (
            <div className="countdown-element">
              <div className="countdown-digit">{days}</div>{' '}
              <span className="countdown-label">day{days > 1 ? 's' : ''}</span>
            </div>
          ) : null}
          {days || hours ? (
            <div className="countdown-element">
              <div className="countdown-digit">{hours}</div>{' '}
              <span className="countdown-label">hour{hours > 1 ? 's' : ''}</span>
            </div>
          ) : null}
          {days || hours || minutes ? (
            <div className="countdown-element">
              <div className="countdown-digit">{minutes}</div>{' '}
              <span className="countdown-label">min{minutes > 1 ? 's' : ''}</span>
            </div>
          ) : null}
          <div className="countdown-element">
            <div className="countdown-digit">{seconds}</div>{' '}
            <span className="countdown-label">sec{seconds > 1 ? 's' : ''}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default CountdownTimer;
