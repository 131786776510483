/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Card,
  CardBody,
  Container,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu
} from 'reactstrap';
import * as styles from 'styles/index.scss';

const { addressLine } = styles;

class Address extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false
    };
  }

  toggleDropdown = () => this.setState((state, props) => ({ dropdownOpen: !state.dropdownOpen }));
  handleEditClick = () => this.props.showAddressModal(this.props.index);
  handleDeleteClick = () => this.props.deleteAddress(this.props.address);

  render() {
    const { dropdownOpen } = this.state;
    const {
      isDefaultBilling,
      isDefaultShipping,
      address: { name, address1, address2, city, state, postalCode, country }
    } = this.props;
    return (
      <Card>
        <CardBody>
          <Container fluid classNamew="p-0">
            <Row className="p-0 mt-1">
              <Col>
                <div className={addressLine}>
                  <span>{name}</span>
                </div>
                <div className={addressLine}>
                  <span>{address1}</span>
                </div>
                {address2 ? (
                  <div className={addressLine}>
                    <span>{address2}</span>
                  </div>
                ) : null}

                <div className={addressLine}>
                  <span>{`${city}, ${state} ${postalCode}`}</span>
                </div>
                <div className={addressLine}>
                  <span>{country || 'United States'}</span>
                </div>
                {isDefaultShipping || isDefaultBilling ? (
                  <div className={addressLine}>
                    <small className="text-muted">
                      This is your default {isDefaultBilling ? 'billing' : ''}
                      {isDefaultBilling && isDefaultShipping ? ' and ' : ' '}
                      {isDefaultShipping ? 'shipping' : ''} address.
                    </small>
                    <span></span>
                  </div>
                ) : null}
              </Col>
            </Row>
            <Row className="p-0 mt-1">
              <Col xs="auto" className="pr-2">
                <Button onClick={this.handleEditClick} size="sm" className="mr-2">
                  Edit
                </Button>
                <Button onClick={this.handleDeleteClick} size="sm">
                  Delete
                </Button>
              </Col>
              <Col xs="auto" className="pl-0">
                {!(isDefaultBilling && isDefaultShipping) ? (
                  <Dropdown isOpen={dropdownOpen} toggle={this.toggleDropdown}>
                    <DropdownToggle caret size="sm">
                      Set as default for
                    </DropdownToggle>
                    <DropdownMenu>
                      {!isDefaultBilling ? (
                        <DropdownItem onClick={() => this.props.makeAddressDefault(this.props.address, 'billing')}>
                          Billing
                        </DropdownItem>
                      ) : null}
                      {!isDefaultShipping ? (
                        <DropdownItem onClick={() => this.props.makeAddressDefault(this.props.address, 'shipping')}>
                          Shipping
                        </DropdownItem>
                      ) : null}
                    </DropdownMenu>
                  </Dropdown>
                ) : null}
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>
    );
  }
}

Address.propTypes = {
  address: PropTypes.shape({}).isRequired,
  isDefaultBilling: PropTypes.bool.isRequired,
  isDefaultShipping: PropTypes.bool.isRequired
};

export default Address;
