/* eslint-disable */

import React, { Component } from 'react';
import ReactMarkdown from 'lib/react-markdown';
import { Link } from 'react-router-dom';
import RecipeCard from 'routes/Products/components/RecipeCard';
import { Container, Row, Col } from 'reactstrap';
import * as styles from 'styles/index.scss';
import { track } from '../../../../lib/track';
//FIXME this could/should be a container

const { recipesListContainer, recipesListTitle, recipesListRecipes } = styles;

class RecipesList extends Component {
  render() {
    const path = location.pathname.split('/');
    const { description } = this.props.data || {};
    const { colSizes, Children, userAnalytics } = this.props;

    let thisProductInPath = path[2];
    const { disableViewAll } = this.props;

    return (
      <div className={this.props.className}>
        {Children.length ? (
          <Row className="align-items-end">
            <Col xs="auto">
              <h4>Recipes</h4>
            </Col>
            {!disableViewAll && (
              <Col xs="auto" style={{ marginBottom: '1.8rem' }}>
                <Link to={`/products/${thisProductInPath}/recipes`}>View all ...</Link>
              </Col>
            )}
          </Row>
        ) : null}
        {description && (
          <Row style={{ padding: 0 }}>
            <Col>
              <ReactMarkdown source={description} />
            </Col>
          </Row>
        )}
        <Row className={`${recipesListRecipes} justify-content-center`} style={{ padding: 0 }}>
          {Children.map((recipe, i) => {
            return (
              <Col
                {...colSizes}
                onClick={() => {
                  track(
                    'LINK_CLICK',
                    userAnalytics
                      ? { ...userAnalytics, ...{ link_type: 'recipe' } }
                      : { service: 'default', link_type: 'recipe' }
                  );
                }}
              >
                <RecipeCard {...recipe} className="h-100" />
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
}

export default RecipesList;
