/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from 'modules/user';
import { Button, Card, Input, Label, Modal, ModalHeader, ModalBody, Form, FormText, FormGroup } from 'reactstrap';
import * as styles from 'styles/index.scss';

const { passwordResetContainer, passwordResetButton, passwordResetButtonsBox, passwordResetFormInput } = styles;

@connect(state => ({ navigateTo: state.application.navigateTo }), { updateProfile: actions.updateProfile })
class ChangePasswordModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      password2: '',
      saving: false
    };
  }

  handleFieldChange = e => this.setState({ [e.target.name]: e.target.value });

  handleSubmit = e => {
    e.preventDefault();
    if (this.state.password === this.state.password2) {
      const profile = { password: this.state.password };
      this.setState({ saving: true });
      this.props.updateProfile(profile).then(resp => {
        if (resp) {
          if (typeof this.props.toggle === 'function') {
            this.props.toggle();
          }
          this.setState({ password: '', password2: '', saving: false });
        }
      });
    }
  };

  render() {
    const { password, password2, saving } = this.state;
    const passwordBlank = !(password.length && password2.length);
    const passwordsMatch = password === password2;
    return (
      <Modal centered isOpen={this.props.isOpen} toggle={this.props.toggle}>
        <ModalHeader toggle={this.props.toggle}>Change Password</ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="password">Password</Label>
              <Input
                type="password"
                id="password"
                name="password"
                value={password}
                required
                onChange={this.handleFieldChange}
              />
            </FormGroup>
            <FormGroup>
              <Label for="password2">Confirm Password</Label>
              <Input
                type="password"
                id="password2"
                name="password2"
                value={password2}
                required
                onChange={this.handleFieldChange}
              />
              {!passwordsMatch && !passwordBlank ? <FormText color="danger">Passwords must match</FormText> : null}
            </FormGroup>
            <Button onClick={this.handleSubmit} disabled={saving || !passwordsMatch || passwordBlank}>
              {saving ? 'Saving...' : 'Save Password'}
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}

ChangePasswordModal.propTypes = {};
//   updateProfile: PropTypes.func.isRequired
// };

export default ChangePasswordModal;
