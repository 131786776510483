import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCommentsAlt } from '@fortawesome/pro-regular-svg-icons/faCommentsAlt';
import * as styles from 'styles/index.scss';

//Simple component
//Displays some welcome text and a link to Forum
//props.handleNav is defined in Dashboard
function DashboardHeader(props) {
  const { handleNav, isUnstructured } = props;
  return (
    <div className=" d-flex align-items-center justify-content-between dashboardHeader" style={{ flexGrow: 1 }}>
      <h1>Welcome </h1>
      {isUnstructured ? null : (
        <div
          className={`text-success pointerGlobal d-none d-md-flex`}
          style={{ fontSize: '20px' }}
          onClick={() => handleNav('community')}
        >
          <FontAwesomeIcon color="#28a745" icon={faCommentsAlt} />
          <span className="px-3 dashboardHeader">Join the discussion</span>
        </div>
      )}
    </div>
  );
}

export default DashboardHeader;
