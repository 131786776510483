import React from 'react';
import PropTypes from 'prop-types';
import { Card } from 'reactstrap';
import Video from '../../../Video';
import * as styles from 'styles/index.scss';

const { collectionListItemContentTitle, listItem } = styles;

const ListItem = props => (
  <Card className={listItem}>
    <h3 className={collectionListItemContentTitle}>{props.title}</h3>
    <Video {...props} />
  </Card>
);

ListItem.propTypes = {
  title: PropTypes.string.isRequired
};

export default ListItem;
