/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as styles from 'styles/index.scss';
import Markdown from 'routes/Products/components/Markdown';

const { audioPlayerMobileTextContainer, apmMakeMeBlue } = styles;

const Text = props => {
  const {
    data: { description }
  } = props.Children.find(child => child.type === 'bio') || {};
  const { isWide } = props;
  return (
    <div className={audioPlayerMobileTextContainer}>
      <Markdown source={description} escapeHtml={false} />
    </div>
  );
};

Text.propTypes = {};

export default Text;
