import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions } from 'modules/user';
import { actions as applicationActions } from 'modules/application';
import { actions as modalActions } from 'modules/modal';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
// import AuthedOnlyUserDisplay from 'containers/AuthedUserRoute';
import SideBarItem from './components/SideBarItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FRNLogo from 'images/SVG/FRN';
import * as styles from 'styles/index.scss';

const { WLC_PRODUCT_NAME } = process.env;
const { WLC_SLUG } = process.env;

const {
  makeSideBarVisible,
  sideBarArrowIcon,
  sideBarContainer,
  sideBarFooter,
  sideBarLogo,
  sideBarMain,
  sideBarNavExt,
  sideBarOverview,
  hideThis
} = styles;

@connect(state => ({ user: state.user }), {
  getUserProducts: actions.getUserProducts,
  navigateTo: applicationActions.navigateTo
})
class SideBar extends PureComponent {
  constructor(props) {
    super(props);

    // innerWidth of the window, used for handling resize
    // ... and hiding sidebar if user goes from md to lower screen width
    this.state = {
      innerWidth: null,
      showMeOnResize: false,
      showSideBarFooter: true
    };
  }

  componentDidMount() {
    if (this.props.user.isAuthenticated) {
      this.props.getUserProducts();
    }
    this.handleFooterDisplay();
    document.body.onresize = this.handleResizeWindow;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.user.isAuthenticated && this.props.user.isAuthenticated) {
      this.props.getUserProducts();
    }
  }

  componentWillUnmount() {
    document.body.onresize = null;
  }

  handleResizeWindow = e => {
    // I might not work on other browsers?
    const { innerWidth } = e.srcElement;
    const { showSideBar, toggleSideBar } = this.props;
    const { showMeOnResize } = this.state;
    // if the window transitions to below 768 in width, close sidebar:
    if (this.state.innerWidth >= 768 && innerWidth < 768) {
      if (showSideBar) {
        toggleSideBar();
        this.setState({ showMeOnResize: true });
      } else if (showMeOnResize) {
        this.setState({ showMeOnResize: false });
      }
    }
    // if the window had been resized and then transitions BACK to above 768 in width, re-open sidebar:
    if (this.state.innerWidth && this.state.innerWidth <= 768 && innerWidth > 768) {
      if (showMeOnResize && !showSideBar) {
        toggleSideBar();
      }
    }

    //don't show sidebar nav footer if screen too short
    this.handleFooterDisplay();
    this.setState({ innerWidth });
  };

  handleFooterDisplay = () => {
    window.innerHeight < 540 ? this.setState({ showSideBarFooter: false }) : this.setState({ showSideBarFooter: true });
  };
  navigateSomewhere = location => () => {
    // this.props.navigateTo(navigateToLocation);
    this.props.history.push(location);
    // Close the side bar for small devices on navigate
    if (document.body.clientWidth <= 768) {
      this.props.toggleSideBar();
    }
    return true;
  };

  renderSideBarItemItem = (location, title, icon) => {
    return <SideBarItem title={title} icon={icon} handleClick={this.navigateSomewhere(location)} />;
  };

  render() {
    // const isLanding = window.location.pathname.split('/').length < 2 || !window.location.pathname.split('/')[1];
    // if (isLanding) return null;

    const path = location.pathname.split('/');
    const thisProductInPath = path[2];
    const isCheckout = path[1] === 'checkout';
    const isLanding = path[1] === 'landing';

    if (isCheckout || isLanding) {
      return null;
    }

    const {
      openLoginModal,
      showSideBar,
      toggleSideBar,
      user: { isAuthenticated, products }
    } = this.props;
    const userHasWLC = products.some(product => product.slug === WLC_SLUG);

    const myProductsItems = products.slice();
    myProductsItems.push({ name: 'All My Products', slug: '' });

    const visibilityClass = showSideBar ? makeSideBarVisible : '';

    return (
      <div className={`${sideBarContainer} ${visibilityClass}`}>
        <div>
          <div className={sideBarNavExt}>
            <FontAwesomeIcon onClick={toggleSideBar} className={sideBarArrowIcon} icon={['far', 'times']} />
          </div>
          <div className={sideBarLogo}>
            <FRNLogo />
          </div>
          {isAuthenticated ? (
            <div className={sideBarMain}>
              <div className={sideBarOverview}>
                <span>OVERVIEW</span>
              </div>
              {userHasWLC && this.renderSideBarItemItem(`/products/${WLC_SLUG}/`, 'WHOLE Life Club', ['frn', 'WLC'])}
              {userHasWLC &&
                this.renderSideBarItemItem(`/products/${WLC_SLUG}/action-hours`, 'Action Hours', ['fas', 'video'])}
              {products.length && this.renderSideBarItemItem(`/products/`, 'My Products', ['fas', 'box-open'])}
              {userHasWLC &&
                this.renderSideBarItemItem(`/products/${WLC_SLUG}/community`, 'Community Forum', ['fas', 'users'])}
            </div>
          ) : null}
          {/* if vertical height is too small, we hide the sideBarFooter to avoid overlap */}
          <div className={`${sideBarFooter} ${this.state.showSideBarFooter ? '' : hideThis}`}>
            <small>
              Looking for the Summit Empowerment Package, Plant-Powered and Thriving, or other products?{' '}
              <a href="https://members.foodrevolution.org" target="_blank">
                Click here
              </a>{' '}
              to go to{' '}
              <a href="https://members.foodrevolution.org" target="_blank">
                Food Revolution Network's Member Center
              </a>
              .
            </small>
          </div>
        </div>
      </div>
    );
  }
}

SideBar.propTypes = {};
//   user: PropTypes.shape({
//     isAuthenticated: PropTypes.bool.isRequired,
//     products: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired
//   }).isRequired
// };

const mapStateToProps = state => ({
  showSideBar: state.application.showSideBar
});

const mapDispatchToProps = {
  toggleSideBar: applicationActions.toggleSideBar,
  openLoginModal: () => modalActions.openModal('login')
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(SideBar);
