import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'lib/react-markdown';
import { Card } from 'reactstrap';
import * as styles from 'styles/index.scss';

const { recipeNotesContainer, recipeDirectionsTitle } = styles;

const Notes = props => (
  <Card className={recipeNotesContainer}>
    {props.notes &&
      props.notes.map((note, index) => {
        const hackyIndex = index + 300;
        return (
          <div>
            {note.title && <em>{note.title}</em>}
            <ReactMarkdown source={note.text} />
          </div>
        );
      })}
    {props.attribution && (
      <p>
        {props.attribution.text}{' '}
        {props.attribution.url ? (
          <a href={props.attribution.url} target="_blank">
            {props.attribution.name}
          </a>
        ) : (
          props.attribution.name
        )}
      </p>
    )}
  </Card>
);

Notes.propTypes = {};

export default Notes;
