/* eslint-disable */
import React, { useRef, useState } from 'react';

import { formatDistanceToNow } from 'date-fns';
import { useUserState } from './context/user';
import { useMessageState, useMessageDispatch } from './context/message';
import { useEnvState } from './context/env';

import { Button } from 'reactstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons/faAngleDown';

import { generateClient } from 'aws-amplify/api';

import ModeratorFeatures from './ModeratorFeatures';
import CreateMessageBox from './CreateMessageBox';

import * as mutations from './graphql/mutations';

import { getMessageBatch, getUser } from './utils';
import { useDebouncedCallback } from 'use-debounce';
import ReactMarkdown from 'react-markdown';

import VisibilitySensor from 'react-visibility-sensor';

export default function Messages() {
  const { id: userId, moderator } = useUserState();
  const messageDispatch = useMessageDispatch();
  const messageState = useMessageState();

  const envState = useEnvState();

  const messageAnchorElement = React.useRef<HTMLDivElement>(null);
  const internalMessageAnchorId = React.useRef<string>('');
  const messageWrapperElement = React.useRef<HTMLDivElement>(null);
  const currentVisibleElements = React.useRef<any[]>([]);
  const [nextToken, setNextToken] = React.useState(messageState.nextToken);

  const [displayedMessages, setDisplayedMessages] = React.useState<any[]>([]);
  const [loaded, setLoaded] = React.useState(false);
  const [userInfo, setUserInfo] = React.useState<any>({});

  const client = generateClient();

  React.useEffect(() => {
    let sortedMessages = messageState.messages;
    setDisplayedMessages(moderator ? sortedMessages : sortedMessages.filter((m: any) => !m.hidden && !m.userBlocked));
  }, [userId]);

  React.useEffect(() => {
    setDisplayedMessages(messageState.displayedMessages);
  }, [messageState.displayedMessages]);

  // on load, scroll to last comment
  React.useEffect(() => {
    if (displayedMessages.length) {
      const messageAnchorId = `message-${displayedMessages.slice(-1)[0].id}`;
      // initialize context and the internal anchor
      messageDispatch({ type: 'SET_CURRENT_MESSAGE_ANCHOR', payload: messageAnchorId });
      internalMessageAnchorId.current = messageAnchorId;
    }
  }, [loaded]);

  React.useEffect(() => {
    if (!loaded && displayedMessages.length) {
      setLoaded(true);
      scrollToBottom();
    }
    // set the anchor to the latest internal reference - good if we are switching screens or something else is happening
    messageDispatch({ type: 'SET_CURRENT_MESSAGE_ANCHOR', payload: internalMessageAnchorId.current });
  }, [displayedMessages]);

  async function toggleRead(messageId: string, currentStatus: string) {
    await client.graphql({
      query: mutations.updateMessage,
      variables: {
        input: {
          id: messageId,
          readByParentCommentUser: !currentStatus
        }
      }
    });
    messageDispatch({
      type: 'SET_UNREAD_NOTIFICATIONS_COUNT',
      payload: messageState.unreadNotificationsCount + (currentStatus ? 1 : -1)
    });
  }

  const scrollIntoView = function (element: any) {
    if (!(element && element.current && element.current.offsetTop)) return;
    const el = messageWrapperElement.current;
    if (!el) return;
    el.scrollTop = element.current.offsetTop;
  };

  React.useEffect(() => {
    const doScroll = async () => {
      // allows this to work for un-logged in users
      const delay = () => {
        return new Promise((resolve: any) => setTimeout(resolve, 100));
      };
      await delay();
      scrollIntoView(messageAnchorElement);
    };
    doScroll();
  }, [displayedMessages]);

  // set up a poller to update the message anchor on regular interval (default to 5s if nothing passed down in config)
  const POLLING_INTERVAL = envState.pollingInterval || 5000;

  const setChildAsAnchor = (parentCommentId: string) => {
    // find the latest child of this comment the user can see and set it as the anchor
    const latestMessageInThread = messageState.messages
      .filter((m: any) => m.ParentCommentId === parentCommentId || m.id === parentCommentId)
      .filter((m: any) => (moderator ? m : !m.hidden && !m.userBlocked))
      .sort((a: any, b: any) => b.createdAt.localeCompare(a.createdAt))[0];

    // Thanks to the or statement in the first filter the above is guaranteed to exist
    internalMessageAnchorId.current = `message-${latestMessageInThread.id}`;
  };

  const updateMessageAnchor = () => {
    if (!messageState.displayedUserId && !messageState.inNotificationScreen) {
      const lastMessageAnchor = currentVisibleElements.current
        .filter(m => m)
        .sort((a, b) => -1 * a.createdAt.localeCompare(b.createdAt))[0];

      const lastMessageAnchorString = `message-${lastMessageAnchor && lastMessageAnchor.id}`;
      // only set the internal ref so we don't re-render
      internalMessageAnchorId.current = lastMessageAnchorString;
    }
  };

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      !messageState.displayedUserId && !messageState.inNotificationScreen && updateMessageAnchor();
    }, POLLING_INTERVAL);

    return () => clearInterval(intervalId); // Cleanup on component unmount
  }, [messageState.displayedUserId, messageState.inNotificationScreen]);
  // end polling functions

  React.useEffect(() => {
    // get user info
    const userInfo = async () => {
      if (messageState.displayedUserId) {
        const retrievedUserInfo = await getUserInfo(null);
        setUserInfo(retrievedUserInfo);
      } else {
        setUserInfo({});
      }
    };
    userInfo();
  }, [messageState.displayedUserId]);

  const getUserInfo = async (userId: string | null) => {
    const retrievedUserInfo = await getUser(userId || messageState.displayedUserId, envState);
    return retrievedUserInfo;
  };

  const getPosts = async () => {
    const allMessagesPayload = (await getMessageBatch({
      category: messageState.category,
      sortDirection: 'DESC',
      nextToken: nextToken || messageState.nextToken
    })) as { data: any };
    const allMessages = allMessagesPayload?.data?.messagesByDate?.items;
    const token = allMessagesPayload?.data?.messagesByDate?.nextToken;
    messageDispatch({ type: 'ADD_MESSAGES', payload: allMessages });
    messageDispatch({ type: 'SET_NEXT_TOKEN', payload: token });
    setNextToken(token);
  };

  interface IMessageProps {
    message: any;
    className?: string;
  }

  const chatBottom = useRef<null | HTMLDivElement>(null);
  const scrollToBottom = () => {
    const el = messageWrapperElement.current;
    if (!el) return;
    el.scrollTop = el.scrollHeight;
  };

  const [scrollButtonVisible, setScrollButtonVisible] = useState(false);
  const scrollHandler = useDebouncedCallback(target => {
    if (!(target && target.scrollTop)) return;
    if (target.scrollTop + 50 >= target.scrollHeight - target.offsetHeight) {
      setScrollButtonVisible(false);
    } else {
      setScrollButtonVisible(true);
    }
  }, 10);

  const UserInfoComponent = function () {
    return (
      <div className="userInfo px-4">
        <p>
          <b>Name:</b> {userInfo.name}
        </p>
        <p>
          <b>Email:</b> {userInfo.email}
        </p>
      </div>
    );
  };

  const MessageWrapper = function ({ message }: IMessageProps) {
    // use this for all messages that don't have a ParentCommentId or for Notification screen
    return (
      <>
        <VisibilitySensor
          key={message.id}
          containment={messageWrapperElement.current}
          onChange={(isVisible: boolean) => {
            if (isVisible) {
              // add to current visible elements
              currentVisibleElements.current = [
                ...currentVisibleElements.current,
                { id: message.id, createdAt: message.createdAt }
              ];
            } else {
              // pop out of current visible elements
              currentVisibleElements.current = currentVisibleElements.current.filter(e => e.id !== message.id);
            }
          }}
        >
          <>
            {(!message.ParentCommentId ||
              messageState.inNotificationScreen ||
              (userId && messageState.displayedUserId === userId)) && <Message message={message} />}
            {message.displayThread ? (
              <div className="message-thread ms-4 ps-3">
                {messageState.messages
                  .filter((m: any) => m.ParentCommentId === message.id)
                  .filter((m: any) => (moderator ? m : !m.hidden && !m.userBlocked))
                  .sort((a: any, b: any) => a.createdAt.localeCompare(b.createdAt))
                  .map((m: any) => {
                    return <Message message={m} className="message-reply" />;
                  })}
              </div>
            ) : null}
          </>
        </VisibilitySensor>
      </>
    );
  };

  const Message = function ({ message, className }: IMessageProps) {
    // everything leads here in the end
    const [messageEmail, setMessageEmail] = React.useState(null);
    return (
      message &&
      (moderator || (!message.hidden && !message.userBlocked)) && (
        <div
          key={message.id}
          id={`message-${message.id}`}
          ref={messageState.currentMessageAnchor === `message-${message.id}` ? messageAnchorElement : null}
          className={classNames(
            `message-wrap`,
            `p-3`,
            `my-3`,
            `rounded`,
            className,
            message.userID === userId && 'message-wrap-user'
          )}
        >
          <>
            {message.hidden && <div className="alert-success rounded p-2 mb-3">This message has been hidden.</div>}
            {message.userBlocked && (
              <div className="alert-success rounded p-2 mb-3">
                This user has been blocked and all their messages hidden.
              </div>
            )}
            <div className="message-header">
              <div className="message-user d-flex align-items-center">
                {message.picture && (
                  <img
                    className="message-avatar rounded-circle me-2"
                    src={message.picture}
                    alt="user avatar graphic"
                    style={{ maxHeight: '40px', aspectRatio: '1/1' }}
                  />
                )}
                <div className="message-user-name">
                  <span className="text-800">{message.userName}</span> {message.userID === userId && <>(me)</>}
                  {moderator
                    ? messageEmail || (
                        <Button
                          className="btn text-small ms-2"
                          color="mod-link"
                          onClick={async () => {
                            const retrievedUserInfo = await getUserInfo(message.userID);
                            setMessageEmail(retrievedUserInfo.email);
                          }}
                        >
                          Display User Email
                        </Button>
                      )
                    : null}
                  {moderator && (
                    <Button
                      className="btn text-small ms-2"
                      color="mod-link"
                      onClick={() => {
                        messageDispatch({ type: 'SHOW_USER_MESSAGES', payload: message.userID });
                      }}
                    >
                      See All User Posts
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <ReactMarkdown className="message-content my-4">{message.raw}</ReactMarkdown>
            <div className="message-footer">
              <div className="message-time mb-1">
                <time dateTime="2023-01-23T15:56" className="text-medium-gray text-small">
                  {`${formatDistanceToNow(new Date(message.createdAt), { includeSeconds: true })} ago`}
                </time>
              </div>
              {!!message.numOfReplies && (
                <>
                  <button
                    className="btn btn-reply me-2"
                    type="button"
                    onClick={() => {
                      messageDispatch({
                        type: message.displayThread ? 'COLLAPSE_COMMENT_THREAD' : 'EXPAND_COMMENT_THREAD',
                        payload: message.id
                      });
                      setChildAsAnchor(message.id);
                    }}
                  >
                    {message.displayThread ? 'Hide' : 'See'} {message.numOfReplies}{' '}
                    {message.numOfReplies > 1 ? 'Replies' : 'Reply'}
                  </button>
                </>
              )}
              {!message.ParentCommentId && userId && (
                <>
                  <button
                    className="btn btn-reply"
                    type="button"
                    onClick={() => {
                      messageDispatch({ type: 'EXPAND_COMMENT_THREAD', payload: message.id });
                      setChildAsAnchor(message.id);
                    }}
                  >
                    Reply to Message
                  </button>
                </>
              )}
              {(messageState.inNotificationScreen || messageState.displayedUserId) && (
                <>
                  {/*`MessageID ${message.id}`*/}
                  {/*`Am I read??? ${message.readByParentCommentUser}`*/}
                  <button
                    className="btn btn-reply ms-2"
                    type="button"
                    onClick={() => {
                      messageDispatch({ type: 'LEAVE_SUB_SCREEN' });
                      const messageAnchorId = `message-${message.id}`;
                      // set both context and ref if we are navigating to a message
                      messageDispatch({ type: 'SET_CURRENT_MESSAGE_ANCHOR', payload: messageAnchorId });
                      internalMessageAnchorId.current = messageAnchorId;
                      messageState.inNotificationScreen &&
                        !message.readByParentCommentUser &&
                        toggleRead(message.id, message.readByParentCommentUser);
                      message.ParentCommentId &&
                        messageDispatch({ type: 'EXPAND_COMMENT_THREAD', payload: message.ParentCommentId });
                    }}
                  >
                    Go to Message
                  </button>
                </>
              )}
              {messageState.inNotificationScreen || (userId && message.userToNotifyID === userId) ? (
                <button
                  className="btn btn-reply ms-2"
                  type="button"
                  onClick={() => {
                    toggleRead(message.id, message.readByParentCommentUser);
                  }}
                >
                  {`Mark as ${message.readByParentCommentUser ? 'Unread' : 'Read'}`}
                </button>
              ) : null}
            </div>
            {moderator && <ModeratorFeatures message={message} />}
          </>
        </div>
      )
    );
  };

  React.useEffect(() => {
    const links = document.querySelectorAll('.chat-messages a');
    links.forEach(link => {
      link.setAttribute('target', '_blank');
    });
  });

  return (
    <div className="chat-inner">
      {messageState.displayedUserId && !messageState.inNotificationScreen && Object.keys(userInfo).length && (
        <UserInfoComponent />
      )}
      <Button
        color="scroll"
        onClick={scrollToBottom}
        className={scrollButtonVisible ? 'scrollVisible' : 'scrollHidden'}
      >
        <FontAwesomeIcon icon={faAngleDown} />
      </Button>
      <div
        className="chat-messages overflow-auto p-3"
        onScroll={e => {
          const target = e.currentTarget;
          scrollHandler(target);
        }}
        ref={messageWrapperElement}
      >
        {!messageState.displayedUserId && !messageState.inNotificationScreen && messageState.nextToken && (
          <div className="text-end">
            <Button
              className="btn text-small"
              color="load-more"
              onClick={() => {
                getPosts();
              }}
            >
              Load More Comments
            </Button>
          </div>
        )}
        {displayedMessages.map(message => {
          return (
            <>
              <MessageWrapper message={message} key={`message-${message.id}`} />
              {message.displayThread && userId && (
                <CreateMessageBox
                  id={`reply-box-${message.id}`}
                  parentId={message.id}
                  parentMessageUserId={message.userID}
                  placeholderText="Add a Reply..."
                />
              )}
            </>
          );
        })}
        <div ref={chatBottom} />
      </div>
    </div>
  );
}
