import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import * as styles from 'styles/index.scss';

const { genericInputContainer, inputRangeTicks } = styles;

class GenericInput extends PureComponent {
  constructor(props) {
    super(props);
    const { input, placeholder } = props.data;
    let value;
    if (placeholder) {
      value = placeholder;
    } else {
      value = input === 'range' ? 5 : '';
    }

    this.state = { pristine: true, value };
  }

  handleInput = ({ target: { value } }) => {
    this.setState({ ...this.state, value });
  };

  handleClickTick = value => {
    this.setState({ ...this.state, value });
  };

  handleKeyDown = e => {
    if (e.key == 'Enter') {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  render() {
    const {
      id,
      name,
      value,
      data: { options, text }
    } = this.props;
    const inputType = this.props.data.input;

    if (inputType === 'select') {
      return (
        <div className={genericInputContainer}>
          <label htmlFor={name}>{text}</label>
          <select
            type={inputType}
            min="0"
            max="10"
            step="1"
            onChange={this.props.change}
            onBlur={this.props.post}
            name={name}
            id={id}
            value={value}
          >
            {options && options.map(option => <option key={option}>{option}</option>)}
          </select>
        </div>
      );
    }

    return (
      <div className={genericInputContainer}>
        <label htmlFor={name}>{text}</label>
        <input
          style={{ cursor: inputType === 'range' ? 'pointer' : null }}
          type={inputType}
          min="1"
          max="10"
          step="1"
          onKeyDown={this.handleKeyDown}
          onChange={this.props.change}
          onBlur={() => console.log('blur survey field/deprecated functionality')}
          name={name}
          id={id}
          value={value}
        />
        {inputType === 'range' ? (
          <div className={inputRangeTicks}>
            {Array.apply(null, Array(10)).map((e, i) => (
              <i style={{ cursor: 'pointer' }} onClick={() => this.props.change({ target: { name, value: i + 1 } })}>
                {i + 1}
              </i>
            ))}
          </div>
        ) : null}
      </div>
    );
  }
}

GenericInput.propTypes = {
  // TODO
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  data: PropTypes.shape({}).isRequired
};

GenericInput.defaultProps = {
  id: 88888888
};

export default GenericInput;
