/* eslint-disable max-len */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Alert, Button, Card, CardBody, CardHeader, Col, Container, Modal, ModalBody, Row } from 'reactstrap';
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { ForumWrapper } from 'components/ForumWrapper';
import WLCVideo from 'routes/Products/products/WLC/components/WLCVideo';
import Checklist from 'routes/Products/components/Checklist';
import RecipesList from 'routes/Products/components/RecipesList';
import ResourcesList from 'routes/Products/components/ResourcesListTwo';
import WeekNav from './components/WeekNav';
import { actions as userActions } from 'modules/user';
import * as styles from 'styles/index.scss';
import getContent from 'lib/getContent';
import { getWeekNameDate } from 'lib/time';
import { page } from '../../../../lib/track';
const {
  weekRenderOnboardAlert,
  weekRenderOnboardNav,
  weekRenderOnboardCompleteButton,
  weekRenderOnboardSuccessModal,
  weekRenderOnboardSuccessModalButtons,
  // weekRenderOnboardNavTitle,
  weekChecklistAndResources,
  weekRenderOnboardNavContainer,
  weekContainer
} = styles;

@connect(state => ({ user: state.user, authToken: state.user.authToken }), {})
//WLC index --> Week --> ForumWrapper
class Week extends PureComponent {
  constructor(props) {
    super(props);
    //in our weekly guide home page we show the latest week and we normally use href location.
    //in this case we need to use the exact URL for pageLocation instead of producs/wlc
    this.Children = {};
    // props.Children.map(child => {
    //   this.Children[`${child.type}`] = child;
    // });
    this.scrollToContent = this.scrollToContent.bind(this);
    const thisPath = location.pathname.split('/');
    this.productInPath = thisPath[2]; // will be 'wlc'
    let { slug } = this.props.match.params;
    if (this.props.overrideSlug) slug = this.props.overrideSlug;
    this.isOnboard = slug.includes('getting-started'); //is onboard,  BUT other weeks could exist

    // onBoardIsOnlyWeek, iow don't allow option to navigate to this week, //this is onboard and no other weeks exist
    // this.onBoardIsOnlyWeek = props.sortedWeeksList.length <= 1;

    const initialState = {
      error: null,
      errorInfo: null,
      steps: [],
      complete: false, // is onboard/ getting-started complete
      dismissedTimes: 0, // only used for onboard case
      checklistComplete: true,
      runTour: true,
      showCompleteModal: false,
      showOnboardSkipModal: false
    };

    if (this.isOnboard && this.videoId && this.checklistId) {
      initialState[this.checklistId] = false;
      // initialState[this.videoId] = false;
    }

    this.state = { contentItem: null, ...initialState };
    this.tourSteps = [
      {
        title: 'Welcome to the Weekly Guide!',
        content:
          'Each week a new guide is released with action items, recipes and resources covering a specific topic.',
        target: 'body',
        placement: 'center'
      },
      {
        title: 'Action Of The Week',
        content:
          'Each week you’ll see a new Action Of The Week video from Ocean. You can enable closed captioning by clicking the CC button once the video starts playing.',
        target: '.wlcVideoContainer .videoWrapper',
        placement: 'auto'
      },
      {
        target: '.recipesListRecipes div:first-child',
        title: 'Recipes',
        content:
          'You will see 5 new recipes on each Weekly Guide page. Click on an image to see that particular recipe.',
        placement: 'auto'
      },
      {
        target: '#WLCWeekResources .card:first-child',
        title: 'Bonus resources',
        content:
          'Check this section out each week to see the additional materials we’ve provided to help you get the most out of the week’s topic.',
        placement: 'auto'
      },
      {
        target: '.post-form',
        title: 'Post a comment',
        content: 'Post your answer to the weekly question and other takeaways in a new comment box here.',
        placement: 'auto'
      }
    ];

    //used for scrolling to comments section on notification links
    this.getWeek = this.getWeek.bind(this);
    this.commentsWrapper = React.createRef();
    this.highlightedComment = React.createRef();
  }

  componentDidMount() {
    if (this.isOnboard) {
      this.getState();
    }
    this.getWeek();
  }
  //get this week from api
  getWeek() {
    const product = location.pathname.split('/')[2];
    let { slug } = this.props.match.params;
    if (this.props.overrideSlug) slug = this.props.overrideSlug;
    getContent(slug, product, this.props.authToken)
      .then(res => {
        let thisWeek;
        this.setState({
          contentItem: res,
          thisWeek,
          title: res.title, //for convenniece and legacy code
          video: res.Children.find(item => item.type === 'video'),
          checklist: res.Children.find(item => item.type === 'checklist'),
          recipesList: res.Children.find(item => item.type === 'recipesList'),
          resourcesList: res.Children.find(item => item.type === 'resourcesList'),
          userAnalytics: {
            service: 'weekly_guide',
            content_id: res.id,
            slug: res.slug,
            title: res.title,
            uuid: this.props.user && this.props.user.UUID
          }
        });
        page('weekly guide', {
          service: 'weekly_guide',
          contentId: res.id,
          slug: res.slug,
          title: res.title,

          uuid: props.user && props.user.UUID
        });
      })

      .catch(e => {
        console.log('error getting Weeks in Weeks Page', e.message);
      });
  }

  //Following several methods only for getting started/'onboard' week
  async getState() {
    const savedState = await this.props.get(this.id);
    if (savedState) {
      this.setState({ ...savedState, loading: false, showOnboardSkipModal: false, showCompleteModal: false });
    }
  }

  saveState = stateItem => {
    const newState = { ...this.state, ...stateItem };
    this.props.post(this.id, newState);
    this.setState((prevState, props) => ({ ...prevState, ...stateItem }));
    //this.setState(stateItem);
  };

  //This is given to checklist
  handleOnboardComponentUpdate = id => {
    const newState = { ...this.state };
    newState.checklistComplete = true;
    newState.complete = true;
    this.saveState({ ...newState });
    this.completeOnboard();
  };

  //scroll to comments section
  scrollToContent() {
    //timout used to ensure the reference exists when it is called by child
    setTimeout(() => {
      //const offSet = this.commentsWrapper.current.offsetTop - 100;
      const offSet =
        this.highlightedComment.current &&
        this.highlightedComment.current.scrollIntoView(false, { behavior: 'smooth' });
      window.scrollTo({
        top: offSet,
        behavior: 'smooth'
      });
    }, 1000);
  }
  //Unlikely this will be called/ complete triggers modal first
  handleIncomplete = id => {
    const newState = { ...this.state };
    newState.checklistComplete = false;
    this.saveState({ ...newState });
  };

  completeOnboard = () => {
    if (!this.state.complete) {
      this.saveState({ complete: true });
      // if (!this.onBoardIsOnlyWeek) {
      //     this.saveState({showCompleteModal: true});
      //   }
    }
  };

  handleUserMoveToCurrentWeek = () => {
    const dismissedTimes = this.state.dismissedTimes + 1;
    this.saveState({ dismissedTimes });
    this.moveToCurrentWeek();
  };

  handleUserSkip = () => {
    const { dismissedTimes } = this.state;
    if (dismissedTimes > 1) {
      const neverShowAgain = window.confirm('Click OK to skip this step.');
      if (neverShowAgain) {
        this.saveState({ neverShowAgain });
        this.moveToCurrentWeek();
      }
      // this.saveState({ neverShowAgain: true })
    }
  };

  handleUserClickComplete = () => {
    if (this.state.showCompleteModal) this.saveState({ showCompleteModal: false });
    this.moveToCurrentWeek();
  };

  handleUserCloseModal = () => {
    this.setState({ showCompleteModal: false });
  };

  moveToCurrentWeek = () => {
    window.PREVENT_NAV_TO_GETTING_STARTED = true;
    this.props.history.push(`/products/${this.productInPath}/current-week`);
  };

  handleOnboardAlertLinkClick = e => {
    const { checklistComplete, dismissedTimes } = this.state;
    e.preventDefault();
    if (this.state.checklistComplete || this.state.neverShowAgain) {
      return this.moveToCurrentWeek();
    }

    this.saveState({ ...this.state, dismissedTimes: dismissedTimes + 1 });
    if (dismissedTimes > 1) {
      this.setState({ showOnboardSkipModal: true });
    }
  };

  handleOnboardSkipModalButtonClick = (e, skip) => {
    if (skip) {
      this.saveState({ ...this.state, neverShowAgain: true });
    }
    this.moveToCurrentWeek();
  };

  renderOnboardNav = () => (
    <Alert color="primary" className={weekRenderOnboardAlert}>
      <p>
        You are currently viewing the Getting Started Welcome page. This page introduces you to the structure of the
        weekly guides you will have access to as a member of WHOLE Life Club. It includes a short action of the week,
        recipes, a simple action checklist, and more resources, and a chance to engage with the Community. Every time
        you come back to the club you will see this page until you have completed the short checklist at the bottom.
      </p>

      <p>
        If you're done with this page,{' '}
        <Link
          to={`/products/${this.productInPath}/current-week`}
          className="alert-link"
          onClick={this.handleOnboardAlertLinkClick}
        >
          click here to go to the most recent Weekly Guide
        </Link>
      </p>
    </Alert>
  );

  renderOnboardCompleteButton = () => {
    //zp if no week after this, then return null
    // if (this.onBoardIsOnlyWeek) {
    //   return null;
    // }
    return (
      <div className={weekRenderOnboardCompleteButton}>
        <Button color="purple" disabled={!this.state.complete} onClick={this.handleUserClickComplete}>
          {this.state.complete ? 'Continue to your first week' : 'Continue on when complete...'}
        </Button>
      </div>
    );
  };

  renderSuccessModal = () => {
    return (
      <Modal isOpen={this.state.showCompleteModal} centered>
        <ModalBody>
          <h6>Congratulations! You've completed the Getting Started Welcome Guide.</h6>
          <Container fluid>
            <Row className="d-flex justify-content-center">
              <Col xs="auto">
                <Button onClick={this.handleUserCloseModal}>Stay Here</Button>
              </Col>
              <Col xs="auto">
                <Button onClick={this.handleUserClickComplete} color="primary">
                  Go to the most recent Weekly Guide
                </Button>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    );
  };

  OnboardSkipModal = ({ isOpen }) => (
    <Modal isOpen={isOpen} centered>
      <ModalBody>
        <h6>Would you like to see this Getting Started Action Guide again?</h6>
        <Container fluid>
          <Row className="d-flex justify-content-center">
            <Col xs="auto">
              <Button onClick={e => this.handleOnboardSkipModalButtonClick(e, true)}>Yes</Button>
            </Col>
            <Col xs="auto">
              <Button onClick={e => this.handleOnboardSkipModalButtonClick(e, false)} color="light">
                No
              </Button>
            </Col>
          </Row>
        </Container>
      </ModalBody>
    </Modal>
  );

  //JOYRIDE Stuff
  handleJoyrideCallback = data => {
    const { status, type } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      this.setState({ runTour: false });
      this.props.updateTourStatus('week', true);
    }
  };

  render() {
    const { OnboardSkipModal } = this;

    const {
      user,
      refreshToken,
      // title,
      // data: {startTime, endTime},
      toursCompleted,
      toursLoaded
    } = this.props;
    const {
      checklist,
      complete,
      contentItem,
      recipesList,
      resourcesList,
      showOnboardSkipModal,
      showJoyride,
      title,
      userAnalytics,
      video
    } = this.state;

    //TODO, pass this in?
    const showTour = !!toursLoaded && !toursCompleted.week;
    // Note: every component here is ultimately wrapped in a Container-Col-Row
    // and if given borders only for md and up
    if (contentItem) {
      const {
        slug,
        data: { startTime, endTime }
      } = contentItem;
      const weekNameDate = slug === 'getting-started' ? title : `${title} (${getWeekNameDate(startTime, endTime)})`;

      return (
        <Container key={contentItem.id}>
          <ReactJoyride
            continuous
            callback={this.handleJoyrideCallback}
            run={this.state.runTour && showTour}
            steps={this.tourSteps}
            scrollToFirstStep
            showProgress
            showSkipButton
            styles={{
              options: {
                zIndex: 10000
              }
            }}
          />
          {this.isOnboard ? this.renderOnboardNav() : null}
          <Row>
            <Col className="pagetitle">
              <h5>Weekly Guide — {weekNameDate}</h5>
              <h1 style={{ display: 'inline' }}>{title}</h1>
            </Col>
            <Col xs={12} sm="auto" className="d-flex align-items-center">
              <WeekNav
                product="wlc"
                slug="wlc-18-weeks-list"
                // sortedWeeksList={this.props.sortedWeeksList}
                // {...this.props}
                // hideNav={this.onBoardIsOnlyWeek}
              />
            </Col>
          </Row>
          <WLCVideo {...video} userAnalytics={{ ...userAnalytics }} />
          <RecipesList
            userAnalytics={{ ...userAnalytics, ...{ link_type: 'resource' } }}
            {...recipesList}
            className="weekRecipesList"
            colSizes={{ xs: 12, sm: 10, md: 4 }}
          />
          <Row className={weekChecklistAndResources}>
            <Col xs={12} lg={6} className="weekChecklist" style={{ marginTop: '3.6rem' }}>
              {checklist && userAnalytics ? (
                <Card>
                  <CardBody>
                    <Checklist
                      userAnalytics={{ ...userAnalytics }}
                      style={{ marginBottom: '32px' }}
                      handleIncomplete={this.props.handleIncomplete}
                      handleComplete={this.handleOnboardComponentUpdate}
                      overrideTitle={this.isOnboard ? undefined : 'Weekly Action Items'}
                      HeaderWrapperTag={CardHeader}
                      {...checklist}
                    />
                  </CardBody>
                </Card>
              ) : null}
            </Col>
            <Col xs={12} lg={6} id="WLCWeekResources">
              <ResourcesList
                userAnalytics={{ ...userAnalytics, ...{ link_type: 'resource' } }}
                customTitle="Resources"
                {...resourcesList}
                colSizes={{ sm: 6, md: 4 }}
              />
            </Col>
          </Row>
          {/* ref used for scrolling to comments section */}
          <div ref={this.commentsWrapper}>
            {this.isOnboard && contentItem ? (
              <ForumWrapper
                service={'week'}
                product={this.productInPath}
                slug={slug}
                scrollToMe={this.scrollToContent}
                passedReactRef={this.highlightedComment}
                pageTitle={title}
                user={user}
                refreshToken={this.props.refreshToken}
                revokeDiscourseAPIKey={this.props.revokeDiscourseAPIKey}
                forumType="ContentItem"
              />
            ) : contentItem ? (
              <ForumWrapper
                service={'week'}
                product={this.productInPath}
                scrollToMe={this.scrollToContent}
                slug={slug}
                pageTitle={title}
                passedReactRef={this.highlightedComment}
                user={user}
                refreshToken={this.props.refreshToken}
                revokeDiscourseAPIKey={this.props.revokeDiscourseAPIKey}
                forumType="ContentItem"
              />
            ) : null}
          </div>
          {this.isOnboard && complete && this.renderSuccessModal()}
          {this.isOnboard && <OnboardSkipModal isOpen={showOnboardSkipModal} />}
        </Container>
      );
    } else {
      return <Container key="loading-week">Loading this week...</Container>;
    }
  }
}

Week.propTypes = {
  Children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  get: contendId => dispatch(userActions.getUserData(contendId)),
  post: (contentId, data) => dispatch(userActions.postUserData(contentId, data)),
  refreshToken: userActions.refreshToken,
  revokeDiscourseAPIKey: userActions.revokeDiscourseAPIKey
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Week);
