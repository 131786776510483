import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ToolsList from './components/ToolsList';
import * as styles from 'styles/index.scss';

const { toolsContainer, list, listTitle, subTitle } = styles;

class Tools extends PureComponent {
  constructor(props) {
    super(props);
    this.Children = props.Children;
  }

  render() {
    return (
      <div className={toolsContainer}>
        <h1 className={listTitle}>{this.props.title}</h1>

        {this.Children.map((toolsCategory, index) => (
          <div key={toolsCategory.title + String(index)} className={list}>
            <h5 className={subTitle}>{toolsCategory.title}</h5>
            <ToolsList tools={toolsCategory.Children} />
          </div>
        ))}
      </div>
    );
  }
}

Tools.propTypes = {
  Children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string.isRequired
};

export default Tools;
