/* eslint-disable max-len */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Button, Col, Container, Form, Progress, Row } from 'reactstrap';
import Input from 'components/Forms/Generic';
import { actions } from 'modules/user';
import SurveyPagedResults from './components/SurveyPagedResults';
import * as styles from 'styles/index.scss';
import ReactMarkdown from 'lib/react-markdown';

const {
  SurveyPagedButton,
  SurveyPagedButtonCol,
  SurveyPagedButtonRow,
  SurveyPagedCompleted,
  SurveyPagedContainer,
  SurveyPagedDescription,
  SurveyPagedHeadline,
  SurveyPagedTitle
} = styles;

class SurveyPaged extends PureComponent {
  SurveyQuestions = [];
  constructor(props) {
    super(props);
    // Children are each pages
    const {
      data: { pages },
      id
    } = props;
    // id is contentItem id
    this.id = id;
    this.initialValues = {};
    this.SurveyQuestions = pages.reduce((acc, cur) => acc.concat(cur.surveyQuestions), []);
    this.SurveyQuestions.map(question => {
      this.initialValues[question.name] = '';
    });

    this.state = {
      hasBegun: false,
      timeCompleted: null,
      values: this.initialValues,
      loading: true,
      page: -1
    };
  }

  componentDidMount() {
    this.getSurveyPagedResponses();
    window.scrollTo(0, 0);
  }

  async getSurveyPagedResponses() {
    const savedData = await this.props.get(this.id);
    if (savedData) {
      if (savedData.timeCompleted) savedData.page = -1;
      this.setState({ ...savedData, loading: false });
    } else {
      this.setState({ loading: false });
      // No saved values - do something?
    }
  }

  updateValue = ({ target: { name, value } }) => {
    const newState = { ...this.state, values: { ...this.state.values, [name]: value } };
    this.setState(newState);
    this.props.post(this.id, newState);
  };

  submit = () => {
    const newState = { ...this.state, timeCompleted: Date.now() };
    this.setState(newState);
    this.props.post(this.id, newState);
    if (typeof this.props.handleComplete === 'function') {
      this.props.handleComplete(this.id);
    }
    this.scrollToTop();
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  movePage = (e, inc) => {
    e.preventDefault();
    const page = this.state.page + inc;
    this.setState({
      page
    });
  };

  prevPage = e => this.movePage(e, -1);
  nextPage = e => this.movePage(e, 1);

  render() {
    const {
      // Children,
      data: { description, headline, pages },
      title
    } = this.props;

    const { SurveyQuestions } = this;

    const completed = Boolean(this.state.timeCompleted);
    // isInOther - iow this is not in an onboard, needs its own complete button
    const { loading, values } = this.state;
    const page = Math.max(-1, Math.min(this.state.page, pages.length));

    if (loading) {
      return <div>Loading . . . </div>;
    }
    const buttonAction = page < pages.length - 1 ? this.nextPage : this.submit;
    const buttonText = page === -1 ? 'Begin Survey' : page < pages.length - 1 ? 'Next' : 'Submit';
    const percent = Math.floor(((page + 1) / (pages.length + 1)) * 100);

    return (
      <div className={SurveyPagedContainer}>
        <h5>{headline}</h5>
        {page === -1 ? (
          <ReactMarkdown source={description} />
        ) : (
          <div style={{ padding: '0 8px' }}>
            <Progress value={percent}>{percent}% Complete</Progress>
            <Form>
              {pages[page].surveyQuestions
                .sort((a, b) => a.data.order - b.data.order)
                .map(question => {
                  if (false && !question.data.track) return null;
                  return (
                    <Input
                      value={values[question.name] || ''}
                      change={this.updateValue}
                      key={question.name}
                      {...question}
                    />
                  );
                })}
            </Form>
          </div>
        )}
        <Row className={SurveyPagedButtonRow}>
          {page > -1 ? (
            <Col className={SurveyPagedButtonCol}>
              <Button onClick={this.prevPage} block>
                Previous
              </Button>
            </Col>
          ) : null}
          <Col className={SurveyPagedButtonCol}>
            <Button onClick={buttonAction} block>
              {buttonText}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

SurveyPaged.propTypes = {};

SurveyPaged.defaultProps = {
  Children: [],
  id: 88888888
};

const mapDispatchToProps = dispatch => ({
  get: contendId => dispatch(actions.getUserData(contendId)),
  post: (contentId, data) => dispatch(actions.postUserData(contentId, data))
});

export const ConnectedSurveyPaged = connect(null, mapDispatchToProps)(SurveyPaged);

// Page level; this gets data
export default withRouter(ConnectedSurveyPaged);
