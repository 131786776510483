import React, { PureComponent } from 'react';

import { connect } from 'react-redux';
import { compose } from 'redux';
import { actions } from 'modules/user';
import { actions as applicationActions } from 'modules/application';
import { actions as modalActions } from 'modules/modal';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
// import AuthedOnlyUserDisplay from 'containers/AuthedUserRoute';
import SideBarItem from './components/SideBarItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FRNLogo from 'images/SVG/FRN';
import * as styles from 'styles/index.scss';
import { Button, Container, Row, Col } from 'reactstrap';
import { faList } from '@fortawesome/pro-regular-svg-icons/faList';
import Guidelines from '../../routes/Products/components/Guidelines';

import Markdown from '../../routes/Products/components/Markdown/index';
import Disclaimer from '../../routes/Products/products/PPTv2/components/Disclaimer/index';

const { WLC_PRODUCT_NAME } = process.env;
const { WLC_SLUG } = process.env;

const {
  makeSideBarVisible,
  sideBarArrowIcon,
  productSideBarContainer,
  sideBarFooter,
  sideBarLogo,
  sideBarMain,
  sideBarNavExt,
  sideBarOverview,
  hideThis,
  productSidebarChildren,
  showMoreNav,
  showMoreNavCloseMark,
  pptStyles
} = styles;

@connect(state => ({ user: state.user }), {
  getUserProducts: actions.getUserProducts,
  navigateTo: applicationActions.navigateTo,
  getUserData: actions.getUserData,
  post: actions.postUserData
})
class SideBar extends PureComponent {
  constructor(props) {
    super(props);

    // innerWidth of the window, used for handling resize
    // ... and hiding sidebar if user goes from md to lower screen width
    this.state = {
      innerWidth: null,
      showMeOnResize: false,
      showSideBarFooter: true,
      showMoreSideNav: false,
      showDisclaimer: false
    };
    this.disclaimerId = this.props.contentId;
  }

  componentDidMount() {
    if (this.props.user.isAuthenticated) {
      this.props.getUserProducts();
    }
    this.handleFooterDisplay();
    document.body.onresize = this.handleResizeWindow;
    this.setState({ showBottomNav: window.innerWidth < 576 });
    window.addEventListener('resize', this.updateDimensions);

    const userData = this.props.getUserData(this.disclaimerId);
    userData
      .then(userState => {
        //console.log('userState for disclaimer id: ', this.disclaimerId, userState);
        this.setState({ showDisclaimer: userState ? userState.showDisclaimer : true });
      })
      .catch(e => console.log('user data err ', e.message));
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.user.isAuthenticated && this.props.user.isAuthenticated) {
      this.props.getUserProducts();
    }
  }

  componentWillUnmount() {
    document.body.onresize = null;
  }

  handleResizeWindow = e => {
    // I might not work on other browsers?
    const { innerWidth } = e.srcElement;
    const { showSideBar, toggleSideBar } = this.props;
    const { showMeOnResize } = this.state;

    //don't show sidebar nav footer if screen too short
    this.handleFooterDisplay();
    this.setState({ innerWidth });
  };

  handleFooterDisplay = () => {
    window.innerHeight < 540 ? this.setState({ showSideBarFooter: false }) : this.setState({ showSideBarFooter: true });
  };
  navigateSomewhere = location => () => {
    this.props.history.push(location);
    // Close the side bar for small devices on navigate
    if (document.body.clientWidth <= 768) {
      this.props.toggleSideBar();
    }
    return true;
  };

  renderSideBarItemItem = (location, title, icon) => {
    return <SideBarItem title={title} icon={icon} handleClick={this.navigateSomewhere(location)} />;
  };
  //show the Full width nav bar on MOBILE
  handleMore = () => {
    this.setState({ showMoreNav: !this.state.showMoreNav }, () => {
      //console.log(this.state);
    });
  };

  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  componentWillMount = () => {
    this.updateDimensions();
  };
  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateDimensions);
  };

  handleDisclaimer() {
    this.setState({ showDisclaimer: false });
    const userData = this.props.getUserData(this.disclaimerId);
    userData
      .then(userState => {
        this.props
          .post(this.disclaimerId, { showDisclaimer: false })
          .then(res => {
            console.log('userstate res ', res);
          })
          .catch(e => {
            console.log('err user state ', e.message);
          });
      })
      .catch(e => console.log('user data err ', e.message));
  }

  render() {
    // const isLanding = window.location.pathname.split('/').length < 2 || !window.location.pathname.split('/')[1];
    // if (isLanding) return null;

    const path = location.pathname.split('/');
    const thisProductInPath = path[2];
    const slug = path[3] ? path[3] : '';

    const isCheckout = path[1] === 'checkout';
    const isLanding = path[1] === 'landing';

    if (isCheckout || isLanding) {
      return null;
    }

    const {
      openLoginModal,
      showSideBar,
      toggleSideBar,
      user: { isAuthenticated, products },
      specialStyles, //PPT has special layout for product sidebar
      disclaimerRequired
    } = this.props;
    const { showDisclaimer } = this.state;
    const userHasWLC = products.some(product => product.slug === WLC_SLUG);

    const myProductsItems = products.slice();
    myProductsItems.push({ name: 'All My Products', slug: '' });

    //for the bottom bar, a makes the left bar visible or bottom nav visible with flex box
    let visibilityClass = this.state.windowWidth > 576 ? makeSideBarVisible : 'd-flex justify-content-around';
    visibilityClass = this.state.showMoreNav ? 'hideThis' : visibilityClass;
    //for the side bar that comes out from the left
    const showMoreNavClass = this.state.showMoreNav ? 'makeVisible' : 'm';

    // Handles Nav for mobile footer or full screen side nav
    return (
      <div>
        {/* SHOW MORE NAV ON LEFT SIDE */}
        <div className={`d-print-none ${showMoreNav}  ${showMoreNavClass} `} role="navigation">
          <div onClick={this.handleMore} className="closeMark">
            X
          </div>
          {this.props.navItems.map((item, index) => {
            const activeClass = item.slug === slug ? 'activeNav' : null;
            const activeImgClass = item.slug === slug ? 'ActivecustomImgIcon' : '';
            return (
              <div
                onClick={() => {
                  this.setState({ showMoreNav: false }, () => {
                    this.props.handleNav(item.name, item.typeOfComponent, item.slug);
                  });
                }}
                key={`show-more-${index}`}
                className={`showMoreNavItem d-flex flex-row align-middle py-3 ${activeClass}

        `}
              >
                <div className="showMoreNavItemIcon pl-1">
                  {item.icon && <FontAwesomeIcon size="2x" icon={item.icon} />}
                  {item.base64Icon && (
                    <img
                      className={`customImgIcon ${item.slug === slug ? 'ActivecustomImgIcon' : ''}`}
                      src={item.base64Icon}
                    />
                  )}
                </div>
                <div className="showMoreNavItemText">{`  ${item.name}`}</div>
              </div>
            );
          })}
        </div>
        {/* END SHOW MORE NAV ON LEFT SIDE */}

        {/* NORMAL NAVIGATION  LEFT OR BOTTOM BAR*/}
        <div
          className={`d-print-none ${productSideBarContainer} ${visibilityClass} ${specialStyles ? pptStyles : null}`}
          role="navigation"
        >
          {this.props.navItems.map((item, index) => {
            const activeClass = item.slug === slug ? 'activeNav' : null;
            //show "More" after showing first 3 items
            //512 is sm break
            if (index > 3 && window.innerWidth < 768) return null;
            if (index === 3 && window.innerWidth < 768) {
              return (
                <div
                  onClick={this.handleMore}
                  className="productSideBarContainerNavItem font-weight-bold   d-flex flex-column flex-md-row py-3 py-lg-4 my-lg-0"
                  key={`sidebar-${index}`}
                >
                  <div>
                    <FontAwesomeIcon size="1x" icon={faList} />
                  </div>
                  <div>More</div>
                </div>
              );
            }
            // Normal navigation (not show more)
            return (
              <div
                onClick={() => {
                  if (item.inactive) {
                    return;
                  }
                  if (showDisclaimer && disclaimerRequired) return;
                  this.setState(
                    { showMoreNav: false },
                    this.props.handleNav(item.name, item.typeOfComponent, item.slug)
                  );
                }}
                key={`sidebar-${index}`}
                className={`  ${
                  item.inactive === undefined ? 'productSideBarContainerNavItem' : 'ml-4'
                } d-flex flex-column flex-md-row text-left py-3 py-lg-4  my-lg-0 ${item.customClass} ${activeClass}`}
              >
                <div className={`pt-0`}>
                  {item.icon && <FontAwesomeIcon size="1x" icon={item.icon} />}
                  {item.base64Icon && (
                    <div style={{ position: 'relative', marginRight: item.slug === slug ? '20px' : '0px' }}>
                      <img
                        className={`customImgIcon ${item.slug === slug ? 'ActivecustomImgIcon' : ''} `}
                        src={item.base64Icon}
                      />
                    </div>
                  )}
                </div>
                <div className="pl-md-4 font-weight-bold ">{item.name}</div>
              </div>
            );
          })}
        </div>
        <div className={productSidebarChildren}>
          {showDisclaimer && disclaimerRequired ? (
            <Container>
              <Row>
                <Col>
                  <Disclaimer contentId={this.disclaimerId} productInPath={thisProductInPath} />
                  <Button
                    onClick={() => {
                      this.handleDisclaimer();
                    }}
                  >
                    Agree
                  </Button>
                </Col>
              </Row>
            </Container>
          ) : (
            this.props.children
          )}
        </div>
        <div className="w-100 mb-5 py-5" style={{ height: '100px' }}>
          {' '}
        </div>
      </div>
    );
  }
}

SideBar.propTypes = {};
//   user: PropTypes.shape({
//     isAuthenticated: PropTypes.bool.isRequired,
//     products: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired
//   }).isRequired
// };

const mapStateToProps = state => ({
  showSideBar: state.application.showSideBar
});

const mapDispatchToProps = {
  toggleSideBar: applicationActions.toggleSideBar,
  openLoginModal: () => modalActions.openModal('login')
};

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(SideBar);
