import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { BreadcrumbItem, Container, Row, Col, Collapse, Modal, ModalBody, ModalHeader } from 'reactstrap';
//import testData from '../../data/testData';
import VideoResource from '../../../../components/VideoResource';
import RecipesList from '../../../../components/RecipesList';
import Checklist from '../../../../components/Checklist';
import ResourcesListTwo from '../../../../components/ResourcesListTwo';
import AudioPlayerMobile from '../../../../components/AudioPlayerMobile';
import { ForumWrapper } from 'components/ForumWrapper';
import BreadCrumbs from 'components/BreadCrumbs';
import * as styles from 'styles/index.scss';
import LessonWrapper from './components/LessonWrapper';
import AssessmentWrapper from './components/AssessmentWrapper';
import ModulePageHeader from './components/ModulePageHeader';
import { Link } from 'react-router-dom';
import testData from '../../../TNTG/data/hhc/hhcData';
import ProductSidebar from 'components/ProductSidebar';
import { Route, Switch } from 'react-router-dom';
import BreadcrumbWrapper from 'components/BreadCrumbWrapper';
import Recipe from '../../../../components/Recipe';
import { MedicalDisclaimer } from 'components/MedicalDisclaimer';
import ModuleWrapper from '../Dashboard/components/ModuleWrapper';
import Markdown from '../../../../components/Markdown';
import { connect } from 'react-redux';
import { actions } from 'modules/user';

@connect(state => ({ user: state.user }), {
  revokeDiscourseAPIKey: actions.revokeDiscourseAPIKey,
  refreshToken: actions.refreshToken
})
//assume modulePage gets the whole product content item
class ModulePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: { isOpen: false },
      showComments: typeof this.props.showComments !== 'undefined' ? this.props.showComments : false, //default is to show comments
      moduleState: this.props.moduleState
    };
    this.productInPath = location.pathname.split('/')[2];
    this.product = this.props.product ? this.props.product : this.props; //this.props in the case that a module is being used as a product
    this.handleNav = this.handleNav.bind(this);
    this.chevronNav = this.chevronNav.bind(this);
    this.findCurrentLesson = this.findCurrentLesson.bind(this);
    this.getNextAvailableModule = this.getNextAvailableModule.bind(this);
    this.isModuleAvailable = this.isModuleAvailable.bind(this);
    this.scrollToContent = this.scrollToContent.bind(this);
    //get the module that matches the slug
    let moduleSlug = props.slug ?? location.pathname.split('/').findLast(e => !!e);
    this.moduleSlug = moduleSlug ? moduleSlug : 'the-basics';
    // this.moduleSlug = moduleSlug;

    this.toggleModal = this.toggleModal.bind(this);
    this.modules = this.product.Children.filter(item => item.type === 'module'); //used for navigation
    this.modules.map(
      module => (this.modules = this.modules.concat(module.Children.filter(item => item.type === 'module')))
    );

    this.module = this.modules.find(item => item.slug === moduleSlug);
    console.log('THIS MODULE', this.module);
    if (!this.module) this.module = this.modules[0]; //one off for products that are just a single module //TODO bad design

    this.lessons = this.module.Children.filter(item => item.type === 'lesson');
    this.assessments = this.module.Children.filter(item => item.type === 'assessment');
    this.recipesList = this.module.Children.find(item => item.type === 'recipesList');
    this.recipes = (this.recipesList && this.recipesList.Children.map(item => item)) || [];
    this.checklist = this.module.Children.find(item => item.type === 'checklist');
    this.resourcesList = this.module.Children.find(item => item.type === 'resourcesList');
    console.log('module resources ', this.resourcesList);
    this.interviewCollectionItems = this.module.Children.filter(item => item.type === 'InterviewCollectionItem');
    this.videoResources = this.module.Children.filter(item => item.type === 'videoResource');
    this.childrenModules = this.module.Children.filter(item => item.type === 'module');
    this.childrenModuleIds = this.childrenModules.map(item => item.id);

    this.today = Date.now();
    this.useExpertInterview = this.productInPath === 'hhc';
    this.commentsWrapper = React.createRef();
    this.highlightedComment = React.createRef();
  }
  handleNav = slug => {
    this.props.history.push(
      `/products/${this.productInPath}/${
        this.props.slugAliasFilter ? slug.replace(this.props.slugAliasFilter, '') : slug
      }`
    );
  };
  //cheeck if this module has premiered
  isModuleAvailable(newIndex) {
    return (
      !this.modules[newIndex].data.premieresAt ||
      parseInt(this.modules[newIndex].data.premieresAt) < parseInt(this.today)
    );
  }

  //Recursive method looking for the next available module
  //TODO infinite loop danger -- probably will hide prev/next when no module is available
  getNextAvailableModule(currentIndex) {
    let newIndex = currentIndex + 1;
    if (newIndex > this.modules.length - 1) {
      //out of bounds
      newIndex = 0;
      return this.handleNav(this.modules[0].slug); //assuming first module is available always, we can stop searching here
    }
    //if this is available nav to it
    if (this.isModuleAvailable(newIndex)) {
      return this.handleNav(this.modules[newIndex].alias ?? this.modules[newIndex].slug);
    } else {
      //recurse
      this.getNextAvailableModule(newIndex);
    }
  }
  //Recursive method looking for the next available module, going backwards
  //TODO infinite loop danger -- probably will hide prev/next when no module is available
  getPreviousAvailableModule(currentIndex) {
    let newIndex = currentIndex - 1;
    if (newIndex < 0) {
      //out of bounds
      return this.getPreviousAvailableModule(this.modules.length); //using length because this method subtracts one
    }
    if (this.isModuleAvailable(newIndex)) {
      return this.handleNav(this.modules[newIndex].alias ?? this.modules[newIndex].slug);
    } else {
      //recurse
      this.getPreviousAvailableModule(newIndex);
    }
  }

  //handles the onclick
  //determines the curentIndex and pases it to getNextAvailableModule
  chevronNav(direction) {
    const currentIndex = this.modules.findIndex(item => item.slug === this.moduleSlug);
    direction === 'forward' ? this.getNextAvailableModule(currentIndex) : this.getPreviousAvailableModule(currentIndex);
    return;
  }
  //MODAL for forum
  toggleModal() {
    this.setState((prevState, props) => {
      const newState = { ...prevState.modal, isOpen: !prevState.modal.isOpen };
      return { modal: newState };
    });
  }
  //returns the current lesson the user is on OR false
  //used to determine what lesson acordian should be open
  findCurrentLesson() {
    const { moduleState = { lessons: [] } } = this.props;
    if (moduleState && moduleState.lessons)
      return typeof moduleState.lessons.find(item => !item.completed) != 'undefined' &&
        moduleState &&
        moduleState.lessons
        ? moduleState.lessons.find(item => !item.completed).id
        : false;
  }

  scrollToContent(fromClick) {
    if (this.props.discussButtonPath) {
      this.props.history.push(`/products/${this.productInPath}/${this.props.discussButtonPath}`);
      return;
    }
    //const offSet = this.commentsWrapper.current.offsetTop - 100;
    let offSet;
    if (fromClick && this.commentsWrapper.current) {
      offSet = this.commentsWrapper.current.offsetTop || 15000;
    } else if (this.highlightedComment.current) {
      offSet = this.highlightedComment.current.scrollIntoView(false, { behavior: 'smooth' });
    }
    setTimeout(() => {
      window.scrollTo({
        top: offSet,
        behavior: 'smooth'
      }),
        900;
    });
  }

  render() {
    const { title, data } = this.module;
    const moduleNumber = data ? data.num : null;
    const { showComments } = this.state;
    let {
      updateModuleStates,
      moduleState = { modules: [], lessons: [], assessments: [] },
      moduleStates = [],
      parentModule = {},
      isModuleProduct,
      isUnstructured,
      noHeader = false,
      slugAliasFilter,
      user
    } = this.props;
    console.log('slugAliasFilter', slugAliasFilter);
    return (
      <div>
        {/* prev/next navigation for modules. */}
        {isUnstructured || noHeader ? null : (
          <ModulePageHeader
            isUnstructured={isUnstructured}
            chevronNav={this.chevronNav}
            toggleModal={this.toggleModal}
            scrollToContent={this.scrollToContent}
            module={this.module}
          />
        )}

        <Container>
          <BreadCrumbs>
            <BreadcrumbItem tag={Link} to={`/products/${this.productInPath}`}>
              {this.product.title}
            </BreadcrumbItem>

            {parentModule && parentModule.slug && parentModule.title ? (
              <BreadcrumbItem tag={Link} to={`/products/${this.productInPath}/${parentModule.slug}/`}>
                {parentModule.title}
              </BreadcrumbItem>
            ) : null}

            {/* if this is a product as module, we don't need the title of the module here, since it is one */}
            {isModuleProduct ? (
              ''
            ) : (
              <BreadcrumbItem active tag="span">
                {title}
              </BreadcrumbItem>
            )}
          </BreadCrumbs>
          <h1>
            {!isUnstructured && typeof moduleNumber !== 'undefined'
              ? Number.isInteger(parseInt(moduleNumber))
                ? `${data.type ? data.type : 'Module'} ${moduleNumber}: `
                : `${moduleNumber}: `
              : ''}
            {title}
          </h1>

          {data.description ? <Markdown source={data.description} className="mb-5" /> : null}
          {isUnstructured || !this.childrenModules.length ? null : (
            <div className="d-flex justify-content-between align-items-baseline  h-100 ">
              <div className="lessonHeading">{isUnstructured ? null : 'Modules'}</div>
              <div>
                {(moduleStates ?? []).filter(item => this.childrenModuleIds.includes(item.id) && item.completed).length}
                /{(this.childrenModuleIds ?? []).length} completed
              </div>
            </div>
          )}
          {this.childrenModules ? (
            <Row>
              {this.childrenModules.map((item, index) => {
                return (
                  <Col key={item.id} xs="12">
                    <ModuleWrapper
                      determineColor={this.determineColor}
                      handleNav={this.handleNav}
                      module={item}
                      moduleNumber={item.data.num}
                      isUnstructured={isUnstructured}
                      moduleState={moduleStates.find(i => i.id === item.id)}
                      calculateModuleProgress={this.calculateModuleProgress}
                      overrideLockedContent={this.props.overrideLockedContent}
                      slugAliasFilter={slugAliasFilter}
                    />
                  </Col>
                );
              })}
            </Row>
          ) : null}
          {isUnstructured || !this.lessons.length ? null : (
            <div className="d-flex justify-content-between align-items-baseline  h-100 ">
              <div className="lessonHeading">{isUnstructured ? null : 'Lessons'}</div>
              <div>
                {moduleState ? (moduleState.lessons ?? []).filter(item => item.completed).length : 0}/
                {moduleState ? (moduleState.lessons ?? []).length : 0} completed
              </div>
            </div>
          )}
          {this.lessons
            ? this.lessons.map((lesson, i) => {
                const lessonState = moduleState ? moduleState.lessons.find(item => lesson.id === item.id) : null;
                //isOpen is based off the moduleState of this lesson.  We get true if this lesson of this module is the not completed (the first one available)
                let isOpen = this.findCurrentLesson() === lesson.id;
                isOpen = isUnstructured && i === 0 ? true : isOpen; //open the first one for unstructured
                // Wraps a single lesson, 'lesson one'
                return (
                  <LessonWrapper
                    isOpen={isOpen}
                    lesson={lesson}
                    lessonState={lessonState}
                    lessonIndex={i}
                    moduleID={this.module.id}
                    lessonID={lesson.id}
                    updateModuleStates={updateModuleStates}
                    isUnstructured={isUnstructured}
                    overrideLockedContent={this.props.overrideLockedContent}
                  />
                );
              })
            : null}
          {isUnstructured || !this.assessments.length ? null : (
            <div className="d-flex flex-column justify-content-between align-items-baseline  h-100 ">
              <div className="lessonHeading">{isUnstructured ? null : 'Practice Quiz'}</div>
              <span>This quiz is for your practice and is not counted towards your certification</span>
            </div>
          )}
          {this.assessments
            ? this.assessments.map((assessment, i) => {
                const assessmentState = moduleState
                  ? (moduleState.assessments ?? []).find(item => assessment.id === item.id)
                  : null;
                isOpen = true; //open the first one for unstructured
                // Wraps a single lesson, 'lesson one'
                return (
                  <AssessmentWrapper
                    isOpen={isOpen}
                    assessment={assessment}
                    assessmentState={assessmentState}
                    moduleID={this.module.id}
                    assessmentId={assessment.id}
                    updateModuleStates={updateModuleStates}
                    isUnstructured={isUnstructured}
                    overrideLockedContent={this.props.overrideLockedContent}
                  />
                );
              })
            : null}
          {this.interviewCollectionItems
            ? this.interviewCollectionItems.map(item => {
                return (
                  <div>
                    <h4>
                      {item.Children
                        ? item.Children.find(child => child.type === 'audioInterview').Children
                          ? item.Children.find(child => child.type === 'audioInterview').Children.find(
                              child => child.type === 'bio'
                            ).title
                          : ''
                        : ''}
                    </h4>

                    {this.useExpertInterview ? <h4>Expert Interview</h4> : <h4>Guest Interview</h4>}

                    <AudioPlayerMobile {...item} colSizes={{ xs: 12, md: 6 }} />
                  </div>
                );
              })
            : null}
          {this.videoResources
            ? this.videoResources.map(item => {
                return <VideoResource {...item} colSizes={{ xs: 12, md: 6 }} showComments={false} WrapperTag="div" />;
              })
            : null}
          {this.checklist ? (
            <Checklist parentID={this.module.id} updateModuleStates={updateModuleStates} {...this.checklist} />
          ) : null}
          {this.recipes.length ? (
            <RecipesList
              colSizes={{ xs: 12, md: 4 }}
              customTitle="All Recipes"
              Children={this.recipes}
              disableViewAll
            />
          ) : null}
          {this.resourcesList ? (
            <ResourcesListTwo disableViewAll {...this.resourcesList} colSizes={{ xs: 12, md: 4 }} />
          ) : null}
          <div ref={this.commentsWrapper}>
            {showComments ? (
              <Row className="d-print-none mt-5">
                <Col>
                  <ForumWrapper
                    product={this.productInPath}
                    slug={this.moduleSlug}
                    pageTitle={title}
                    passedReactRef={this.highlightedComment}
                    scrollToMe={this.scrollToContent}
                    user={user}
                    revokeDiscourseAPIKey={this.props.revokeDiscourseAPIKey}
                    refreshToken={this.props.refreshToken}
                    forumType="ContentItem"
                  />
                </Col>
              </Row>
            ) : null}
          </div>
        </Container>
      </div>
    );
  }
}

export default withRouter(ModulePage);
