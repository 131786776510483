import React from 'react';
import ModalContainer from '../container';
import { GridLoader } from 'react-spinners';

const OrderProcessingModal = props => {
  const { showModal, hideModal } = props;
  const title = 'Processing your order . . . ';

  return (
    <ModalContainer {...props} disableClose title={title}>
      <GridLoader color={'#7D00B5'} size={15} cssOverride={{ margin: '0 auto', display: 'block' }} />
      <p className="text-center mt-3">Processing order . . . </p>
    </ModalContainer>
  );
};

export default OrderProcessingModal;
