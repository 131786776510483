import React from 'react';
import * as styles from 'styles/index.scss';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { actions } from 'modules/user';
import EditPic from './components/EditPic';
const { editPicContainer, editPicPreviewCroppedImage } = styles;

class EditPicModal extends React.Component {
  constructor(props) {
    super(props);
    this.src = props.src;
    this.onCrop = props.onCrop;
  }

  render() {
    const { toggleModal, user, isOpen, toggle } = this.props;

    return (
      <Modal centered isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Update Profile Picture</ModalHeader>
        <ModalBody>
          <EditPic user={user} onSuccess={toggle} />
        </ModalBody>
      </Modal>
    );
  }
}

export default EditPicModal;
