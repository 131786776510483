import React from 'react';
import PropTypes from 'prop-types';
import ListItem from './components/ListItem';
import * as styles from 'styles/index.scss';

const { fullWidthListContainer, listTitle, list } = styles;

const FullWidthList = props => (
  <div className={fullWidthListContainer}>
    <h1 className={listTitle}>{props.customTitle || props.title}</h1>
    <div className={list}>
      {props.Children.map(item => (
        <ListItem key={item.title} {...item} />
      ))}
    </div>
  </div>
);

FullWidthList.propTypes = {
  Children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customTitle: PropTypes.string,
  title: PropTypes.string.isRequired
};

FullWidthList.defaultProps = {
  customTitle: ''
};

export default FullWidthList;
