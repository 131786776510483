import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, CardBody, CardHeader, CardText, Col, Container, Row } from 'reactstrap';
import Video from '../Video';
import { getSingleDate } from 'lib/time';
import { ForumWrapper } from 'components/ForumWrapper';

import { faBookmark } from '@fortawesome/pro-regular-svg-icons/faBookmark';
import { faHeart } from '@fortawesome/pro-regular-svg-icons/faHeart';
import { faFileVideo } from '@fortawesome/pro-regular-svg-icons/faFileVideo';
import { faCloudDownload } from '@fortawesome/pro-regular-svg-icons/faCloudDownload';
import { faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons/faHeart';
import { faBookmark as faBookmarkSolid } from '@fortawesome/pro-solid-svg-icons/faBookmark';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { actions } from 'modules/user';
import * as styles from 'styles/index.scss';
import Recipe from '../Recipe';
import RecipeCard from '../RecipeCard';
import GeneralModal from 'components/GeneralModal';
import Markdown from 'routes/Products/components/Markdown';
import CountdownTimer from 'routes/Products/components/CountdownTimer';
import List from 'components/List';
import { track, page } from '../../../../lib/track';
const { userAvatar, videoResourceContainer, videoResourceDownload, removeShadow } = styles;

class VideoResourceContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.id = props.id;
    this.productInPath = location.pathname.split('/')[2];
    this.state = {
      showModal: false, //for the recipe modal
      bookmarked: false,
      liked: false,
      showComments: typeof this.props.showComments !== 'undefined' ? this.props.showComments : true, //default is to show comments
      userAnalytics: this.props.userAnalytics
    };

    //used for scrolling to comments section on notification links
    this.comments = React.createRef();
    this.video = this.props.Children.find(child => child.type === 'video');
    this.downloads = this.props.Children.filter(child => child.type === 'download')
      .sort(function(a, b) {
        var typeA = a.data.type.toUpperCase(); // ignore upper and lowercase
        var typeB = b.data.type.toUpperCase(); // ignore upper and lowercase
        if (typeA < typeB) {
          return -1;
        }
        if (typeA > typeB) {
          return 1;
        }

        // names must be equal
        return 0;
      })
      .reverse();
    this.bios = this.props.Children.filter(child => child.type === 'bio') || [];
    this.recipe = this.props.Children.find(child => child.type === 'recipe');
    this.isVimeo = this.video && this.video.data.url.includes('vimeo');
    this.scrollToContent = this.scrollToContent.bind(this);
    this.commentsWrapper = React.createRef();
    this.highlightedComment = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.props.test) {
      this.getState();
    }
    const checkURL = window.location.href;
    //a way to categorize the service, since actionhour is not a type itself and uses video resource
    if (checkURL.match(/-action-hour-/g)) {
      //action hour doesn't have a parent wrapper so the only want to check if it is action hour is by the url
      this.setState(
        {
          userAnalytics: {
            service: 'action_hour',
            content_id: this.id,
            slug: this.props.slug,
            title: this.props.title,
            uuid: this.props.user && this.props.user.UUID
          }
        },
        () => {
          page('action hour', this.state.userAnalytics);
        }
      );
    } else {
      this.setState({ userAnalytics: this.props.userAnalytics ? this.props.userAnalytics : {} });
    }
  }
  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  async getState() {
    const savedData = await this.props.get(this.id);
    if (savedData) {
      this.setState({ ...this.state, ...savedData });
    } else {
      // No saved values - do something?
    }
  }

  postState = newState => {
    this.props.post(this.id, newState);
  };

  saveState = stateItem => {
    const newState = { ...this.state, ...stateItem };
    this.props.post(this.id, newState);
    this.setState({ ...newState });
  };

  toggleLiked = () => this.saveState({ liked: !this.state.liked });
  toggleBookmarked = () => this.saveState({ bookmarked: !this.state.bookmarked });
  //scroll to comments section
  scrollToContent = () => {
    //timout used to ensure the reference exists when it is called by child
    setTimeout(() => {
      //const offSet = this.commentsWrapper.current.offsetTop - 100;
      const offSet =
        this.highlightedComment.current &&
        this.highlightedComment.current.scrollIntoView(false, { behavior: 'smooth' });
      window.scrollTo({
        top: offSet,
        behavior: 'smooth'
      });
    }, 1000);
  };

  renderWide = () => {
    const { bookmarked, liked } = this.state;

    return (
      <Col sm={4}>
        <div style={{ paddingLeft: '12px' }}>
          <div onClick={this.toggleLiked} style={{ cursor: 'pointer', minWidth: '190px' }}>
            <FontAwesomeIcon style={{ color: '#F5515F', marginRight: '8px' }} icon={liked ? faHeartSolid : faHeart} />
            <span>Favorite this content</span>
          </div>
          <div onClick={this.toggleBookmarked} style={{ cursor: 'pointer', minWidth: '190px' }}>
            <FontAwesomeIcon
              style={{ marginLeft: '3px', marginRight: '9px' }}
              icon={bookmarked ? faBookmarkSolid : faBookmark}
            />
            <span>Bookmark this page</span>
          </div>
        </div>
      </Col>
    );
  };

  downloadIcon = type => {
    switch (type) {
      case 'MP3':
        return ['far', 'file-audio'];
      // case 'PDF':
      //   return ['far', 'file-pdf'];
      case 'PDF':
        return ['far', 'cloud-download'];
      case 'VIDEO':
        return ['far', 'cloud-download'];
      default:
        return ['far', 'cloud-download'];
    }
  };

  render() {
    const {
      data: { time, description },
      isActionHour,
      displayProps,
      videoProgressReport, //used to report video being finished
      hideTitle,
      WrapperTag = Container,
      user
    } = this.props;
    const showComments = typeof this.props.showComments !== 'undefined' ? this.props.showComments : true;
    const { bookmarked, liked } = this.state;
    const names = this.bios.map(e => e.title);
    let nameStr = '';
    if (names) {
      names.push(names.splice(-2).join(' & '));
      nameStr = names.join(', ');
    }

    const { title, subtitle } = displayProps;

    const videoResourceTitle =
      isActionHour && this.bios
        ? `Action Hour${nameStr ? ' with ' + nameStr : ''}${time ? ': ' + getSingleDate(time) : ''}`
        : title;
    let displayedSubtitle = subtitle
      ? subtitle
      : title.split('Action Hour:').length > 1
      ? title.split('Action Hour:')[1]
      : title;

    return (
      <WrapperTag className={`${videoResourceContainer} `}>
        {hideTitle ? null : (
          <div className="pagetitle">
            {isActionHour ? <h5>{`Action Hour${time ? ' — ' + getSingleDate(time) : ''}`}</h5> : null}
            {isActionHour ? <h1 className="action-hour-title">{displayedSubtitle}</h1> : <h1>{displayedSubtitle}</h1>}
            {nameStr ? <h3>with {nameStr}</h3> : null}
          </div>
        )}

        {/* backwards compatible design */}
        {/* if there is a video, we grab that description */}
        {this.video ? (
          <React.Fragment>
            <Video
              save
              {...this.video}
              videoProgressReport={videoProgressReport}
              userAnalytics={this.state.userAnalytics ? this.state.userAnalytics : {}}
            />
            {this.video.data.description ? (
              <Row className="mb-1">
                <Col>
                  <Markdown source={this.video.data.description} />
                </Col>
              </Row>
            ) : null}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Row>
              <Col>
                {this.props.data && this.props.data.time ? (
                  <div className="mb-5">
                    <h4 className="text-center">Premiering in</h4>
                    <CountdownTimer
                      time={this.props.data.time}
                      onComplete={() => this.setState({ inProgress: true })}
                    />
                  </div>
                ) : this.props.data.time ? (
                  <h4 className="text-center mb-5">Premiering soon</h4>
                ) : null}
              </Col>
            </Row>
            {description ? (
              <Row className="mb-1">
                <Col>
                  <Markdown source={description} />
                </Col>
              </Row>
            ) : null}
          </React.Fragment>
        )}
        {this.bios.map(({ data: { img, title, description } }) => (
          <Row className="d-flex align-items-center">
            {img ? (
              <Col xs={12} md={3}>
                <div className={userAvatar}>
                  <img src={img} alt={title || ''} />
                </div>
              </Col>
            ) : null}
            {description ? (
              <Col xs={12} md={img ? 9 : 12}>
                <Markdown source={description} />
              </Col>
            ) : null}
          </Row>
        ))}

        {this.downloads.length ? (
          <React.Fragment>
            <Row>
              <Col className="pb-4">
                <h5>Downloads</h5>
              </Col>
            </Row>
            <List
              contentItems={this.downloads}
              userAnalytics={this.state.userAnalytics ? { ...this.state.userAnalytics } : {}}
            />
          </React.Fragment>
        ) : null}

        {/* RECIPES
        show recipe card if video has recipe, toggle modal with recipe in it on click */}
        {this.recipe && (
          <Row>
            <Col {...{ xs: 12, sm: 6, md: 4 }} className="py-5 summary d-print-none" onClick={this.toggleModal}>
              <RecipeCard disableRouter={true} {...this.recipe} className="h-100" />
            </Col>
          </Row>
        )}
        {/* modal for showing a recipe */}
        <GeneralModal
          isOpen={this.state.showModal}
          toggle={this.toggleModal}
          component={() => {
            return <Recipe {...this.recipe} />;
          }}
        />
        {this.recipe && (
          <div className="d-none d-print-block">
            <Recipe {...this.recipe} />
          </div>
        )}
        {showComments ? (
          <Row className="d-print-none mt-5">
            <Col ref={this.commentsWrapper}>
              <ForumWrapper
                service={this.userAnalytics && this.userAnalytics.service ? this.userAnalytics.service : 'general'}
                product={this.productInPath}
                slug={this.props.slug}
                pageTitle={title}
                scrollToMe={this.scrollToContent}
                passedReactRef={this.highlightedComment}
                user={user}
                revokeDiscourseAPIKey={this.props.revokeDiscourseAPIKey}
                refreshToken={this.props.refreshToken}
                forumType="ContentItem"
              />
            </Col>
          </Row>
        ) : null}
      </WrapperTag>
    );
  }
}

VideoResourceContainer.propTypes = {
  data: PropTypes.shape({
    ingredients: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  }).isRequired,
  get: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  post: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = dispatch => ({
  get: contendId => dispatch(actions.getUserData(contendId)),
  post: (contentId, data) => dispatch(actions.postUserData(contentId, data)),
  refreshToken: actions.refreshToken,
  revokeDiscourseAPIKey: actions.revokeDiscourseAPIKey
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoResourceContainer);
