/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as styles from 'styles/index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const {
  appSideBarItemChildren,
  appSideBarItemContainer,
  appSideBarItemIcon,
  appSideBarItemIconImg,
  appSideBarItemTitle
} = styles;

class SideBarItem extends Component {
  render() {
    return (
      <div className={appSideBarItemContainer}>
        <div className={appSideBarItemTitle} onClick={this.props.handleClick}>
          <div className={appSideBarItemIcon}>
            <FontAwesomeIcon icon={this.props.icon} color="white" />
          </div>
          <div>
            <span>{this.props.title}</span>
          </div>
        </div>
      </div>
    );
  }
}

SideBarItem.propTypes = {
  title: PropTypes.string.isRequired
};

export default SideBarItem;
