import React from 'react';
import * as styles from 'styles/index.scss';

const { tagStyles } = styles;

export default props => (
  <div className={tagStyles} style={props.overrideStyles}>
    {props.tag}
  </div>
);
