import React, { Component } from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { actions } from 'modules/user';
import history from 'store/history';
import { actions as modalActions } from 'modules/modal';
import * as styles from 'styles/index.scss';
const { landingPageBG } = styles;
//"Please check your email right away. This link will expire in 30 minutes from when we send it."
@connect(state => ({}), {
  loginUser: actions.loginUser,
  openModal: modalActions.openModal
})

//Magic Link expects a URL params:  EMAIL, TOKEN (produced from the backend)
class MagicLinkLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMessage: false
    };
  }
  componentWillMount() {
    const params = queryString.parse(this.props.location.search);
    // Replace parsed spaces with "+"
    params.magicToken = params.magicToken.split(' ').join('+');
    this.magicLogin(params.email, params.magicToken);
  }

  magicLogin(email, magicToken) {
    this.props
      .loginUser({ email: email, magicToken: magicToken })
      .then(resp => {
        if (resp) {
          history.push('/profile');
        } else {
          //login in failed, most likely login link expired, let user know
          this.setState({ showMessage: true });
          //4th arg is for options, passing in magicLinkExpired
          //PassworLoginForm will check for props.opts.magicLinkExpired and show appropriate message
          this.props.openModal('login', 1, false, { magicLinkExpired: true, ...this.props.location });
        }
      })
      .catch(e => {
        console.log('error in magic link ', e);
      });
  }

  render() {
    const { showMessage } = this.state;

    /* If it is expired,  magicLinkLogin will open up a login modal, via store method and ModalRouter (in containers/App/index)*/
    return showMessage ? <div className={landingPageBG} /> : 'Logging in...';
  }
}

export default MagicLinkLogin;
