import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {actions} from 'modules/user';
import {Button, Card, CardBody, CardHeader, CardFooter, Row, Col} from 'reactstrap';
import UpdateModal from './components/UpdateModal';
import CancelModal from './components/CancelModal';
import UpgradeModal from './components/UpgradeModal';
import * as styles from 'styles/index.scss';
import subscriptionOne from '../../../../Products/products/WLC/pages/Subscription/testData';
import moment from 'moment';
import queryString from 'query-string';

const {userSubscriptionCard, userSubscriptionCardButton, userSubscriptionsCancelLink} = styles;

const initialState = {
  updatingNumber: false,
  cancelingNumber: false,
  upgradeOpen: false,
  cancelFail: false,
  upgradeFail: false
};

@connect(
  state => ({
    user: state.user
  }),
  {
    getSubscriptions: actions.getSubscriptions,
    cancelSubscription: actions.cancelSubscription,
    upgradeSubscription: actions.upgradeSubscription
    // deletePaymentMethod: actions.deletePaymentMethod
  }
)
class RecurringPaymentCard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.upgradeSubscription = this.upgradeSubscription.bind(this);
    this.toggleValue = this.toggleValue.bind(this);
    this.resetState = this.resetState.bind(this);
    this.getSubscriptionWrapper = this.getSubscriptionWrapper.bind(this);
  }

  resetState() {
    this.setState(initialState);
  }

  getSubscriptionWrapper() {
    this.props.getSubscriptions();
    this.resetState();
  }

  toggleUpdate = (id, name, paymentString, updatingPaymentMethodId) => () => {
    return new Promise(resolve =>
      this.setState(
        this.setState({
          updatingNumber: this.state.updatingNumber === id ? null : id,
          updatingProduct: name,
          paymentString,
          updatingPaymentMethodId: updatingPaymentMethodId
        }),
        () => resolve()
      )
    );
  };

  toggleCancel = (id, name) => () => {
    this.setState({
      cancelingNumber: this.state.cancelingNumber === id ? null : id,
      cancelingProduct: name,
      cancelFail: false,
      cancelSuccess: false
    });
  };
  toggleValue(key) {
    this.setState(prev => {
      return {[key]: !prev[key]};
    });
  }

  cancelSubscription = id => async () => {
    const {cancelSubscription, getSubscriptions} = this.props;
    // getSubscriptions is a callback on successful cancel
    const cancelSuccess = await cancelSubscription(id);
    if (cancelSuccess) {
      // refresh subscriptions after successful cancel
      this.setState({cancelSuccess: true});
      // Pause here?
      getSubscriptions();
    } else {
      this.setState({cancelFail: true});
      // FIX handle cancel fail here
    }
  };

  getPaymentMethod = UserPaymentMethodId => {
    const {UserPaymentMethods} = this.props.user;
    const paymentMethod = UserPaymentMethods.find(paymentMethod => paymentMethod.id === UserPaymentMethodId);
    return paymentMethod;
  };

  getPaymentOption = OrderTransactions => {
    const mostRecent = OrderTransactions[OrderTransactions.length - 1];

    if (mostRecent && mostRecent.OfferPaymentOption) {
      const {
        OfferPaymentOption: {amount, interval}
      } = mostRecent;
      return {amount, interval};
    } else {
      return {amount: 0, interval: null};
    }
  };

  upgradeSubscription(id, price) {
    const {getSubscriptions} = this.props;
    this.setState({upgradeSuccess: false, upgradeFail: false});
    // getSubscriptions is a callback on successful cancel
    this.props
      .upgradeSubscription(id, price)
      .then(res => {
        if (res) {
          this.setState({upgradeSuccess: true});
          this.resetState();
          this.props.updateOrders();
        } else {
          throw res;
        }
      })
      .catch(e => {
        this.setState({upgradeFail: true});
        console.log('e upgrading sub ', e);
      });
  }

  render() {
    if (!this.props.order || !this.props.order.Subscription) {
      return null;
    }
    const {
      id,
      gift,
      total,
      OrderProducts,
      OrderTransactions,
      Subscription,
      paymentAmount,
      numInstallments,
      paymentsMade
    } = this.props.order;

    const {justShowBanner} = this.props;

    const {cancelDate, firstBillingDate, nextBillingDate, UserPaymentMethodId, data, status, type, ProductId} = {
      ...Subscription
    };
    const copyBillingDate = nextBillingDate ? nextBillingDate.split('T')[0] : '';
    const copyBillingDateString = copyBillingDate ? moment(copyBillingDate).format('MMM D, YYYY') : '';
    const {UserPaymentMethods} = this.props.user;
    const {
      Product: {name = ''}
    } = OrderProducts.find(op => op.ProductId === ProductId) || OrderProducts[0];

    // parsing the date string (which looks like YYYY-MM-DDTHH:MM:SS)
    // allows us to get around UTC issues when creating a moment object
    let subIsInTrial = moment() < moment(firstBillingDate.split('T')[0]).endOf('date');

    const qs = queryString.parse(window.location.search);

    const showSpecial = qs.showWLCspecial;

    let annualPrice = 197; //(showSpecial || subIsInTrial) ? 99 : 127;

    const fullPriceMonthly = 49;
    const fullPriceAnnual = 497;

    if (!!gift || isNaN(Number(total))) return null;
    const isSubscription = type === 'recurring';
    const paymentOption = this.getPaymentOption(OrderTransactions);
    let amount, interval;
    interval = paymentOption.interval;
    const installmentsLeft = numInstallments - paymentsMade;
    if (isSubscription) {
      amount = paymentOption.amount;
    } else {
      amount = paymentAmount;
    }

    ///numbers for the subscription yearly upgrade copy
    const currentMonthlyAmount = amount ? parseInt(amount) : null;
    const totalPaid = amount ? parseInt(amount) * 12 : null;
    const savings = totalPaid ? totalPaid - annualPrice : 0;

    let upgradeInfoObject = {
      totalPaid,
      savings,
      currentMonthlyAmount,
      copyBillingDateString
    };

    const paymentMethod = this.getPaymentMethod(UserPaymentMethodId);
    const {brand, expMonth, expYear, last4} = paymentMethod || {};

    const subscriptionDescendentInfo = {
      ...(data && data.upgradedAs && {upgradedAs: data.upgradedAs, case: 'upgradedAs'}),
      ...(data && data.upgradedFrom && {upgradedFrom: data.upgradedFrom, case: 'upgradedFrom'}),
      ...(data && data.restartedAs && {restartedAs: data.restartedAs, case: 'restartedAs'}),
      ...(data && data.restartedFrom && {restartedFrom: data.restartedFrom, case: 'restartedFrom'})
    };

    // const { amount, interval } = subscription.Subscription;
    const paymentString = amount && interval ? `$${amount || 'amount'}/${interval || 'interval'}` : null;

    const readableStatus = status === 'pastDue' ? 'past due' : status;
    const brandString = brand === 'paypal' ? 'PayPal Billing Agreement' : `${brand} card ending ${last4}`;

    const firstBillReadable = new Date(firstBillingDate).toDateString();
    const nextBillReadable = new Date(nextBillingDate).toDateString();

    function renderCancelMessage() {
      switch (subscriptionDescendentInfo.case) {
        case 'upgradedAs':
          return (
            <div>
              This monthly subscription has been upgraded to an annual subscription that starts with Order #
              {subscriptionDescendentInfo.upgradedAs.FirstOrderId}.
            </div>
          );
        case 'restartedAs':
          return (
            <div>
              This subscriptions has been restarted and will start with Order #
              {subscriptionDescendentInfo.restartedAs.FirstOrderId}.
            </div>
          );
        default:
          return (
            <div>
              This subscription{' '}
              {status === 'pastDue'
                ? 'is past due and will be cancelled on'
                : 'has been cancelled. Your subscription will be active through'}{' '}
              {new Date(cancelDate).toDateString()}.
            </div>
          );
      }
    }

    function renderDescendentMessage() {
      switch (subscriptionDescendentInfo.case) {
        case 'upgradedFrom':
          return (
            <div>
              This annual subscription starts {firstBillReadable} and was upgraded from the subscription that starts
              with Order #{subscriptionDescendentInfo.upgradedFrom.FirstOrderId}.
            </div>
          );
        case 'restartedFrom':
          return (
            <div>
              This subscription starts {firstBillReadable} and was restarted from the subscription that starts with
              Order #{subscriptionDescendentInfo.restartedFrom.FirstOrderId}.
            </div>
          );
        default:
          return (
            <div>
              This subscription{' '}
              {status === 'pastDue'
                ? 'is past due and will be cancelled on'
                : 'has been cancelled. Your subscription will be active through'}{' '}
              {new Date(cancelDate).toDateString()}.
            </div>
          );
      }
    }

    return justShowBanner ? (
      <React.Fragment>
        {interval === 'month' && !cancelDate ? (
          <Card className="upgradeBannerWrapper mb-5">
            <CardBody className="p-5">
              <Row>
                <Col xs="12" lg="9" md="8" className="upgradeBannerCopy">
                  <h5 className="mt-4">Upgrade your WLC membership and save!</h5>
                  <p>
                    Save money and upgrade your subscription to annual! Instead of ${currentMonthlyAmount}/{interval},
                    you'll pay ${annualPrice}/year, for annual savings of ${savings}. You'll be billed starting at the
                    end of your current subscription and can cancel at any time.
                  </p>
                  <p>
                    You'll be automatically billed ${annualPrice}/year starting on {copyBillingDateString}.
                  </p>
                </Col>
                <Col xs="12" lg="3" md="4" className="align-self-center text-center">
                  <Button
                    className="font-weight-bold"
                    color="secondary"
                    size="lg"
                    block
                    onClick={() => {
                      this.setState({currentOrderId: id});
                      this.toggleValue('upgradeOpen');
                    }}
                  >
                    Upgrade Now
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        ) : null}
        {isSubscription ? (
          <UpgradeModal
            user={this.props.user}
            isOpen={this.state.upgradeOpen}
            showTrialUpgradeCopy={subIsInTrial}
            upgradeSubscription={this.upgradeSubscription}
            cancelingNumber={this.state.cancelingNumber}
            cancelingProduct={this.state.cancelingProduct}
            currentOrderId={id}
            annualPrice={annualPrice}
            upgradeFail={this.state.upgradeFail}
            upgradeSuccess={this.state.upgradeSuccess}
            toggle={this.toggleValue}
            upgradeCopy={upgradeInfoObject}
            refreshSubscriptions={this.getSubscriptionWrapper}
          />
        ) : null}
      </React.Fragment>
    ) : (
      <React.Fragment>
        <Card className={userSubscriptionCard}>
          <CardHeader>
            <h5>Order #{id}</h5>
            <div>
              <span style={{fontSize: '16px'}}>
                {name} {/* cancelDate and status states determine what is displated */}
                {isSubscription ? (cancelDate && status == 'active' ? `(cancelled)` : `(${readableStatus})`) : null}
              </span>
            </div>
            <div>{paymentString ? paymentString : ''}</div>
            {status !== 'complete' ? (
              <div>
                <div>
                  <b>First Billing Date:</b> {firstBillReadable}
                </div>
                <div>
                  <b>Next Billing Date</b>: {nextBillReadable}
                </div>
              </div>
            ) : null}
            {!isSubscription ? <div>{installmentsLeft} payments remaining</div> : null}
          </CardHeader>
          <CardBody style={{paddingTop: cancelDate ? '16px' : '8px'}}>
            {(status === 'active' && cancelDate) || !['pastDue', 'active', 'pending'].includes(status) ? (
              <div>
                <span>No pending payments</span>
              </div>
            ) : (
              <React.Fragment>
                <div>
                  <span>Selected Payment Method:</span>
                </div>
                <div>
                  <span>{brandString}</span>
                </div>
                {brand !== 'paypal' ? (
                  <div>
                    <span>
                      Expires {expMonth}/{String(expYear).slice(2)}{' '}
                    </span>
                  </div>
                ) : null}
                <div className={userSubscriptionCardButton} style={{marginTop: '16px'}}>
                  <Button onClick={this.toggleUpdate(id, name, paymentString, UserPaymentMethodId)} color="purple">
                    Update
                  </Button>
                </div>
              </React.Fragment>
            )}
          </CardBody>
          {isSubscription ? (
            <CardFooter>
              <div className={userSubscriptionCardButton}>
                <React.Fragment>
                  {cancelDate ? renderCancelMessage() : null}
                  {status === 'pending' ? renderDescendentMessage() : null}
                  {status === 'pastDue' || (status === 'active' && !cancelDate) ? (
                    <Button
                      color="link"
                      onClick={this.toggleCancel(id, name)}
                      className="userSubscriptionsCancelLink p-0"
                    >
                      Cancel Subscription
                    </Button>
                  ) : null}
                  {interval === 'month' && !cancelDate ? (
                    <div className={userSubscriptionCardButton} style={{marginTop: '16px'}}>
                      <Button
                        color="purple"
                        onClick={() => {
                          this.setState({currentOrderId: id});
                          this.toggleValue('upgradeOpen');
                        }}
                        className=""
                      >
                        Upgrade To Yearly & Save
                      </Button>
                    </div>
                  ) : null}
                </React.Fragment>
              </div>
            </CardFooter>
          ) : null}
        </Card>
        <UpdateModal
          isOpen={!!this.state.updatingNumber}
          orderId={this.state.updatingNumber}
          updatingProduct={name}
          toggleUpdate={this.toggleUpdate(this.state.updatingNumber)}
          UserPaymentMethods={UserPaymentMethods}
          paymentString={`$${amount}`}
          refreshSubscriptions={this.props.getSubscriptions}
          updatingPaymentMethodId={this.state.updatingPaymentMethodId}
          status={status}
          type={type}
        />
        {isSubscription ? (
          <CancelModal
            user={this.props.user}
            isOpen={!!this.state.cancelingNumber}
            cancelingNumber={this.state.cancelingNumber}
            cancelingProduct={this.state.cancelingProduct}
            toggleCancel={this.toggleCancel(this.state.cancelingNumber)}
            cancelSubscription={this.cancelSubscription(this.state.cancelingNumber)}
            cancelFail={this.state.cancelFail}
            cancelSuccess={this.state.cancelSuccess}
            fullPriceMonthly={fullPriceMonthly}
            fullPriceAnnual={fullPriceAnnual}
          />
        ) : null}
        {isSubscription ? (
          <UpgradeModal
            user={this.props.user}
            isOpen={this.state.upgradeOpen}
            showTrialUpgradeCopy={subIsInTrial}
            upgradeSubscription={this.upgradeSubscription}
            cancelingNumber={this.state.cancelingNumber}
            cancelingProduct={this.state.cancelingProduct}
            currentOrderId={id}
            annualPrice={annualPrice}
            upgradeFail={this.state.upgradeFail}
            upgradeSuccess={this.state.upgradeSuccess}
            toggle={this.toggleValue}
            upgradeCopy={upgradeInfoObject}
            refreshSubscriptions={this.getSubscriptionWrapper}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default RecurringPaymentCard;
