/* eslint-disable max-len */

import React from 'react';
import PropTypes from 'prop-types';
import SpecialReportsListItem from './components/SpecialReportsListItem';
import * as styles from 'styles/index.scss';

const { list, subTitle, title } = styles;

const SpecialReports = props => (
  <div className={list}>
    <h1 className={title}>Special Reports</h1>
    <h5 className={subTitle}>{props.title}</h5>
    {props.Children.map((specialReport, index) => (
      <SpecialReportsListItem key={specialReport.title + String(index)} {...specialReport} />
    ))}
  </div>
);

SpecialReports.propTypes = {
  Children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  title: PropTypes.string.isRequired
};

export default SpecialReports;
