import React from 'react';
import {Link} from 'react-router-dom';
import {Container, Row, Col} from 'reactstrap';
import AudioPlayerMobile from 'routes/Products/components/AudioPlayerMobile';
import BreadCrumbs from '../BreadCrumbs';
import BackNav from 'routes/Products/components/BackNav';
import epStyles from 'routes/Products/products/EmpowermentPackageV2/styles.js';
const LegacyAudioCollection = ({product, productPath, collection}) => {
  return (
    <Container>
      <div style={epStyles.backNavWrapperStyle}>
        <BackNav />
      </div>
      <div style={{marginTop: '20px'}}>
        <BreadCrumbs>
          <Link to={`/products/${productPath}`} className="pr-1">
            {product.title}
          </Link>{' '}
          / {collection.title}
        </BreadCrumbs>
      </div>
      {collection.Children.map(item => 
       <Row key={item.id}>
          <Col xs="12">
              <AudioPlayerMobile save {...item} />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default LegacyAudioCollection;