import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import * as styles from 'styles/index.scss';
import classNames from 'classnames';

const {appContentColumn, appContentColumnHasSidebar} = styles;

const MainContainer = props => {
  console.log(props);
  const visibilityClass = props.showSideBar ? appContentColumnHasSidebar : '';
  return <div className={[appContentColumn, visibilityClass].join(' ')}>{props.children}</div>;
};

MainContainer.propTypes = {};

const mapStateToProps = state => ({
  showSideBar: state.application.showSideBar
});

export default connect(mapStateToProps)(MainContainer);
