/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */

import queryString from 'query-string';
import clonedeep from 'lodash.clonedeep';
import mergewith from 'lodash.mergewith';
import Api from '../../lib/api';
import { track } from '../../lib/track';
import { actions as modalActions } from '../modal';
import { actions as userActions } from '../user';
import { orderFormMessages } from '../messages';
import selectors, { getCurrentOfferPaymentOption } from './selectors';
import braintree from 'braintree-web';
import history from 'store/history';

// ------------------------------------
// Constants
// ------------------------------------

const ACK_UPSELL = 'ACK_UPSELL';
const ADD_BRAINTREE_CLIENT_INSTANCE = 'ADD_BRAINTREE_CLIENT_INSTANCE';

const CLEAR_PAYMENT_TOKEN = 'CLEAR_PAYMENT_TOKEN';

const LOAD_OFFER_REQUEST = 'LOAD_OFFER_REQUEST';
const LOAD_OFFER_SUCCESS = 'LOAD_OFFER_SUCCESS';

const MARK_TOTALS_PENDING = 'MARK_TOTALS_PENDING';

const PROCESS_CHECKOUT_SUCCESS = 'PROCESS_CHECKOUT_SUCCESS';
const TRACK_CHECKOUT_SUCCESS = 'TRACK_CHECKOUT_SUCCESS';
const PROCESS_UPSELL_SUCCESS = 'PROCESS_UPSELL_SUCCESS';
const TRACK_UPSELL_SUCCESS = 'TRACK_UPSELL_SUCCESS';

const RESET = 'RESET';
const RESET_USER = 'RESET_USER';

const SET_COOKIE = 'SET_COOKIE';
const SET_CURRENT_PAYMENT_METHOD = 'SET_CURRENT_PAYMENT_METHOD';
const SET_DEVICE_DATA = 'SET_DEVICE_DATA';
const SET_RECAPTCHA_TOKEN = 'SET_RECAPTCHA_TOKEN';
const SET_GIFT_RECIPIENT = 'SET_GIFT_RECIPIENT';
const SET_NEW_ADDRESS = 'SET_NEW_ADDRESS';
const SET_USER = 'SET_USER';
const SET_ORDER = 'SET_ORDER';
const SET_ORDER_FORM_MESSAGE = 'SET_ORDER_FORM_MESSAGE';
const SET_ORDER_REQUEST_OFFER_ID = 'SET_ORDER_REQUEST_OFFER_ID';
const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
const SET_PAYMENT_TOKEN = 'SET_PAYMENT_TOKEN';
const SET_PRODUCT_AMOUNT = 'SET_PRODUCT_AMOUNT';
const SET_RECEIPT_MESSAGE = 'SET_RECEIPT_MESSAGE';
const SET_SELECTED_BILLING_ADDRESS = 'SET_SELECTED_BILLING_ADDRESS';
const SET_SELECTED_PAYMENT_OPTION = 'SET_SELECTED_PAYMENT_OPTION';
const SET_SELECTED_SHIPPING_ADDRESS = 'SET_SELECTED_SHIPPING_ADDRESS';
const SET_SAVE_ADDRESS_BILLING = 'SET_SAVE_ADDRESS_BILLING';
const SET_SAVE_ADDRESS_SHIPPING = 'SET_SAVE_ADDRESS_SHIPPING';
const SET_DEFAULT_BILLING = 'SET_DEFAULT_BILLING';
const SET_DEFAULT_BILLING_AND_SHIPPING = 'SET_DEFAULT_BILLING_AND_SHIPPING';
const SET_DEFAULT_SHIPPING = 'SET_DEFAULT_SHIPPING';
const SET_STANDALONE_UPSELL_ORDER = 'SET_STANDALONE_UPSELL_ORDER';
const SET_THIRD_PARTY_USER_INFO = 'SET_THIRD_PARTY_USER_INFO';
const SET_USE_SAME_SHIPPING_ADDRESS = 'SET_USE_SAME_SHIPPING_ADDRESS';
const SET_USER_FROM_STATE = 'SET_USER_FROM_STATE';
const SET_USER_PAYMENT_ID = 'SET_USER_PAYMENT_ID';

const TOGGLE_IS_GIFT = 'TOGGLE_IS_GIFT';

const UPDATE_BUMP_OFFERS = 'UPDATE_BUMP_OFFERS';
const UPDATE_QUANTITY = 'UPDATE_QUANTITY';
const UPDATE_TOTALS = 'UPDATE_TOTALS';
const UPDATE_UPSELL_STATE = 'UPDATE_UPSELL_STATE';

// ------------------------------------
// Actions
// ------------------------------------

const ackUpsell = newCurrentUpsellIdx => ({
  type: ACK_UPSELL,
  currentUpsellIdx: newCurrentUpsellIdx || null
});

const updateUpsellState = upsellState => ({
  type: UPDATE_UPSELL_STATE,
  upsellState
});

const addBraintreeClientInstance = (key, instance) => ({
  type: ADD_BRAINTREE_CLIENT_INSTANCE,
  key,
  instance
});

const requestOffer = slug => ({
  type: LOAD_OFFER_REQUEST,
  slug
});

// params is an offer for an order
//this piggybacks the RudderStack track() off of the LOAD_OFFER_SUCCESS action
const receiveOffer = offer => {
  track('Checkout Started', {
    value: offer.OfferPaymentOptions[0].amount,
    revenue: offer.OfferPaymentOptions[0].amount,
    tax: 0,
    shipping: 0,
    currency: 'usd',
    products: [
      {
        product_id: offer.Product.id,
        sku: offer.Product.slug,
        name: offer.Product.name,
        price: offer.OfferPaymentOptions[0].amount,
        quantity: 1
      }
    ]
  });
  return {
    type: LOAD_OFFER_SUCCESS,
    offer
  };
};

const setPaymentMethod = method => ({
  type: SET_PAYMENT_METHOD,
  method
});

const setNewAddress = (address, addressType) => ({
  type: SET_NEW_ADDRESS,
  address,
  addressType
});

const setThirdPartyUserInfo = user => ({
  type: SET_THIRD_PARTY_USER_INFO,
  user
});
const setUser = user => ({
  type: SET_USER,
  user
});

const toggleIsGift = boolean => ({
  type: TOGGLE_IS_GIFT,
  boolean
});

const setGiftRecipient = giftRecipient => ({
  type: SET_GIFT_RECIPIENT,
  giftRecipient
});

const setReceiptMessage = receiptMessage => ({
  type: SET_RECEIPT_MESSAGE,
  receiptMessage
});

const updateBumpOffers = (BumpOfferIds, BumpOfferQuantities) => ({
  type: UPDATE_BUMP_OFFERS,
  BumpOfferIds,
  BumpOfferQuantities
});

const updateTotals = totals => ({
  type: UPDATE_TOTALS,
  totals
});

const createOrderCompletedEvent = (order, cb = () => {}) => {
  const trans = order.OrderTransactions.sort((a, b) => Date.parse(b.gatewayDate) - Date.parse(a.gatewayDate))[0];
  if (trans) {
    if (typeof window !== 'undefined') {
      window._vis_opt_queue = window._vis_opt_queue || [];
      window._vis_opt_queue.push(function () {
        _vis_opt_revenue_conversion(
          Number(trans.TransactionProducts.reduce((acc, p) => acc + Number(p.price) * p.qtyOrdered, 0)).toFixed(2)
        );
      });
    }
    const timer = setTimeout(cb, 1000);
    track(
      'Order Completed',
      {
        order_id: `${order.id}-${trans.id}`,
        event_id: `${order.id}-${trans.id}`,
        total: trans.amount,
        revenue: trans.TransactionProducts.reduce((acc, p) => acc + Number(p.price) * p.qtyOrdered, 0),
        shipping: trans.TransactionProducts.reduce((acc, p) => acc + Number(p.shipping), 0),
        tax:
          trans.amount -
          trans.TransactionProducts.reduce((acc, p) => acc + (Number(p.price) + Number(p.shipping)) * p.qtyOrdered, 0),
        currency: 'USD',
        products: trans.TransactionProducts.map(prod =>
          Object.assign(
            {
              id: prod.ProductId,
              price: prod.price
            },
            order.OrderProducts.filter(op => op.ProductId === prod.ProductId).reduce(
              (acc, val) => ({
                sku: val.Product.slug,
                name: val.Product.name
              }),
              null
            )
          )
        )
      },
      () => {
        if (timer) clearTimeout(timer);
        cb();
      }
    );
  } else {
    cb();
  }
};

const trackCheckoutSuccess = resp => {
  const meta = createOrderCompletedEvent(resp.Order);
  return {
    type: TRACK_CHECKOUT_SUCCESS,
    meta
  };
};

const checkoutSuccess = (resp, shouldRedirect = true) => {
  if (shouldRedirect) {
    history.push(window.location.pathname + (window.location.pathname.slice(-1) === '/' ? '' : '/') + 'success/');
  }
  return {
    type: PROCESS_CHECKOUT_SUCCESS,
    submitted: true
  };
};

const upsellSuccess = resp => {
  history.push(window.location.pathname + (window.location.pathname.slice(-1) === '/' ? '' : '/') + 'success/');
  return {
    type: PROCESS_UPSELL_SUCCESS,
    submitted: true
  };
};

const trackUpsellSuccess = (resp, upSellId) => {
  const meta = createOrderCompletedEvent(resp);
  return {
    type: TRACK_UPSELL_SUCCESS,
    meta
  };
};

const setCurrentPaymentMethod = method => ({
  type: SET_CURRENT_PAYMENT_METHOD,
  method
});

const setOrder = order => ({
  type: SET_ORDER,
  order
});

const setPaymentToken = (token, keepMessage) => dispatch => {
  dispatch({
    type: SET_PAYMENT_TOKEN,
    token
  });
  if (!keepMessage) {
    dispatch(setOrderFormMessage(null, 0));
  }
};

const setSelectedBillingAddress = id => dispatch => {
  dispatch({
    type: SET_SELECTED_BILLING_ADDRESS,
    id
  });
  dispatch(loadTotals());
};

const setSelectedShippingAddress = id => dispatch => {
  dispatch({
    type: SET_SELECTED_SHIPPING_ADDRESS,
    id
  });
  dispatch(loadTotals());
};

const setSaveAddress = addressType => dispatch => {
  dispatch({
    type: addressType === 'billing' ? SET_SAVE_ADDRESS_BILLING : SET_SAVE_ADDRESS_SHIPPING
  });
};

const setDefaultBilling = () => dispatch => {
  dispatch({
    type: SET_DEFAULT_BILLING
  });
};

const setDefaultBillingAndShipping = () => dispatch => {
  dispatch({
    type: SET_DEFAULT_BILLING_AND_SHIPPING
  });
};

const setDefaultShipping = () => dispatch => {
  dispatch({
    type: SET_DEFAULT_SHIPPING
  });
};

const setStandaloneUpsellOrder = order => ({
  type: SET_STANDALONE_UPSELL_ORDER,
  order
});

const setUseSameShippingAddress = val => ({
  type: SET_USE_SAME_SHIPPING_ADDRESS,
  val
});

const setSelectedUserPaymentId = id => ({
  type: SET_USER_PAYMENT_ID,
  id
});

const setDeviceData = deviceData => dispatch => {
  dispatch({
    type: SET_DEVICE_DATA,
    deviceData
  });
};

const setRecaptchaToken = recaptchaToken => dispatch => {
  dispatch({
    type: SET_RECAPTCHA_TOKEN,
    recaptchaToken
  });
};

const setSelectedPaymentOption = id => (dispatch, getState) => {
  dispatch({
    type: SET_SELECTED_PAYMENT_OPTION,
    id
  });
  const { orderRequest, offer } = getState().checkout;
  if (!orderRequest) return;
  const currentOfferPaymentOption = orderRequest.OfferPaymentOptionId
    ? offer.OfferPaymentOptions.filter(opo => opo.id === orderRequest.OfferPaymentOptionId)[0]
    : null;
  if (!currentOfferPaymentOption) return;
  const freeBumpOfferIds = currentOfferPaymentOption.BumpOffers.filter(
    i => Number(i.OfferPaymentOptionBumpOffer.amount) === 0
  ).map(i => i.id);
  if (!freeBumpOfferIds.length) return;
  dispatch(updateBumpOffers([].concat(freeBumpOfferIds), Array(freeBumpOfferIds.length).fill(1)));
};

const setOrderFormMessage = (message, gatewayCode) => ({
  type: SET_ORDER_FORM_MESSAGE,
  orderFormMessage: message ? message : orderFormMessages[gatewayCode]
});

const updateQuantity = quantity => dispatch => {
  dispatch({
    type: UPDATE_QUANTITY,
    quantity: quantity > 1 ? quantity : 1
  });
  dispatch(loadTotals());
};

const setCookie = cookie => ({
  type: SET_COOKIE,
  cookie
});

const setOrderRequestOfferId = OfferId => ({
  type: SET_ORDER_REQUEST_OFFER_ID,
  OfferId
});

const reset = () => ({
  type: RESET
});

const resetUser = () => ({
  type: RESET_USER
});

const createStandaloneUpsellOrderRequest = (getState, OfferId, OfferPaymentOptionId) => {
  const { order } = getState().checkout;
  const { BillingAddress, User } = order;

  const { gateway, UserPaymentMethodId } = order.OrderTransactions.sort(
    (a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt)
  )[0];

  // Format order
  const OrderRequest = {
    User: { id: User.id, email: User.email },
    OfferId,
    BillingAddress: Object.keys(BillingAddress)
      .filter(key => !['id', 'createdAt', 'updatedAt', 'type', 'OrderId'].includes(key))
      .reduce((res, key) => ((res[key] = BillingAddress[key]), res), {}),
    OfferPaymentOptionId,
    UserPaymentMethodId,
    PaymentMethod: {
      gateway,
      gatewayToken: {}
    }
  };

  return OrderRequest;
};

const getCookie = name => {
  const prop = `${name}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(prop) === 0) {
      return c.substring(prop.length, c.length);
    }
  }
  return '';
};

export const loadTotals = () => (dispatch, getState) => {
  const {
    checkout: {
      offer: { id: offerId },
      orderRequest: OrderRequest,
      totals: currentTotals,
      useSameShippingAddress
    }
  } = getState();

  if (!OrderRequest.OfferPaymentOptionId || !offerId) {
    return;
  }

  dispatch({ type: MARK_TOTALS_PENDING });

  return Api.post('/orders/totals', {
    ...OrderRequest,
    ...(useSameShippingAddress ? { ShippingAddress: OrderRequest.BillingAddress } : {})
  })
    .then(resp => {
      if (resp.status === undefined) {
        dispatch(updateTotals(resp));
        return resp;
      } else {
        const currentTotals = getState().checkout.totals;
        if (resp.status && resp.status === 402) {
          // set message here . . .
          currentTotals.error = true;
          dispatch(updateTotals(currentTotals));
        }

        currentTotals.error = true;
        dispatch(updateTotals(currentTotals));
        return currentTotals;
      }
    })
    .catch(error => {
      // Error message if totals returns faulty object
      dispatch(setOrderFormMessage(null, 999));
      console.log('loadTotals ERROR: ', error);
      return getState().checkout.totals;
    });
};

const loadOffer = slug => (dispatch, getState) => {
  dispatch(requestOffer(slug));

  return Api.get(`/offers/${slug}`)
    .then(resp => {
      if (resp.status === undefined) {
        resp.OfferPaymentOptions = resp.OfferPaymentOptions.sort((a, b) =>
          isNaN(b.amount) || isNaN(a.amount) ? 0 : Number(b.amount) - Number(a.amount)
        );
        dispatch(receiveOffer(resp));
        dispatch(setOrderRequestOfferId(resp.id)); //this add offerId to an initial default orderRequest
        dispatch(setSelectedPaymentOption(resp.OfferPaymentOptions[0].id)); //this adds bumpOffers to an initial default orderRequest
        const User = getState().user;
        if (User.userId && User.email) {
          /*
          1.this gets the default billing address from the api and loads it into the store,
          2. This gets the default payment method from the api and loads it into the store
            orderRequest.UserPaymentMethodId: action.id,
            orderRequest.PaymentMethod: {gateway: 'braintree'}
          */
          dispatch(loadUserFromState());
        }

        dispatch(loadTotals()); //this gets the totals from the api and loads them into the store
      } else {
        window.location.replace('/checkout/404');
      }
    })
    .catch(error => {
      console.log(error);
      window.location.replace('/checkout/404');
    });
};

const loadUserFromState = () => (dispatch, getState) => {
  let User = getState().user;
  if (User.userId && User.email) {
    dispatch(
      setUser({
        id: User.userId,
        email: User.email,
        DefaultBillingAddressId: User.DefaultBillingAddressId,
        DefaultShippingAddressId: User.DefaultShippingAddressId
      })
    );
  }
  dispatch(userActions.loadAddresses())
    .then(() => dispatch(userActions.getPaymentMethods()))
    .then(() => {
      const User = getState().user;
      if (User.addresses.length) {
        dispatch(
          setSelectedBillingAddress(
            User.DefaultBillingAddress && User.DefaultBillingAddress.id
              ? User.DefaultBillingAddress.id
              : User.addresses[User.addresses.length - 1].id
          )
        );
      }
      if (User.UserPaymentMethods.length) {
        dispatch(setSelectedUserPaymentId(User.UserPaymentMethods[User.UserPaymentMethods.length - 1].id));
      }
    });
};

const toggleBumpOffer = id => (dispatch, getState) => {
  const { BumpOfferIds, BumpOfferQuantities } = getState().checkout.orderRequest;
  const idx = BumpOfferIds.indexOf(id);
  if (idx > -1) {
    BumpOfferIds.splice(idx, 1);
    BumpOfferQuantities.splice(idx, 1);
  } else {
    BumpOfferIds.push(id);
    BumpOfferQuantities.push(1);
  }
  dispatch(updateBumpOffers([].concat(BumpOfferIds), [].concat(BumpOfferQuantities)));
  dispatch(loadTotals());
};

const updateBumpOfferQuantity = (quantity, id) => (dispatch, getState) => {
  if (quantity <= 0) return;
  const { BumpOfferIds, BumpOfferQuantities } = getState().checkout.orderRequest;
  const idx = BumpOfferIds.indexOf(id);
  if (idx < 0) return;
  BumpOfferQuantities[idx] = quantity;
  dispatch(updateBumpOffers([].concat(BumpOfferIds), [].concat(BumpOfferQuantities)));
  dispatch(loadTotals());
};

const processThirdPartyCheckout = newUser => async (dispatch, getState) => {
  dispatch(setThirdPartyUserInfo(newUser));
  const { User } = getState().checkout.orderRequest;
  dispatch(setUser(mergewith({}, User, newUser, (a, b) => (!b || b === null ? a : undefined))));
  dispatch(loadTotals());
};

const processOrder = () => async (dispatch, getState) => {
  dispatch(modalActions.openModal('spinner'));
  const OrderRequest = clonedeep(getState().checkout.orderRequest);

  const { braintreeClientInstances, selectedPaymentMethod, gateway, useSameShippingAddress } = getState().checkout;
  const state = getState();

  if (!selectors.orderRequiresPayment(state)) {
    OrderRequest.PaymentMethod = { gateway: 'manual', gatewayToken: 'manual' };
  } else if (OrderRequest.PaymentMethod && OrderRequest.PaymentMethod.token && OrderRequest.PaymentMethod.token.nonce) {
    OrderRequest.PaymentMethod.gatewayToken = { id: OrderRequest.PaymentMethod.token.nonce };
  } else if (!OrderRequest.UserPaymentMethodId && OrderRequest.PaymentMethod.gateway != 'manual') {
    const payload = await braintreeClientInstances[selectedPaymentMethod].tokenize();
    OrderRequest.PaymentMethod = { gateway: 'braintree', gatewayToken: { id: payload.nonce } };
  }
  const offer = getState().checkout.offer;
  const currentOfferPaymentOption = OrderRequest.OfferPaymentOptionId
    ? offer.OfferPaymentOptions.filter(opo => opo.id === OrderRequest.OfferPaymentOptionId)[0]
    : null;
  const requiresShippingAddress =
    offer.Product.requiresShippingAddress ||
    (currentOfferPaymentOption && currentOfferPaymentOption.BumpOffers.some(bo => bo.Product.requiresShippingAddress));
  if (useSameShippingAddress) OrderRequest.ShippingAddress = OrderRequest.BillingAddress;
  if (!requiresShippingAddress && OrderRequest.ShippingAddress) delete OrderRequest.ShippingAddress;
  const { deviceData, recaptchaToken } = state.checkout;

  if (deviceData) OrderRequest.deviceData = deviceData; //fraud protection
  if (recaptchaToken) OrderRequest.recaptchaToken = recaptchaToken;

  if (location && location.search) {
    const { everflow_transaction_id } = queryString.parse(location.search);
    if (everflow_transaction_id) OrderRequest.everflow_transaction_id = everflow_transaction_id;
  }

  return Api.post('/orders', OrderRequest).then(resp => {
    dispatch({ type: CLEAR_PAYMENT_TOKEN });

    if (resp.status === undefined && resp.gatewayCode === undefined) {
      dispatch(setOrder(resp.Order));
      dispatch(setOrderFormMessage(null, 0));
      createOrderCompletedEvent(resp.Order, () => dispatch(checkoutSuccess(resp)));

      if (resp.User) {
        dispatch(userActions.receiveLogin(resp.User));
        return new Promise((res, rej) => dispatch(userActions.getUserProducts()))
          .then(() => {
            dispatch(setOrderFormMessage(null, 0));
            dispatch(checkoutSuccess(resp));
          })
          .catch(e => console.log('error geting user products', e.message));
      } else {
        dispatch(setOrderFormMessage(null, 0));
        dispatch(checkoutSuccess(resp));
      }
    } else if (resp.gatewayCode) {
      dispatch(setOrderFormMessage(null, resp.gatewayCode));
      dispatch(modalActions.hideModal());
      return Promise.resolve(true);
    } else {
      dispatch(setOrderFormMessage({ type: 'api', text: resp.errorMessage }, 999));
      dispatch(modalActions.hideModal());
      return Promise.resolve(true);
    }
  });
};
/*
A few changes from processOrder
1. no redirect to an upsell page
2. close the spinner modal after the order is processed
3. TODO: not sure how to handle errors
*/
const processUpgrade = token => async (dispatch, getState) => {
  dispatch(modalActions.openModal('spinner'));
  const OrderRequest = clonedeep(getState().checkout.orderRequest);

  const { braintreeClientInstances, selectedPaymentMethod, gateway, useSameShippingAddress } = getState().checkout;
  const state = getState();

  if (!selectors.orderRequiresPayment(state)) {
    OrderRequest.PaymentMethod = { gateway: 'manual', gatewayToken: 'manual' };
  } else if (OrderRequest.PaymentMethod && OrderRequest.PaymentMethod.token && OrderRequest.PaymentMethod.token.nonce) {
    OrderRequest.PaymentMethod.gatewayToken = { id: OrderRequest.PaymentMethod.token.nonce };
  } else if (!OrderRequest.UserPaymentMethodId && OrderRequest.PaymentMethod.gateway != 'manual') {
    const payload = await braintreeClientInstances[selectedPaymentMethod].tokenize();
    OrderRequest.PaymentMethod = { gateway: 'braintree', gatewayToken: { id: payload.nonce } };
  }
  const offer = getState().checkout.offer;
  const currentOfferPaymentOption = OrderRequest.OfferPaymentOptionId
    ? offer.OfferPaymentOptions.filter(opo => opo.id === OrderRequest.OfferPaymentOptionId)[0]
    : null;
  const requiresShippingAddress =
    offer.Product.requiresShippingAddress ||
    (currentOfferPaymentOption && currentOfferPaymentOption.BumpOffers.some(bo => bo.Product.requiresShippingAddress));
  if (useSameShippingAddress) OrderRequest.ShippingAddress = OrderRequest.BillingAddress;
  if (!requiresShippingAddress && OrderRequest.ShippingAddress) delete OrderRequest.ShippingAddress;
  const { deviceData } = state.checkout;

  if (deviceData) OrderRequest.deviceData = deviceData; //fraud protection

  if (location && location.search) {
    const { everflow_transaction_id } = queryString.parse(location.search);
    if (everflow_transaction_id) OrderRequest.everflow_transaction_id = everflow_transaction_id;
  }

  return Api.post('/orders', OrderRequest).then(resp => {
    dispatch({ type: CLEAR_PAYMENT_TOKEN });

    if (resp.status === undefined && resp.gatewayCode === undefined) {
      dispatch(setOrder(resp.Order));
      dispatch(setOrderFormMessage(null, 0));
      createOrderCompletedEvent(resp.Order, () => dispatch(checkoutSuccess(resp, false)));
      dispatch(modalActions.hideModal()); //added for update, no redirect

      if (resp.User) {
        dispatch(userActions.receiveLogin(resp.User));
        return new Promise((res, rej) => dispatch(userActions.getUserProducts()))
          .then(() => {
            dispatch(setOrderFormMessage(null, 0));
            dispatch(checkoutSuccess(resp, false)); //false for redirect
            dispatch(modalActions.hideModal()); //added for update, no redirect
          })
          .catch(e => console.log('error geting user products', e.message));
      } else {
        dispatch(setOrderFormMessage(null, 0));
        dispatch(checkoutSuccess(resp, false)); //false for redirect
        dispatch(modalActions.hideModal()); //added for upgrade, no redirect
      }
    } else if (resp.gatewayCode) {
      dispatch(setOrderFormMessage(null, resp.gatewayCode));
      dispatch(modalActions.hideModal());
      return Promise.resolve(true);
    } else {
      dispatch(setOrderFormMessage({ type: 'api', text: resp.errorMessage }, 999));
      dispatch(modalActions.hideModal());
      return Promise.resolve(true);
    }
  });
};

const processUpSell = (id, orderId) => (dispatch, getState) => {
  dispatch(modalActions.openModal('spinner'));
  const OrderId = orderId || getState().checkout.order.id;
  const { authToken } = getState().user;
  const upSellId = id;
  const url = `/orders/${OrderId}/upsell/${upSellId}`;

  return Api.post(url, {}, authToken).then(resp => {
    if (resp.status === undefined && resp.gatewayCode === undefined) {
      // purchasedProducts.push(UpSells[0]);

      //dispatch(trackUpsellSuccess(resp.Order, upSellId));
      createOrderCompletedEvent(resp.Order, () => dispatch(upsellSuccess(resp.Order, upSellId)));
      dispatch(setOrder(resp.Order));
      dispatch(setOrderFormMessage(null, 0));
      if (resp.User) {
        dispatch(userActions.receiveLogin(resp.User));
      }
      dispatch(ackUpsell());
    } else if (resp.gatewayCode) {
      dispatch(setOrderFormMessage(null, resp.gatewayCode));
      dispatch(modalActions.hideModal());

      return null;
    }
    dispatch(setOrderFormMessage(null, 999));
    dispatch(modalActions.hideModal());

    return null;
  });
};
const processStandaloneUpSell = (OfferId, OfferPaymentOptionId) => (dispatch, getState) => {
  dispatch(modalActions.openModal('spinner'));
  const { purchasedProducts } = getState().checkout;
  const { authToken } = getState().user;
  const url = `/orders/`;
  const orderRequest = createStandaloneUpsellOrderRequest(getState, OfferId, OfferPaymentOptionId);

  return Api.post(url, orderRequest, authToken).then(resp => {
    if (resp.status === undefined && resp.gatewayCode === undefined) {
      // purchasedProducts.push(UpSells[0]);
      dispatch(setStandaloneUpsellOrder(resp.Order));
      createOrderCompletedEvent(resp.Order, () => dispatch(checkoutSuccess(resp)));
      if (resp.User) {
        dispatch(userActions.receiveLogin(resp.User));
      }
      dispatch(setOrderFormMessage(null, 0));

      return purchasedProducts;
    } else if (resp.gatewayCode) {
      dispatch(setOrderFormMessage(null, resp.gatewayCode));
      dispatch(modalActions.hideModal());

      return null;
    }
    dispatch(setOrderFormMessage(null, 999));
    dispatch(modalActions.hideModal());

    return null;
  });
};

const getOrder = id => (dispatch, getState) => {
  const { authToken } = getState().user;
  return Api.get(`/orders/${id}`, authToken)
    .then(resp => {
      dispatch(setOrder(resp));
      return resp;
    })
    .catch(err => {
      console.log('Error retrieving order', err);
      return null;
    });
};

const resetOnLeavePage = () => dispatch => {
  dispatch(reset());
};

// ------------------------------------
// Action Handlers
// ------------------------------------
export const actions = {
  ackUpsell,
  addBraintreeClientInstance,
  getOrder,
  loadOffer,
  loadTotals,
  loadUserFromState,
  processOrder,
  processStandaloneUpSell,
  processThirdPartyCheckout,
  processUpgrade,
  processUpSell,
  receiveOffer,
  reset,
  resetOnLeavePage,
  resetUser,
  setCurrentPaymentMethod,
  setDeviceData,
  setRecaptchaToken,
  setGiftRecipient,
  setNewAddress,
  setUser,
  setOrderFormMessage,
  setPaymentMethod,
  setPaymentToken,
  setReceiptMessage,
  setSelectedBillingAddress,
  setSelectedPaymentOption,
  setSelectedShippingAddress,
  setSaveAddress,
  setDefaultBilling,
  setDefaultBillingAndShipping,
  setDefaultShipping,
  setSelectedUserPaymentId,
  setUseSameShippingAddress,
  toggleBumpOffer,
  toggleIsGift,
  updateBumpOfferQuantity,
  updateQuantity,
  updateUpsellState
};

export { selectors };
// ------------------------------------
// Reducer
// ------------------------------------

const defaultTotals = {
  default: true,
  1: {
    order: {
      bumps: {
        shipping: 0,
        subtotal: 0,
        tax: 0
      },
      installmentPayment: 0.0,
      numInstallments: 1,
      shipping: 0.0,
      subtotal: 0.0,
      tax: 0.0,
      total: 0.0
    },
    transaction: {
      numInstallments: 1,
      shipping: 0.0,
      subtotal: 0.0,
      tax: 0.0,
      total: 0.0
    }
  },
  2: {
    order: {
      bumps: {
        shipping: 0,
        subtotal: 4,
        tax: 0
      },
      installmentPayment: 0.0,
      numInstallments: 3,
      shipping: 0.0,
      subtotal: 0.0,
      tax: 0.0,
      total: 0.0
    },
    transaction: {
      numInstallments: 3,
      shipping: 0.0,
      subtotal: 0.0,
      tax: 0.0,
      total: 0.0
    }
  }
};

const params = queryString.parse(location.search);
const newUser = {};
if (params.firstname) {
  newUser.firstName = params.firstname;
}

if (params.lastname) {
  newUser.lastName = params.lastname;
}

if (params.email) {
  // Extra double protection to ensure that not url encoding survives
  newUser.email = decodeURIComponent(params.email);
}

const initalOrderRequest = {
  BillingAddress: { country: 'US' },
  BumpOfferIds: [],
  BumpOfferQuantities: [],
  isGift: false,
  giftRecipient: {},
  User: newUser,
  OfferId: null,
  OfferPaymentOptionId: null,
  ShippingAddress: { country: 'US' },
  PaymentMethod: { gateway: 'braintree' },
  Quantity: 1,
  UserPaymentMethodId: null
};

export const initialState = {
  braintreeClientInstances: {},
  currentUpsellIdx: 0,
  offer: {
    id: null,
    name: '',
    description: '',
    slug: '',
    ProductId: null,
    image: '',
    giftable: false,
    Product: {
      id: null,
      name: '',
      requiresShippingAddress: false,
      quantifiable: false
    },
    OfferPaymentOptions: []
  },
  order: {},
  standaloneUpsellOrder: {},
  orderFormMessage: {},
  orderRequest: clonedeep(initalOrderRequest),
  paramUserInfo: newUser,
  receiptMessage: null,
  selectedPaymentMethod: 'creditCard',
  slug: '',
  submitted: false,
  thirdPartyUserInfo: {},
  totals: { pending: true },
  upsellAckd: false,
  upsellState: '',
  useSameShippingAddress: true
};

export default function checkoutReducer(state = initialState, action) {
  switch (action.type) {
    case ACK_UPSELL:
      return {
        ...state,
        currentUpsellIdx:
          (action.currentUpsellIdx || state.currentUpsellIdx) >
          Math.max(
            ...getCurrentOfferPaymentOption({ checkout: state }).UpSells.map(us => us.OfferPaymentOptionUpSell.order)
          )
            ? action.currentUpsellIdx || state.currentUpsellIdx
            : (action.currentUpsellIdx || state.currentUpsellIdx) + 1,
        upsellAckd:
          (action.currentUpsellIdx || state.currentUpsellIdx) + 1 >
          Math.max(
            ...getCurrentOfferPaymentOption({ checkout: state }).UpSells.map(us => us.OfferPaymentOptionUpSell.order)
          )
      };
    case UPDATE_UPSELL_STATE:
      return {
        ...state,
        upsellState: action.upsellState
      };
    case ADD_BRAINTREE_CLIENT_INSTANCE:
      return {
        ...state,
        braintreeClientInstances: {
          ...state.braintreeClientInstances,
          [action.key]: action.instance
        }
      };
    case CLEAR_PAYMENT_TOKEN:
      return {
        ...state,
        orderRequest: {
          ...state.orderRequest,
          PaymentMethod: { gateway: 'braintree' }
        }
      };
    case RESET:
      return clonedeep(initialState);
    case RESET_USER:
      return {
        ...state,
        orderRequest: {
          ...state.orderRequest,
          User: { ...newUser },
          UserPaymentMethodId: null,
          BillingAddress: { ...state.orderRequest.BillingAddress, id: null },
          ShippingAddress: { ...state.orderRequest.ShippingAddress, id: null }
        }
      };
    case SET_SELECTED_SHIPPING_ADDRESS:
      return {
        ...state,
        useSameShippingAddress: false,
        orderRequest: {
          ...state.orderRequest,
          ShippingAddress: { id: action.id },
          setDefaultShipping: false
        }
      };
    case SET_SELECTED_BILLING_ADDRESS:
      return {
        ...state,
        orderRequest: {
          ...state.orderRequest,
          BillingAddress: { id: action.id }
        }
      };
    case SET_STANDALONE_UPSELL_ORDER:
      return {
        ...state,
        standaloneUpsellOrder: action.order
      };
    case SET_SAVE_ADDRESS_BILLING:
      return {
        ...state,
        orderRequest: {
          ...state.orderRequest,
          save: !state.orderRequest.save,
          setDefaultBilling: state.orderRequest.save ? false : state.orderRequest.setDefaultBilling,
          setDefaultShipping: state.useSameShippingAddress
            ? state.orderRequest.save
              ? false
              : state.orderRequest.setDefaultBilling
            : state.orderRequest.setDefaultShipping
        }
      };
    case SET_SAVE_ADDRESS_SHIPPING:
      return {
        ...state,
        orderRequest: {
          ...state.orderRequest,
          saveSeparateShipping: !state.orderRequest.saveSeparateShipping,
          setDefaultShipping: state.orderRequest.saveSeparateShipping ? false : state.orderRequest.setDefaultShipping
        }
      };
    case SET_DEFAULT_BILLING:
      return {
        ...state,
        orderRequest: {
          ...state.orderRequest,
          setDefaultBilling: !state.orderRequest.setDefaultBilling,
          save: state.orderRequest.save || !state.orderRequest.setDefaultBilling
        }
      };
    case SET_DEFAULT_BILLING_AND_SHIPPING:
      return {
        ...state,
        orderRequest: {
          ...state.orderRequest,
          setDefaultBilling: !state.orderRequest.setDefaultBilling,
          setDefaultShipping: !state.orderRequest.setDefaultBilling,
          save: state.orderRequest.save || !state.orderRequest.setDefaultBilling
        }
      };
    case SET_DEFAULT_SHIPPING:
      return {
        ...state,
        orderRequest: {
          ...state.orderRequest,
          setDefaultShipping: !state.orderRequest.setDefaultShipping,
          saveSeparateShipping: state.orderRequest.saveSeparateShipping || !state.orderRequest.setDefaultShipping
        }
      };
    case SET_ORDER:
      return {
        ...state,
        order: action.order
      };
    case SET_DEVICE_DATA:
      return {
        ...state,
        deviceData: action.deviceData
      };
    case SET_RECAPTCHA_TOKEN:
      return {
        ...state,
        recaptchaToken: action.recaptchaToken
      };
    case PROCESS_CHECKOUT_SUCCESS:
      return {
        ...state,
        submitted: action.submitted
      };
    case MARK_TOTALS_PENDING:
      return {
        ...state,
        totals: { ...state.totals, pending: true }
      };
    case UPDATE_TOTALS:
      return {
        ...state,
        totals: action.totals
      };
    case UPDATE_BUMP_OFFERS:
      return {
        ...state,
        orderRequest: {
          ...state.orderRequest,
          BumpOfferIds: action.BumpOfferIds,
          BumpOfferQuantities: action.BumpOfferQuantities
        }
      };
    case SET_THIRD_PARTY_USER_INFO:
      return {
        ...state,
        thirdPartyUserInfo: action.user
      };
    case SET_USER:
      return {
        ...state,
        orderRequest: {
          ...state.orderRequest,
          User: action.user
        }
      };
    case TOGGLE_IS_GIFT:
      return {
        ...state,
        orderRequest: {
          ...state.orderRequest,
          isGift: action.boolean
        }
      };
    case SET_GIFT_RECIPIENT:
      return {
        ...state,
        orderRequest: {
          ...state.orderRequest,
          giftRecipient: action.giftRecipient
        }
      };
    case SET_NEW_ADDRESS:
      return {
        ...state,
        orderRequest: {
          ...state.orderRequest,
          [action.addressType]: { ...action.address }
        },
        ...(action.addressType === 'BillingAddress' && action.address.country !== 'US'
          ? { useSameShippingAddress: false }
          : {})
      };
    case SET_PAYMENT_METHOD:
      return {
        ...state,
        selectedPaymentMethod: action.method
      };
    case SET_PAYMENT_TOKEN:
      return {
        ...state,
        orderRequest: {
          ...state.orderRequest,
          PaymentMethod: action.token
        }
      };
    case LOAD_OFFER_SUCCESS:
      return {
        ...state,
        offer: action.offer
      };
    case LOAD_OFFER_REQUEST:
      return {
        ...state,
        slug: action.slug
      };
    case SET_USE_SAME_SHIPPING_ADDRESS:
      return {
        ...state,
        useSameShippingAddress: action.val,
        orderRequest: {
          ...state.orderRequest,
          setDefaultShipping: !state.useSameShippingAddress ? state.orderRequest.setDefaultBilling : false
        }
      };
    case SET_USER_PAYMENT_ID:
      return {
        ...state,
        orderRequest: {
          ...state.orderRequest,
          UserPaymentMethodId: action.id,
          PaymentMethod: { gateway: 'braintree' }
        }
      };
    case SET_SELECTED_PAYMENT_OPTION:
      return {
        ...state,
        orderRequest: {
          ...state.orderRequest,
          OfferPaymentOptionId: action.id
        }
      };
    case SET_ORDER_FORM_MESSAGE:
      return {
        ...state,
        orderFormMessage: action.orderFormMessage
      };
    case SET_RECEIPT_MESSAGE:
      return {
        ...state,
        receiptMessage: action.receiptMessage
      };
    case SET_COOKIE:
      return {
        ...state,
        orderRequest: {
          ...state.orderRequest,
          cookie: action.cookie
        }
      };
    case SET_ORDER_REQUEST_OFFER_ID:
      return {
        ...state,
        orderRequest: {
          ...initalOrderRequest,
          OfferId: action.OfferId
        }
      };
    case UPDATE_QUANTITY:
      return {
        ...state,
        orderRequest: {
          ...state.orderRequest,
          Quantity: action.quantity
        }
      };
    default:
      return state;
  }
}
