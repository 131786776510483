/* eslint-disable class-methods-use-this */
/* eslint-disable no-new-func */
/* eslint-disable  no-cond-assign */

import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { actions } from 'modules/checkout';
import { actions as modalActions } from 'modules/modal';
import { actions as productActions } from 'modules/product';
import { actions as userActions } from 'modules/user';
import { getCurrentOfferPaymentOption } from 'modules/checkout/selectors';
import UpSellComponents from './components';

const contentUrl = process.env.USER_CONTENT_URL;

class Upsell extends PureComponent {
  constructor(props) {
    super(props);
    this.handleClickUpsell = this.handleClickUpsell.bind(this);
    this.handleDeclineUpsell = this.handleDeclineUpsell.bind(this);
    let paymentDescription;

    const { UpSell } = this.props;

    if (!(UpSell && UpSell.componentName && UpSellComponents[UpSell.componentName])) {
      this.props.history.push(`/checkout/${this.props.offer.slug}/#/receipt`);
    }

    const { numInstallments, interval } = Option;
    const { amount } = UpSell.OfferPaymentOptionUpSell;

    if (Option.numInstallments > 1) {
      paymentDescription = `Add this to your order for only ${numInstallments} ${interval}ly payments of only $ ${amount}.`;
    } else {
      paymentDescription = `Add this to your order for only $${amount}.`;
    }

    this.state = {
      offerSlug: this.props.offer.slug,
      amount,
      paymentDescription,
      numInstallments,
      interval
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const { ackUpsell, getUserProducts, userProducts, UpSell } = this.props;

    getUserProducts(() => {
      return userProducts.find(p => p.id === UpSell.Product.id) && ackUpsell();
    });

    if (!UpSell || UpSell.id === 'empty' || !UpSellComponents[UpSell.componentName]) {
      this.props.history.push(`/checkout/${this.props.offer.slug}/#/receipt`);
    }
  }

  handleClickUpsell = id => {
    this.props.processUpSell(id);
  };

  handleDeclineUpsell = () => {
    this.props.ackUpsell();
  };

  navigateToProduct = () => {
    const { slug } = this.props.offer.Product;
    if (slug) {
      this.props.history.push(`/${slug}`);
    }
    return null;
  };

  render() {
    const { UpSell } = this.props;
    const { amount, interval, offerSlug, paymentDescription, numInstallments } = this.state;

    if (!UpSell || UpSell.id === 'empty' || !UpSellComponents[UpSell.componentName]) {
      // navigating to product instead . . .
      return null;
    }
    // This (deprecated) line made UHC18 the default
    // const Component = UpSellComponents.UHC18;

    const Component = UpSellComponents[UpSell.componentName];

    return (
      <Component
        handleClickUpsell={() => this.handleClickUpsell(UpSell.id)}
        handleDeclineUpsell={() => this.handleDeclineUpsell(UpSell.id)}
        classes={this.props.classes}
        paymentDescription={paymentDescription}
        amount={amount}
        numInstallments={numInstallments}
        interval={interval}
        contentUrl={contentUrl}
        offerSlug={offerSlug}
        purchasedProductSlug={this.props.offer.Product.slug}
        rawHandleClickUpsell={this.handleClickUpsell}
        upSell={this.props.Upsell}
        allUpsells={this.props.allUpsells}
      />
    );
  }
}

Upsell.propTypes = {};

const mapStateToProps = state => ({
  offer: state.checkout.offer,
  orderRequest: state.checkout.orderRequest,
  userProducts: state.user.products,
  currentOfferPaymentOption: getCurrentOfferPaymentOption(state),
  UpSell: getCurrentOfferPaymentOption(state).UpSells.find(
    us => us.OfferPaymentOptionUpSell.order === state.checkout.currentUpsellIdx && !!us.componentName
  ),
  allUpsells: getCurrentOfferPaymentOption(state).UpSells.filter(
    us => us.OfferPaymentOptionUpSell.order === state.checkout.currentUpsellIdx
  )
});

const mapDispatchToProps = dispatch => ({
  disableUpsell: () => dispatch(actions.disableUpsell()),
  ackUpsell: () => dispatch(actions.ackUpsell()),
  setOrderFormMessage: gatewayCode => dispatch(actions.setOrderFormMessage(null, gatewayCode)),
  processUpSell: id => dispatch(actions.processUpSell(id)),
  openModal: modalType => dispatch(modalActions.openModal(modalType)),
  hideModal: () => dispatch(modalActions.hideModal()),
  getUserProducts: () => dispatch(userActions.getUserProducts()),
  addProducts: products => dispatch(productActions.addProductsToAll(products))
});

export default compose(withRouter, connect(mapStateToProps, mapDispatchToProps))(Upsell);
