import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'lib/react-markdown';
const createElement = React.createElement;
import * as styles from 'styles/index.scss';

const { markDownContainer } = styles;

function getCoreProps(props) {
  return props['data-sourcepos'] ? { 'data-sourcepos': props['data-sourcepos'] } : {};
}

function Heading(props) {
  const level = Math.max(2, props.level);
  return createElement(`h${level}`, getCoreProps(props), props.children);
}

const customRenderers = {
  link: ({ href, children }) => {
    return (
      <a rel="nofollow" target="_blank" href={href}>
        {children[0]}
      </a>
    );
  },
  heading: Heading
};

function generateTextChunks(currentString) {
  let newString = '';
  const chunks = [];

  iterateOverStringCharsCode(currentString, (charCode, idx) => {
    if (charCode === 10) {
      chunks.push(newString);
      newString = '';
    } else {
      newString += currentString.charAt(idx);
    }
  });

  chunks.push(newString);
  const filteredChunks = chunks.filter(c => c.length > 0);
  return filteredChunks;
}

class Markdown extends PureComponent {
  render() {
    let markdown = this.props.data && this.props.data.markdown ? this.props.data.markdown : null;
    markdown = !markdown && this.props.source ? this.props.source : markdown;
    const className = this.props.className ? this.props.className : '';
    return (
      <ReactMarkdown
        source={markdown}
        renderers={customRenderers}
        escapeHtml={false}
        className={className}
        {...this.props}
      />
    );
  }
}

Markdown.propTypes = {
  data: PropTypes.shape({
    markdown: PropTypes.string.isRequired
  }).isRequired
};

export default Markdown;
