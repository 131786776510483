// extracted by mini-css-extract-plugin
var _1 = "bottom-border";
var _2 = "event-description";
var _3 = "event-image";
var _4 = "event-image-container";
var _5 = "eventList";
var _6 = "expand-description-btn";
var _7 = "light-grey-background";
var _8 = "past-event";
var _9 = "row";
var _a = "title-mobile";
var _b = "white-background";
export { _1 as "bottom-border", _2 as "event-description", _3 as "event-image", _4 as "event-image-container", _5 as "eventList", _6 as "expand-description-btn", _7 as "light-grey-background", _8 as "past-event", _9 as "row", _a as "title-mobile", _b as "white-background" }
