import React from 'react';
import PropTypes from 'prop-types';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as styles from 'styles/index.scss';

const { USER_CONTENT_URL } = process.env;
const logoUrl = 'assets/images/FRN.svg';

const { modalCloseButton, modalContainer, modalHeader, modalFooter } = styles;

const ModalContainer = props => (
  <Modal
    centered
    className={modalContainer}
    isOpen={props.showModal}
    size={props.size || 'sm'}
    toggle={!props.disableClose ? props.hideModal : null}
  >
    <ModalHeader
      className={modalHeader}
      style={{
        textAlign: 'center',
        paddingBottom: '36px',
        borderBottom: 0,
        justifyContent: 'center'
      }}
      toggle={!props.disableClose ? props.hideModal : null}
    >
      <img width="75%" alt="logo" src={`${USER_CONTENT_URL}${logoUrl}`} />
    </ModalHeader>
    <ModalBody>{props.children}</ModalBody>
  </Modal>
);

ModalContainer.propTypes = {
  children: PropTypes.shape({}).isRequired,
  hideModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired
};

export default ModalContainer;
