/* eslint-disable */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Route } from 'react-router-dom';
import * as styles from 'styles/index.scss';
import _ from 'lodash';
import { faHome } from '@fortawesome/pro-regular-svg-icons/faHome';
import SingleCategory from './components/SingleCategory';
import CategoryWrapper from './components/CategoryWrapper';

const { expander } = styles;

@connect(state => ({ user: state.user }), null)
class InterviewCollectionV2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      product: this.props, //data for entire product
      nav: 'loading',
      currentContent: null, //the currenct ICContentItem the user is looking at
      navItems: [{ name: 'Collection', typeOfComponent: null, slug: null, icon: faHome }] //used to show what nav items are present in top};
    };
    this.handleNav = this.handleNav.bind(this);
    this.handleCategoryClick = this.handleCategoryClick.bind(this);

    //ROUTE stuff
    const thisPath = location.pathname.split('/');
    this.thisProductInPath = thisPath[2];
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    //TODO make call to API from
    this.setState({ product: this.props }, () => {
      //this.setState({product: importProductJson}, () => {

      this.setState({ nav: 'home' }, () => {
        //some basic URL routing, onload check if this is a link to content instead of Home, TODO make a util
        if (window.location.pathname.split('/')[3]) {
          const titleInUrl = window.location.pathname.split('/')[3].toLowerCase();
          if (titleInUrl.match('category')) {
            let found = this.state.product.Children.find(category => {
              return category.slug.toLowerCase() === titleInUrl;
            });
            found ? this.handleCategoryClick(found.id, true) : null;
          }
        }
      });
    });
  }
  //bypass used to not update the URL(used on first load)
  handleNav(name, typeOfComponent, slug, bypass = false) {
    this.setState({ nav: name });
  }

  //used to navigate into an ICCONtent item, bypass used to not update the URL (used on first load)
  handleCategoryClick(categoryID, bypass = false) {
    //ICContent is expecting the data at the category level, find the category clicked
    const currentContent = this.state.product.Children.find(category => {
      return category.id === categoryID;
    });
    this.setState({ currentContent: currentContent }, this.handleNav('content', null, null, bypass));
    window.scrollTo(0, 0);
  }

  render() {
    const { navItems, product } = this.state;
    const categorySlugRegex = new RegExp(`^${this.thisProductInPath}-`, 'g');
    return (
      <div>
        {/* create a route for the home page for this product */}
        <Route
          exact
          path={`/products/${this.thisProductInPath}`}
          render={routeProps => <CategoryWrapper {...routeProps} item={product} {...this.props} />}
        />
        {/* create a route for every category in this product */}
        {this.state.product
          ? this.state.product.Children.filter(item => item.type.toLowerCase() === 'interviewcategory').map(
              category => {
                let path = `/products/${this.thisProductInPath}/${category.slug.replace(categorySlugRegex, '')}`;
                return category.Children[0].type.toLowerCase() === 'interviewcategory' ? (
                  <Route
                    exact
                    path={path}
                    render={routeProps => (
                      <CategoryWrapper {...routeProps} productTitle={product.title} item={category} {...this.props} />
                    )}
                  />
                ) : (
                  <Route
                    exact
                    path={path}
                    render={routeProps => (
                      <SingleCategory
                        productTitle={product.title}
                        {...routeProps}
                        item={category}
                        path={path}
                        categoryID={category.id}
                        {...this.props}
                      />
                    )}
                  />
                );
              }
            )
          : null}
        {/* create a route for every group in this product, if present */}
        {this.state.product
          ? this.state.product.Children.filter(item => item.type.toLowerCase() === 'interviewcategory').map(
              category => {
                const groupSlugRegex = new RegExp(`^${category.slug.replace(categorySlugRegex, '')}-`, 'g');
                return category.Children[0].type.toLowerCase() === 'interviewcategory'
                  ? category.Children.map(group => {
                      let path = `/products/${this.thisProductInPath}/${category.slug.replace(
                        categorySlugRegex,
                        ''
                      )}/${group.slug.replace(categorySlugRegex, '').replace(groupSlugRegex, '')}`;
                      return (
                        <Route
                          exact
                          path={path}
                          render={routeProps => (
                            <SingleCategory
                              productTitle={product.title}
                              categoryTitle={category.title}
                              {...routeProps}
                              item={group}
                              path={path}
                              categoryID={group.id}
                              {...this.props}
                            />
                          )}
                        />
                      );
                    })
                  : null;
              }
            )
          : null}
      </div>
    );
  }
}

InterviewCollectionV2.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  Children: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.object
};

InterviewCollectionV2.defaultProps = {
  Children: [{}],
  data: {}
};

InterviewCollectionV2.displayname = 'InterviewCollection';

export default withRouter(InterviewCollectionV2);
