import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Button } from 'reactstrap';
import { InputGroup, Input } from 'reactstrap';
import * as styles from 'styles/index.scss';

const { clickable, registrationFormBack, registrationFormInput } = styles;

class RegistrationForm extends Component {
  state = {
    email: this.props.email,
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    message: '',
    success: false,
    inFocus: null
  };

  handleFieldChange = e => this.setState({ [e.target.name]: e.target.value });

  toggleFocus = e => {
    if (this.state.inFocus !== e.target.name) {
      this.setState({ inFocus: e.target.name });
    } else {
      this.setState({ inFocus: null });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    // validate passwords match
    if (this.state.password == this.state.confirmPassword) {
      this.props.createUser(this.state).then(success => {
        if (success) {
          this.setState({ success: true });
          setTimeout(this.props.hideModal, 750);
          // FIX
          this.props.history.push(`/products/wlc`);
        } else {
          this.setState({ message: "Sorry!  Something didn't work as expected.  Please try again." });
        }
      });
    } else {
      this.setState({ message: 'Passwords do not match.' });
    }
  };

  handleKeyDown = e => {
    if (e.key === 'Enter') {
      this.handleSubmit(e);
    }
  };

  checkPasswords = () => {
    const { password, confirmPassword } = this.state;
    if (password !== confirmPassword) this.setState({ message: 'Passwords do not match.' });
  };

  render() {
    const { email, firstName, lastName, password, confirmPassword, success } = this.state;

    if (success) {
      return (
        <div style={{ textAlign: 'center' }}>
          <span>Welcome to Food Revolution Network!</span>
        </div>
      );
    }

    let isValid = true;

    if (password !== confirmPassword) {
      isValid = false;
    }

    if (!email || !firstName || !lastName || !password || !confirmPassword) isValid = false;

    return (
      <div name="register">
        <InputGroup>
          <Input
            id="registerEmail"
            className="login-input"
            type="email"
            name="email"
            placeholder={this.state.inFocus === 'email' ? '' : 'Email'}
            value={email}
            onChange={this.handleFieldChange}
            onFocus={this.toggleFocus}
            onBlur={this.toggleFocus}
          />
        </InputGroup>
        <InputGroup>
          <Input
            className="login-input"
            type="text"
            name="firstName"
            placeholder={this.state.inFocus === 'firstName' ? '' : 'First Name'}
            value={firstName}
            onChange={this.handleFieldChange}
            onFocus={this.toggleFocus}
            onBlur={this.toggleFocus}
          />
        </InputGroup>
        <InputGroup>
          <Input
            className="login-input"
            type="text"
            name="lastName"
            placeholder={this.state.inFocus === 'lastName' ? '' : 'Last Name'}
            value={lastName}
            onChange={this.handleFieldChange}
            onFocus={this.toggleFocus}
            onBlur={this.toggleFocus}
          />
        </InputGroup>
        <InputGroup>
          <Input
            className="login-input"
            type="password"
            name="password"
            placeholder={this.state.inFocus === 'password' ? '' : 'Password'}
            value={password}
            onChange={this.handleFieldChange}
            onFocus={this.toggleFocus}
            onBlur={this.toggleFocus}
          />
        </InputGroup>
        <InputGroup>
          <Input
            className="login-input"
            type="password"
            name="confirmPassword"
            placeholder={this.state.inFocus === 'confirmPassword' ? '' : 'Confirm Password'}
            value={confirmPassword}
            onChange={this.handleFieldChange}
            onKeyDown={this.handleKeyDown}
            onBlur={(() => this.checkPasswords, this.toggleFocus)}
            onFocus={(() => this.checkPasswords, this.toggleFocus)}
          />
        </InputGroup>

        {this.state.message !== '' ? (
          <div color="contrast" style={{ textAlign: 'center', margin: '0 0 14px 0' }}>
            <span>{this.state.message}</span>
          </div>
        ) : null}

        <Button
          color="primary"
          className="pill"
          disabled={
            !isValid ||
            !document.getElementById('registerEmail') ||
            !document.getElementById('registerEmail').validity.valid
          }
          style={{ width: '100%' }}
          onClick={e => this.handleSubmit(e)}
        >
          Create Account
        </Button>

        <div className={registrationFormBack}>
          <span onClick={() => this.props.openModal('login')}>Already a member? </span>
          <span className={clickable} onClick={() => this.props.openModal('login')}>
            Log In
          </span>
        </div>
      </div>
    );
  }
}

export default withRouter(RegistrationForm);
