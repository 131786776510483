/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import * as styles from 'styles/index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const { recipeStarsContainer } = styles;

const Stars = props => {
  const { handleClickStar, mouseLeaveStar, toggleHoverStar, stars } = props;
  const starsArray = [false, false, false, false, false];
  for (let i = 1; i <= stars; i += 1) {
    starsArray[i - 1] = true;
  }

  return (
    <div className={recipeStarsContainer}>
      {starsArray.map((star, index) => {
        const hackyIndex = index + 100;
        return (
          <div
            key={hackyIndex}
            onClick={() => handleClickStar(index + 1)}
            onMouseEnter={() => toggleHoverStar(index + 1)}
            onMouseLeave={() => toggleHoverStar(0)}
          >
            <FontAwesomeIcon icon={[star ? 'fas' : 'far', 'star']} key={hackyIndex} color="#FFC107" />
          </div>
        );
      })}
    </div>
  );
};

Stars.propTypes = {
  handleClickStar: PropTypes.func.isRequired,
  stars: PropTypes.number.isRequired
};

export default Stars;
