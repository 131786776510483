import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card } from 'reactstrap';
import * as styles from 'styles/index.scss';

const { toolsCardContainer } = styles;

const ToolsList = props => (
  <div>
    {props.tools.map((tool, index) => (
      <Card key={tool.title + String(index)} className={toolsCardContainer}>
        <h5>{tool.title}</h5>
        <img src={tool.data.thumb.url} alt={tool.data.thumb.alt} />
        <span>{tool.data.description}</span>
        <Button color="primary" href={`http://${tool.data.url}`}>
          Click here to buy
        </Button>
      </Card>
    ))}
  </div>
);

ToolsList.propTypes = {
  tools: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired
};

export default ToolsList;
