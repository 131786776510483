//container
import React, {useCallback, useEffect, useState} from 'react';

import {filterCategories as filterCategoriesDefault} from './data';
//import {useUserState, useUserDispatch, loginUser} from '../../contexts/UserStore';

//import TagCheckbox from './components/TagCheckbox';
import TagCheckbox from '../GlobalSearch/components/TagCheckBox';

//import TagXBox from './components/TagXBox';
import TagXBox from '../GlobalSearch/components/TagXBox';

import Api from '../../lib/api';

//import CardList from '../../components/CardList';
import RecipeCard from '../../routes/Products/components/RecipeCard';

import debounce from 'lodash.debounce';

//import InputText from './components/InputText';
import InputText from '../Forms/Text';

import {connect} from 'react-redux';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBallotCheck} from '@fortawesome/pro-regular-svg-icons/faBallotCheck';
import {faTimes} from '@fortawesome/pro-regular-svg-icons/faTimes';

import {Button, Col, Container, Row} from 'reactstrap';

import SortByMenu from '../GlobalSearch/components/SortByMenu';

import Pagination from '../../components/PaginationV2';

import {useEffectDebugger, usePrevious} from '../../lib/useEffectDebugger';
import {track} from '../../lib/track';

import {
  clearCategories,
  createMatchBodyArrays,
  createQueryBody,
  getCheckedExcludedTagsByCategories,
  getUpdatedCategories,
  getDefaultOpenSearchQueryBody,
  indexMap,
  queryToOpenSearchQueryBody,
  SEARCH_RESULTS_PER_PAGE,
  sortMapper
} from './searchHelpers';
import SortByModalMobile from './SortByModalMobile';
const createTypesBody = types => {
  return {
    match: {
      type: types.length > 1 ? types.join(' or ') : types[0]
    }
  };
};

import {flattenDeep} from 'lodash';
import GridWrapper from '../GridWrapper/index';

import * as styles from 'styles/index.scss';
const {closeOutFilter, closeOutModal} = styles;

import {Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

//import queryString from 'query-string';
import queryString from 'qs';
/*
1. search by text
2. search by filter
3. a filter press triggers a new API hit
*/

const GlobalSearchV2 = ({navigation, authToken, hideFilterBy, types, userAnalytics}) => {
  //const ALL_TYPES = ['download', 'video', 'videoResource', 'week', 'article', 'link', 'recipe'];
  const [errorMessage, setErrorMessage] = useState('');
  const [searchText, setSearchText] = useState();
  const [filterCategories, setFilterCategories] = useState(filterCategoriesDefault); //load up the default state
  //the query string for text search
  const [queryStringArray, setQueryStringArray] = useState([]);
  const [doesNotMatchBodyArray, setDoesNotMatchBodyArray] = useState([]);
  const [matchBodyArray, setMatchBodyArray] = useState([]);
  const [orMatchBodyArray, setOrMatchBodyArray] = useState([]); //for 'or' categories, like contentTags
  const [openSearchQueryBody, setOpenSearchQueryBody] = useState({});
  const [response, setResponse] = useState([]);
  const [loading, setLoading] = useState(false);
  const [orderBy, setOrderBy] = useState('');
  const [curPage, setCurPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [finalPage, setFinalPage] = useState(1);
  const [sortArray, setSortArray] = useState([]);
  const [sortType, setSortType] = useState('newest'); //newest, alphabetical
  const [sortByIsOpen, setSortByIsOpen] = useState(false);
  const [sortByIsOpenMobile, setSortByIsOpenMobile] = useState(false);
  const [clearSearch, setClearSearch] = useState(false);
  const [onFirstLoad, setOnFirstLoad] = useState(true);
  const [searchTextHistory, setSearchTextHistory] = useState();
  const [sortByModalMobile, setSortByModalMobile] = useState(false);
  const API_ENDPOINT = '/elastic/global-search';

  //handles inputtext updates, for speed of UX Input manages its own state
  const handleChange = text => {
    setLoading(true);
    //  setSearchText(text);
    debouncer(text);
  };
  const toggleSortBy = () => {
    setSortByIsOpen(!sortByIsOpen);
  };
  const handleSortByType = type => {
    setSortType(type); //for the legacy view
    //mapper for legacy
    // const sortMapper = {alphabetical: 'alphabetical', newest: 'date', relevant: 'relevant'};
    setOrderBy(sortMapper[type]);
    setSortByIsOpen(false);
  };
  const toggleSortByMobile = () => {
    setSortByIsOpenMobile(!sortByIsOpenMobile);
  };

  /*OPEN SEARCH creation of query body.  Both querystring and match */
  useEffect(() => {
    if (!searchText) {
      setResponse([]);
      setTotalCount(0);
      setLoading(false);
      setFinalPage(0);
      setQueryStringArray([]); //back to default
      setSortArray([{createAt: 'desc'}]); //back to default
      return;
    }
    setCurPage(1); //resent page
    //create a queryBodyArray
    setQueryStringArray([
      {
        query_string: {
          query: searchText,
          auto_generate_synonyms_phrase_query: true
        }
      }
    ]);
    setSortArray([]); //empty is open search for relevant
    setSortType('relevant');
  }, [searchText]);

  //creates the match body/does not match body from the full object of filter categories
  useEffect(() => {
    const _matchBodyArray = [],
      _orMatchBodyArray = [];
    //check for restricted types that must go in match body
    if (types && types.length) {
      _matchBodyArray.push(createTypesBody(types));
    }
    if (!filterCategories || !filterCategories.length) return;
    const {_matchBodyArray: mba, _doesNotMatchBodyArray: dmba} = createMatchBodyArrays({filterCategories, types});

    const _doesNotMatchBodyArray = [];
    filterCategories.forEach(element => {
      if (element && element.tags.length)
        element.tags.forEach(item => {
          //add to doesNotMatch array

          if (item.excluded) {
            if (element.categoryType === 'ingredient') {
              _doesNotMatchBodyArray.push(
                createQueryBody({term: `"${item.name}"`, index: indexMap[element.categoryType]})
              );
            } else {
              _doesNotMatchBodyArray.push(createQueryBody({term: item.name, index: indexMap[element.categoryType]}));
            }
          }
          ///add to mustMatch array
          if (item.active && !item.excluded) {
            //if item is not andOnly, then add it to the orMatchBodyArray
            //andOnly is the logical operator, so it must include any of these items, andOnly is a property set on the tags in the data.js file
            if (item.andOnly) {
              if (element.categoryType === 'ingredient') {
                //make exact match
                _matchBodyArray.push(createQueryBody({term: `"${item.name}"`, index: indexMap[element.categoryType]}));
              } else {
                _matchBodyArray.push(createQueryBody({term: item.name, index: indexMap[element.categoryType]}));
              }
            }
            //these are the 'or' categories
            if (!item.andOnly) {
              if (element.categoryType === 'ingredient') {
                //make exact match
                _orMatchBodyArray.push(
                  createQueryBody({term: `"${item.name}"`, index: indexMap[element.categoryType]})
                );
              } else {
                _orMatchBodyArray.push(createQueryBody({term: item.name, index: indexMap[element.categoryType]}));
              }
            }
          }
        });
    });
    //corresponds to query_string should NOT match
    setDoesNotMatchBodyArray(_doesNotMatchBodyArray);
    //corresponds to query_string should match
    setMatchBodyArray(_matchBodyArray);
    setOrMatchBodyArray(_orMatchBodyArray);
    setCurPage(1); //reset page
  }, [filterCategories]);
  /********************************************************************* */
  //Create the query body that will hit our Opensearch endpoint
  /*
  {
  query: {
    bool: {
      should: defaultQueryStringArray, //searhText
      must: defaultMatchBodyArray,
      must_not: defaultDoesNotMatchBodyArray
    }
  }
}
*/
  useEffectDebugger(() => {
    if (onFirstLoad) return;

    if (!doesNotMatchBodyArray.length && !matchBodyArray.length && !queryStringArray.length) return;
    let setOpenSearchQueryBodyFlag = false;
    let _openSearchQueryBody = {
      from: (curPage - 1) * SEARCH_RESULTS_PER_PAGE,
      size: SEARCH_RESULTS_PER_PAGE,
      track_total_hits: true,
      query: {
        bool: {
          must: matchBodyArray ? matchBodyArray.concat(queryStringArray) : [], //[...matchBodyArray, ...queryStringArray] : [],
          must_not: doesNotMatchBodyArray ? doesNotMatchBodyArray : []
        }
      },
      sort: sortArray ? sortArray : []
    };

    if (
      queryStringArray &&
      queryStringArray.length &&
      ((orMatchBodyArray && orMatchBodyArray.length) || (matchBodyArray && matchBodyArray.length))
    ) {
      _openSearchQueryBody = {
        from: (curPage - 1) * SEARCH_RESULTS_PER_PAGE,
        size: SEARCH_RESULTS_PER_PAGE,
        track_total_hits: true,
        query: {
          bool: {
            should: orMatchBodyArray ? orMatchBodyArray.concat(queryStringArray) : [],
            minimum_should_match: 1,
            must: matchBodyArray ? matchBodyArray.concat(queryStringArray, orMatchBodyArray) : [], //[...matchBodyArray, ...queryStringArray] : [],
            must_not: doesNotMatchBodyArray ? doesNotMatchBodyArray : []
          }
        },
        sort: sortArray ? sortArray : []
      };
      setOpenSearchQueryBodyFlag = true;
    }
    if (!setOpenSearchQueryBodyFlag && orMatchBodyArray && orMatchBodyArray.length) {
      _openSearchQueryBody = {
        from: (curPage - 1) * SEARCH_RESULTS_PER_PAGE,
        size: SEARCH_RESULTS_PER_PAGE,
        track_total_hits: true,
        query: {
          bool: {
            should: orMatchBodyArray ? orMatchBodyArray.concat(queryStringArray) : [],
            minimum_should_match: 1,
            must: matchBodyArray ? matchBodyArray.concat(queryStringArray) : [], //[...matchBodyArray, ...queryStringArray] : [],
            must_not: doesNotMatchBodyArray ? doesNotMatchBodyArray : []
          }
        },
        sort: sortArray ? sortArray : []
      };
    }

    setOpenSearchQueryBody(_openSearchQueryBody);
  }, [curPage, doesNotMatchBodyArray, matchBodyArray, orMatchBodyArray, queryStringArray, sortArray]);

  const debouncer = useCallback(
    debounce(item => {
      setSearchText(item);
    }, 500),
    []
  );

  //conver AWS response to CardList props
  /* routeName?: string;
  title?: string;
  image?: {url: string; alt?: string};
  subtitle?: string;
  boxText?: string;
  slug?: string;
  data?: {
    url?: string; //for downloads
  };
  type?: string;
  cardAction?: 'download' | 'navigate' | 'link' | null;
};*/
  const _dataMassage = data => {
    return data.map(item => {
      const {createAt, image, id, slug, title} = item._source;
      return {createAt, image: {url: image}, slug: slug ? slug : id, title};
    });
  };
  const hitAPI = ({openSearchQueryBody}) => {
    track('button_clicked', userAnalytics ? {...userAnalytics, ...{ux: 'search'}} : {ux: 'search'});
    Api.post(API_ENDPOINT, openSearchQueryBody, authToken, {web: 'web'})
      .then(resp => {
        handleAPIresponse({resp});
      })
      .catch(error => {
        console.log(
          'err in geting search, url:************************************************************** ',
          API_ENDPOINT,
          error
        );
      });
  };
  const handleAPIresponse = ({resp}) => {
    const massagedData =
      resp && resp.result && resp.result.body && resp.result.body.hits && resp.result.body.hits.hits
        ? _dataMassage(resp.result.body.hits.hits)
        : null;
    massagedData && massagedData.length ? setResponse(massagedData) : setResponse([]);

    if (resp && resp.result && resp.result.body && resp.result.body.hits && resp.result.body.hits.total) {
      setTotalCount(resp.result.body.hits.total.value);
      Math.ceil(resp.result.body.hits.total.value / SEARCH_RESULTS_PER_PAGE) !== finalPage
        ? setFinalPage(Math.ceil(resp.result.body.hits.total.value / SEARCH_RESULTS_PER_PAGE))
        : null;

      massagedData && massagedData.length ? setResponse(massagedData) : setResponse([]);
      setOnFirstLoad(false);
    } else {
      setTotalCount(0);
      setFinalPage(0);
      setOnFirstLoad(false);
    }
  };
  //first load only
  useEffect(() => {
    let defaultOpenSearchQueryBody;
    if (window.location.search) {
      setLoading(true);
      defaultOpenSearchQueryBody = queryToOpenSearchQueryBody(window.location.search.split('?')[1]);

      //handle sort history
      if (defaultOpenSearchQueryBody.sort && defaultOpenSearchQueryBody.sort.length) {
        setSortArray(defaultOpenSearchQueryBody.query.sort);
        const mapper = {'title.keyword': 'alphabetical', createAt: 'newest'};
        let type = mapper[Object.keys(defaultOpenSearchQueryBody.sort[0])[0]]
          ? mapper[Object.keys(defaultOpenSearchQueryBody.sort[0])[0]]
          : 'relevant'; //relevant is the default search sort for a search query
        setSortType(type);
        if (!sortArray.length) setSortArray([]);
      }
      //to show labels for search text
      const searchText =
        defaultOpenSearchQueryBody && defaultOpenSearchQueryBody.query && defaultOpenSearchQueryBody.query.bool
          ? defaultOpenSearchQueryBody.query.bool.must.find(item => item.query_string && item.query_string.query)
          : '';
      //handl searchText history by updating current state to past search text
      if (searchText) {
        const text = searchText.query_string.query;
        setSearchText(text);
      }
      //handle filter checks in the UI
      //get the filters from the URL,
      //they look like {match: {contentTags: 'Italy'}}, {match: {ingredients: '"carrots"'}}
      //match body array looks like: match {contentTags: 'British Isles'}
      //these need to be added to the
      if (
        defaultOpenSearchQueryBody.query &&
        defaultOpenSearchQueryBody.query.bool &&
        defaultOpenSearchQueryBody.query.bool.must &&
        defaultOpenSearchQueryBody.query.bool.must.length
      ) {
        setMatchBodyArray(defaultOpenSearchQueryBody.query.bool.must.filter(item => item.match));
      }

      if (
        defaultOpenSearchQueryBody.query &&
        defaultOpenSearchQueryBody.query.bool &&
        defaultOpenSearchQueryBody.query.bool.must_not &&
        defaultOpenSearchQueryBody.query.bool.must_not.length
      ) {
        setDoesNotMatchBodyArray(defaultOpenSearchQueryBody.query.bool.must_not.filter(item => item.match));
      }

      setLoading(false);
    } else {
      clearCheckbox(); //reset the search, this is important because the filter component handles it own state (for speedy UX)
      defaultOpenSearchQueryBody = getDefaultOpenSearchQueryBody({typeString: types.join(', ')});
    }

    hitAPI({openSearchQueryBody: defaultOpenSearchQueryBody, authToken});
  }, []);
  //hit the API, with some debouncing
  useEffect(() => {
    setLoading(true);
    if (onFirstLoad) return;
    if (!openSearchQueryBody) {
      setLoading(false);
      return;
    }

    let defaultOpenSearchQueryBody;
    if (!openSearchQueryBody || !openSearchQueryBody.query) {
      //set default
      const typeString = types.join(', ');
      defaultOpenSearchQueryBody = getDefaultOpenSearchQueryBody({typeString: types.join(', ')});
    }
    const finalQuery =
      openSearchQueryBody && openSearchQueryBody.query ? openSearchQueryBody : defaultOpenSearchQueryBody;

    updateURLHistory();
    hitAPI({openSearchQueryBody: finalQuery, authToken});
  }, [openSearchQueryBody]);

  const updateURLHistory = () => {
    const urlPieces = [location.protocol, '//', location.host, location.pathname];
    let url = urlPieces.join('');
    const qsg = queryString.stringify(openSearchQueryBody);
    const newURL = url + `?${qsg}`;
    history.pushState({}, null, newURL);
  };
  //helpder method to get all the currently checked tags, returned by category
  const getCheckedTagsByCategories = (type = 'defaultTag') => {
    if (!filterCategories) return []; //def
    const checkedTags = filterCategories.map(category => {
      const activeTagsForThisCategory = category.tags.filter(tag => {
        return tag.active;
      });
      return {
        categoryName: category.categoryName,
        categoryType: category.categoryType,
        activeTags: activeTagsForThisCategory
      };
    });
    const final = checkedTags.filter(item => {
      return item.categoryType === type && item.activeTags.length;
    });
    return flattenDeep(final); //return all the checked tags, array of objects {name: 'xxx', active: true}
  };

  //creates sortArray for Open Search query
  const handleSortQuery = orderBy => {
    if (!response.length) return;
    if (orderBy === 'relevant') {
      setSortArray([]);
      return;
    }
    const mapper = {alphabetical: 'title.keyword', date: 'createAt'};
    const ascMapper = {alphabetical: 'asc', date: 'desc'};
    const _sortArray = [];
    setLoading(true);
    //created sort array, pushes in somethign like {creatAt: 'desc'}
    _sortArray.push({
      [mapper[orderBy]]: {
        order: ascMapper[orderBy] //{createAt: {order:'desc'}}
      }
    });
    setSortArray(_sortArray);
  };
  useEffect(() => {
    handleSortQuery(orderBy);
  }, [orderBy]);
  const [showModal, setShowModal] = useState(false);

  const turnPage = currentPage => {
    setCurPage(currentPage);
  };
  const handleRemoveFilter = ({item}) => {
    const {term, ingredients, contentTags} = item.match;
    const tagName = term ? term : ingredients ? ingredients : contentTags ? contentTags : '';
    //remove "" from tagName
    const tagNameNoQuotes = tagName.replace(/['"]+/g, '');

    handleCheckbox({tagName: tagNameNoQuotes, removeTag: true, type: 'exclude', shouldExcludeThisTag: false});
  };
  const handleCheckbox = ({tagName, removeTag, type, shouldExcludeThisTag, andOnly}) => {
    //get new filter categories
    const updatedCategories = getUpdatedCategories({
      tagName,
      removeTag,
      type,
      shouldExcludeThisTag,
      filterCategories,
      andOnly
    });

    setFilterCategories(updatedCategories);
  };
  //remove all checks, used when user begins to search via text.  option to clear text search results as well
  const clearCheckbox = (clearTextResults = true) => {
    setLoading(true);
    clearSearchInput();
    setFilterCategories(clearCategories());
  };
  const clearSearchInput = () => {
    setSortType('newest'); //default sort display
    setSearchText('');
    setClearSearch(true);
    setCurPage(1);
    //some race condition here, not sure where yet
    setTimeout(() => {
      setOpenSearchQueryBody(getDefaultOpenSearchQueryBody({typeString: types.join(', ')}));
      setLoading(false);
    }, 350);
    //setOpenSearchQueryBody(getDefaultOpenSearchQueryBody({typeString: types.join(', ')}));
  };
  const resetClearSearch = () => {
    setClearSearch(false);
  };

  return (
    <div>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)} size="lg" centered className="d-print-none">
        {/* <ModalBody w="xl"> */}

        <ModalHeader toggle={() => setShowModal(false)}>Filter Your Recipe Searches</ModalHeader>
        <ModalBody>
          {filterCategories
            ? filterCategories.map(item => {
                const {categoryName, tags} = item; //without this you lose reference to item.categoryName at toggleOpenCategory invoke
                //determin if current category has active tags
                let thisCategoryHasActiveTags =
                  typeof getCheckedTagsByCategories({filterCategories}).find(
                    item => item.categoryName === categoryName
                  ) != 'undefined';
                let thisCategoryHasActiveExcludedTags =
                  typeof getCheckedExcludedTagsByCategories({filterCategories}).find(
                    item => item.categoryName === categoryName && item
                  ) != 'undefined';

                //if there are activeTags and active Excluded Tags then we don't want to show the opacity marks
                if (thisCategoryHasActiveExcludedTags && thisCategoryHasActiveTags) {
                  thisCategoryHasActiveExcludedTags = false;
                  thisCategoryHasActiveTags = false;
                }

                return (
                  <Container>
                    <h5>{categoryName}</h5>
                    <Row className="ml-2">
                      <div fontSize="xs">Incl. - Exclude</div>
                    </Row>
                    {tags
                      ? tags.map((item, i) => {
                          const {active, excluded, name, type, andOnly} = item;
                          //Used to autocheck all unselected itms of the opposite of what is selected
                          const isXBoxAutoChecked =
                            thisCategoryHasActiveTags && !item.excluded && !item.active && item.type != 'ingredient';
                          const isCheckboxAutoChecked =
                            thisCategoryHasActiveExcludedTags &&
                            !item.excluded &&
                            !item.active &&
                            item.type != 'ingredient';

                          //Attempt to make local changes faster
                          let localActive = active,
                            localExcluded = excluded;
                          return (
                            <Row
                              className="ml-2"
                              space={3}
                              justifyContent="flex-start"
                              alignItems="flex-start"
                              id={`${i} ${item.name}`}
                            >
                              <div
                                className="mr-2"
                                onClick={() => {
                                  handleCheckbox({
                                    tagName: name,
                                    removeTag: false,
                                    type,
                                    andOnly
                                  });
                                  localActive = !localActive;
                                }}
                              >
                                <TagCheckbox checked={isCheckboxAutoChecked ? true : localActive} />
                              </div>
                              <div
                                className="mr-2"
                                onClick={() => {
                                  handleCheckbox({
                                    tagName: name,
                                    removeTag: false,
                                    type,
                                    shouldExcludeThisTag: 'exclude',
                                    andOnly: false
                                  });
                                  localExcluded = !localExcluded;
                                }}
                              >
                                <TagXBox checked={isXBoxAutoChecked ? true : localExcluded} />
                              </div>
                              <div>{name.replace(/\b\w/g, l => l.toUpperCase())}</div>
                            </Row>
                          );
                        })
                      : null}
                  </Container>
                );
              })
            : null}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setShowModal(!showModal)}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <SortByModalMobile
        isOpen={sortByModalMobile}
        toggle={() => {
          setSortByModalMobile(!sortByModalMobile);
        }}
        handleSortByType={handleSortByType}
        sortMapper={sortMapper}
      />
      {response ? (
        <React.Fragment>
          <Container>
            <Row className=" d-flex  align-items-center">
              <Col xs="7" md="6" className=" my-auto  pt-3">
                {/* <InputGroup py="0" px="0"> */}
                <InputText
                  handleChange={handleChange}
                  clearSearch={clearSearch}
                  type="text"
                  resetClearSearch={resetClearSearch}
                  // value={search}
                  // onKeyDown={this.hanldeKeyDownSearch}
                  // onChange={handleChange}
                  placeholder="Search..."
                  className="border-right-0"
                />
              </Col>
              {/* MOBILE BEGIN */}
              <Col xs="5" md="5" className=" my-auto   d-sm-none">
                <div className="d-sm-none d-flex flex-row-reverse ">
                  <div
                    className="btn btn-dark d-inline-flex pointerGlobal ml-1"
                    onClick={() => {
                      setSortByModalMobile(!sortByModalMobile);
                      toggleSortByMobile();
                    }}
                  >
                    <span className="pl-2 pr-2 align-middle">Sort </span>
                    {/* <span className="pl-2 d-none d-sm-block align-middle mr-1"> By</span> */}
                    <span className="align-middle">
                      <FontAwesomeIcon icon="caret-down" />
                    </span>
                  </div>
                  <div></div>
                  {hideFilterBy ? (
                    ''
                  ) : (
                    <div className="btn btn-dark d-inline-flex pointerGlobal" onClick={() => setShowModal(!showModal)}>
                      <span className="align-middle">
                        <FontAwesomeIcon icon={faBallotCheck}></FontAwesomeIcon>
                      </span>
                      <span className="pl-2 align-middle">Filter </span>
                      <span className="pl-1 d-none d-sm-block align-middle"> Results</span>
                    </div>
                  )}
                </div>
              </Col>

              {/* MOBILE END */}
              <Col xs="5" md="6" className="text-right d-none d-sm-block  my-auto">
                {hideFilterBy ? (
                  ''
                ) : (
                  <div className="btn btn-dark d-inline-flex pointerGlobal" onClick={() => setShowModal(!showModal)}>
                    <span className="align-middle">
                      <FontAwesomeIcon icon={faBallotCheck}></FontAwesomeIcon>
                    </span>
                    <span className="pl-2 align-middle">Filter </span>
                    <span className="pl-1 d-none d-sm-block align-middle"> Results</span>
                  </div>
                )}

                <div className="btn btn-dark d-inline-flex pointerGlobal ml-1" onClick={toggleSortBy}>
                  <span className="pl-2 align-middle">Sort </span>
                  <span className="pl-2 d-none d-sm-block align-middle mr-1"> By</span>
                  <span className="align-middle">
                    <FontAwesomeIcon icon="caret-down" />
                  </span>

                  <SortByMenu
                    sortType={sortType}
                    sortByIsOpen={sortByIsOpen}
                    toggleSortBy={toggleSortBy}
                    pkey="large-menu"
                    key="large-menu"
                    handleSortByType={handleSortByType}
                  />
                </div>
              </Col>
            </Row>
            {/* RESPONSIVE FULL SCREEN FILTERS   RESPONSIVE FULL SCREEN FILTER --- DESKTOP START */}
            <div>
              <div className="row d-flex flex-row pb-4 align-items-center row-eq-height">
                {searchText || searchTextHistory ? (
                  <div className="btn btn-success px-3 mx-2 ml-4 position-relative mt-1 d-flex align-items-center">
                    <div className="pr-4">
                      "
                      {searchText && searchText.length
                        ? `${searchText}`
                        : searchTextHistory
                        ? `${searchTextHistory}`
                        : ''}
                      "
                    </div>
                    <div
                      className={closeOutFilter}
                      onClick={() => {
                        clearSearchInput();
                      }}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </div>
                  </div>
                ) : null}
                {matchBodyArray
                  ? matchBodyArray
                      .filter(item => !item.match.type)
                      .map(item => {
                        const {term, contentTags, ingredients} = item.match;
                        return (
                          <div className="btn btn-success px-3 mx-2 ml-4 position-relative mt-1 d-flex align-items-center">
                            <div className="pr-4 text-capitalize">
                              {`${term ? term : ''} ${contentTags ? contentTags : ''}${ingredients ? ingredients : ''}`}{' '}
                            </div>
                            <div
                              className={closeOutFilter}
                              onClick={() => {
                                handleRemoveFilter({item});
                              }}
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </div>
                          </div>
                        );
                      })
                  : null}
                {orMatchBodyArray
                  ? orMatchBodyArray
                      .filter(item => !item.match.type)
                      .map(item => {
                        const {term, contentTags, ingredients} = item.match;
                        return (
                          <div className="btn btn-success px-3 mx-2 ml-4 position-relative mt-1 d-flex align-items-center">
                            <div className="pr-4 text-capitalize">
                              {`${term ? term : ''} ${contentTags ? contentTags : ''}${ingredients ? ingredients : ''}`}{' '}
                            </div>
                            <div
                              className={closeOutFilter}
                              onClick={() => {
                                handleRemoveFilter({item});
                              }}
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </div>
                          </div>
                        );
                      })
                  : null}
                {doesNotMatchBodyArray
                  ? doesNotMatchBodyArray.map(item => {
                      const {term, contentTags, ingredients} = item.match;
                      return (
                        <div className="btn btn-danger px-3 mx-2 ml-4 position-relative mt-1 d-flex align-items-center">
                          <div className="pr-4 text-capitalize" style={{textDecoration: 'line-through'}}>
                            {`${term ? term : ''} ${contentTags ? contentTags : ''}${ingredients ? ingredients : ''}`}{' '}
                          </div>
                          <div
                            className={closeOutFilter}
                            onClick={() => {
                              handleRemoveFilter({item});
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </div>
                        </div>
                      );
                    })
                  : null}
                {matchBodyArray.filter(item => !item.match.type).length ||
                doesNotMatchBodyArray.length ||
                orMatchBodyArray.length ? (
                  <div
                    className="px-3 mx-2 "
                    style={{cursor: 'pointer'}}
                    onClick={() => {
                      clearCheckbox();
                    }}
                  >
                    <u>Clear All</u>
                  </div>
                ) : null}
              </div>
            </div>
            {/* END DESKTOP RESPONSIVE FILTERS */}
            <Row>
              <Col>
                Results: {totalCount} items found {searchText ? `for '${searchText}' ` : ''}
                {finalPage ? `(${finalPage} page${finalPage > 1 ? 's' : ''})` : ''}
              </Col>
              <Col className={'text-right'}>{sortType ? `Sort by: ${sortType}.` : ''}</Col>
            </Row>
          </Container>
          <GridWrapper
            size="4"
            grid={response.map(item => {
              const {image, slug, title} = item;

              //data massage
              const cardProps = {key: title, slug, title, data: {thumb: image}};
              return <RecipeCard {...cardProps} />;
            })}
          />
          <Container>
            <div className="d-flex justify-content-center pt-2 py-md-1">
              {totalCount > 0 ? (
                <Pagination
                  curPage={curPage}
                  finalPage={finalPage}
                  handleParentNav={turnPage}
                  className={{display: 'inline'}}
                  pageClassName={{display: 'inline'}}
                  searchResultsPerPage={SEARCH_RESULTS_PER_PAGE}
                />
              ) : (
                ''
              )}
            </div>
          </Container>
        </React.Fragment>
      ) : (
        'Loading...'
      )}
    </div>
  );
};

export default GlobalSearchV2;
