/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/pro-regular-svg-icons/faFilePdf';
import { faFileAudio } from '@fortawesome/pro-regular-svg-icons/faFileAudio';
import { Col, Row } from 'reactstrap';
import * as styles from 'styles/index.scss';

const { apmDownloadsList } = styles;

class DownloadsList extends PureComponent {
  render() {
    const {
      downloadsList: { Children }
    } = this.props;

    return (
      <Row className={apmDownloadsList}>
        {Children.map(download => {
          console.log('download in downloadsList', download, 'text ', download.data.text);
          return (
            <Col style={{ cursor: 'pointer' }}>
              <a href={download.data.url} download>
                <FontAwesomeIcon icon={download.data.type === 'MP3' ? faFileAudio : faFilePdf} />
                <div>
                  <span>{download.data.text}</span>
                </div>
              </a>
            </Col>
          );
        })}
      </Row>
    );
  }
}

DownloadsList.propTypes = {};

export default DownloadsList;
