import React from 'react';
import PropTypes from 'prop-types';
import { Container, Col, Row } from 'reactstrap';
import * as styles from 'styles/index.scss';

const { profileBraintreeFormWarning, profileBraintreeFormField } = styles;

const ExpirationDateField = props => {
  const {
    expirationDateBlurred,
    expirationDateValid,
    expirationDateBlurredExisting,
    expirationDateValidExisting
  } = props.expirationDateValidity;

  const isExisting = props.isExisting;

  let idPrefix = isExisting ? 'existing' : 'new';

  return (
    <Col xs={6} style={{ padding: 0 }}>
      {isExisting ? (
        <React.Fragment>
          <div
            id={idPrefix + '-expiration-date'}
            className={profileBraintreeFormField}
            style={{ marginBottom: expirationDateBlurredExisting && !expirationDateValidExisting ? '0' : '32px' }}
          />
          {expirationDateBlurredExisting && !expirationDateValidExisting ? (
            <div className={profileBraintreeFormWarning}>
              <span>Invalid Exp. Date</span>
            </div>
          ) : null}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div
            id={idPrefix + '-expiration-date'}
            className={profileBraintreeFormField}
            style={{ marginBottom: expirationDateBlurred && !expirationDateValid ? '0' : '32px' }}
          />
          {expirationDateBlurred && !expirationDateValid ? (
            <div className={profileBraintreeFormWarning}>
              <span>Invalid Exp. Date</span>
            </div>
          ) : null}
        </React.Fragment>
      )}
    </Col>
  );
};

ExpirationDateField.propTypes = {
  expirationDateValidity: PropTypes.shape({
    expirationDateBlurred: PropTypes.bool.isRequired,
    expirationDateValid: PropTypes.bool.isRequired,
    expirationDateBlurredExisting: PropTypes.bool.isRequired,
    expirationDateValidExisting: PropTypes.bool.isRequired
  }).isRequired
};

// const mapStateToProps = state => ({
//   braintreeValidity: state.checkout.braintreeValidity,
// });

export default ExpirationDateField;
