/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft } from '@fortawesome/pro-regular-svg-icons/faAngleDoubleLeft';
import { faAngleDoubleRight } from '@fortawesome/pro-regular-svg-icons/faAngleDoubleRight';
import { faPlay } from '@fortawesome/pro-solid-svg-icons/faPlay';
import { faPause } from '@fortawesome/pro-solid-svg-icons/faPause';
import * as styles from 'styles/index.scss';

const { audioPlayerMobileControlsContainer, apmcButton } = styles;

class Controls extends Component {
  handleBackMouseDown = () => {
    this.goingBackward = setInterval(this.moveBackwardOnce, 500);
  };

  handleBackMouseUp = () => {
    clearInterval(this.goingBackward);
  };

  handleForwardMouseDown = () => {
    this.goingForward = setInterval(this.moveForwardOnce, 500);
  };

  handleForwardMouseUp = () => {
    clearInterval(this.goingForward);
  };

  moveForwardOnce = () => {
    const { player } = this.props;
    const { currentTime } = player.current || { currentTime: 0 };

    player.current.currentTime = currentTime + 10;
  };

  moveBackwardOnce = () => {
    const { player } = this.props;
    const { currentTime } = player.current || { currentTime: 0 };
    player.current.currentTime = currentTime && currentTime > 10 ? currentTime - 10 : 0;
  };

  render() {
    return (
      <div className={audioPlayerMobileControlsContainer}>
        <div className="align-self-start">
          <FontAwesomeIcon
            icon={['fas', 'backward']}
            onClick={this.moveBackwardOnce}
            onMouseDown={this.handleBackMouseDown}
            onMouseUp={this.handleBackMouseUp}
          />
        </div>
        <div className="d-flex align-items-center ml-3 mr-3">
          <div style={{ textAlign: 'center' }} onClick={this.props.handleClickPauseOrPlay}>
            <div className={apmcButton}>
              <FontAwesomeIcon icon={this.props.isPlaying ? faPause : faPlay} style={{ height: '100%' }} />
            </div>
          </div>
        </div>
        <div className="align-self-end">
          <FontAwesomeIcon
            icon={['fas', 'forward']}
            onMouseDown={this.handleForwardMouseDown}
            onMouseUp={this.handleForwardMouseUp}
            onClick={this.moveForwardOnce}
          />
        </div>
      </div>
    );
  }
}

Controls.propTypes = {};

export default Controls;
