import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {actions} from 'modules/modal';
import {actions as userActions} from 'modules/user';
import {actions as applicationActions} from 'modules/application';
import {Navbar, NavbarBrand} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import HeaderUserInfo from './components/HeaderUserInfo';
import * as styles from 'styles/index.scss';
import FRNLogo from 'images/SVG/FRN';
import TNTGLogo from 'images/SVG/TNTG';
import FFLogo from 'images/SVG/FF';
import PPTLogo from 'images/SVG/PPT';
import WLCLogo from 'images/SVG/WLC';
import HBCLogo from 'images/SVG/HBC';
import FRNMobile from 'images/SVG/FRNMobile';
import Seperator from '../Seperator';
import HBC from '../../images/SVG/HBC';
import HHCLogo from '../../images/SVG/HHC';

const {appHeaderContainer, appNavBarBrand} = styles;
const productSlugToLogos = {
  tntg: <TNTGLogo white />,
  'tntg-impactkit': <TNTGLogo white />,
  ff: <FFLogo white />,
  'ff-impactkit': <FFLogo white />,
  ppt: <PPTLogo white />,
  hbc: <HBCLogo white />,
  hhc: <HHCLogo white />,
  // wlc: <WLCLogo white height={50} width={100} />,
  default: <FRNLogo white />
};

@connect(state => ({user: state.user}), {
  getUserProducts: actions.getUserProducts,
  navigateTo: applicationActions.navigateTo,
  toggleProductNavigation: applicationActions.toggleProductNavigation
})
class HeaderV2 extends PureComponent {
  constructor(props) {
    super(props);
    this.path = this.props.location && this.props.location.pathname.split('/');
    this.handleNav = this.handleNav.bind(this);
  }

  renderLogo() {
    if (this.path[1] && this.path[1] === 'products' && this.productInPath && productSlugToLogos[this.path[2]])
      return productSlugToLogos[this.path[2]];
  }
  handleNav(nav) {
    //logo navigation if in products
    if (nav === 'product' && this.path[1] && this.path[1] === 'products' && this.productInPath) {
      console.log(' this.props.history.push(`/products/${this.productInPath}`);');
      this.props.history.push(`/products/${this.productInPath}`);
    } else {
      this.props.history.push('/products');
    }
  }

  render() {
    const isCheckout = this.path[1] === 'checkout';
    const {firstName, img, isAuthenticated, showSideBar} = this.props;
    this.path = this.props.location && this.props.location.pathname.split('/');

    this.productInPath = this.path[2];
    const hasProduct =
      this.path[1] && this.path[1] === 'products' && this.productInPath && productSlugToLogos[this.path[2]];
    return (
      <Navbar className={`${appHeaderContainer} no-print`}>
        <div className="d-flex  flex-row align-middle ">
          <div className={`ml-2 ml-lg-3 d-flex flex-row pointerGlobal align-middle  `}>
            {this.productInPath == 'frs23' ? (
              <div onClick={this.props.toggleProductNavigation} style={{fontSize: '1.5em'}} className="mr-4 pl-2">
                <FontAwesomeIcon icon="bars" />
              </div>
            ) : null}
          </div>
          {/* <div className="seperatorHeader"></div> */}
          {hasProduct ? <Seperator height={'24px'} /> : null}
          <div className={` align-middle pointerGlobal ml-1 ml-lg-0`} onClick={() => this.handleNav('product')}>
            {/* Determine which logo to show based off the of the product slug */}
            {hasProduct ? productSlugToLogos[this.path[2]] : null}
          </div>
          <div className={`d-none d-lg-inline-block `} onClick={() => this.handleNav('home')}>
            <FRNLogo white height={40} width={105} />
          </div>

          <div className="d-lg-none">
            <FRNMobile white height={40} width={50} />
          </div>
        </div>
        {!isCheckout ? (
          showSideBar ? (
            <div style={{color: 'transparent'}}>.</div>
          ) : null // <FontAwesomeIcon onClick={toggleSideBar} className={appNavBarBarIcon} icon="bars" />
        ) : (
          <div style={{color: 'transparent'}}>.</div>
        )}
        <div className="flexCenter">
          {isAuthenticated ? (
            <HeaderUserInfo firstName={firstName} img={img} />
          ) : (
            <NavbarBrand href="/" className="mr-auto" className={appNavBarBrand}>
              Food Revolution Network
            </NavbarBrand>
          )}
        </div>
      </Navbar>
    );
  }
}

HeaderV2.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  logoutUser: PropTypes.func.isRequired,
  openModal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  isAuthenticated: state.user.isAuthenticated,
  img: state.user.avatarUrl,
  firstName: state.user.firstName
});

const mapDispatchToProps = {
  logoutUser: userActions.logoutUser,
  openModal: actions.openModal,

  getUserProducts: actions.getUserProducts
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderV2));
