/* eslint-disable max-len */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import atob from 'lib/atob';
import { Button, Form, FormGroup, FormText, Input, InputGroup, InputGroupAddon, Label, Modal } from 'reactstrap';
import { actions } from 'modules/user';
import * as styles from 'styles/index.scss';

const {
  editUserInfo,
  editUserInfoContainer,
  editUserInfoFormInput,
  editUserInfoButtons,
  editUserPicButton,
  editUserPicModalButton,
  editUserPicModalContainer,
  isOnboardEditUserTitle
} = styles;

@connect(() => ({}), { loadProfile: actions.loadProfile })
class EditUserInfo extends Component {
  constructor(props) {
    super(props);

    const { avatarUrl, firstName, lastName, email, image = '', imageData } = this.props.targetUser || this.props.user;

    const { facebook, twitter, city, state, country, goal } = this.props.targetUser
      ? this.props.targetUser.public || {}
      : props.user.public || {};

    this.initialState = {
      firstName,
      lastName,
      email,
      facebook,
      twitter,
      city,
      state,
      country,
      goal,
      avatarUrl,
      // image: avatarUrl, // image name - to display in DOM
      imageData: '', // image data on input upload
      croppedImageData: '',
      picSavedSuccess: false,
      saveFail: false,
      // savingPic: false,
      showEditPicModal: false,
      formIsValid: false
    };

    // Save original values just in case
    this.state = this.initialState;
    this.formRef = React.createRef();
    this.picInput = React.createRef();
  }

  componentDidMount() {
    this.props.loadProfile();
    this.getValidity();
  }

  componentDidUpdate(prevProps) {
    const user = this.props.targetUser ? this.props.targetUser : this.props.user;
    const prevUser = this.props.targetUser ? prevProps.targetUser : prevProps.user;
    if (user.public && !prevUser.public) {
      const updatedPublicData = (({ facebook, twitter, city, state, country, goal }) => ({
        facebook,
        twitter,
        city,
        state,
        country,
        goal
      }))(user.public);
      this.setState(updatedPublicData);
    }
    if (
      user &&
      (prevUser.firstName != user.firstName || prevUser.lastName != user.lastName || prevUser.email != user.email)
    ) {
      const { firstName, lastName, email } = user;
      this.setState({ firstName, lastName, email });
    }
  }

  handleFieldChange = e => {
    this.setState({ [e.target.name]: e.target.value });
    this.getValidity();
  };

  // for everything but profile pic
  handleSubmit = async e => {
    const { targetUser } = this.props;
    const { croppedImageData } = this.state;
    e.preventDefault();
    const { email, firstName, lastName } = this.state;
    const publicData = (({ facebook, twitter, city, state, country, goal }) => ({
      facebook,
      twitter,
      city,
      state,
      country,
      goal
    }))(this.state);
    const updatedUserData = {
      email,
      firstName,
      lastName,
      public: publicData
    };

    this.props.updateProfile(updatedUserData, targetUser).then(resp => {
      if (resp) {
        if (typeof this.props.onSuccess === 'function') {
          this.props.onSuccess();
        }
        if (!targetUser) {
          this.props.loadProfile();
          this.forceUpdate();
        }
      } else {
        this.setState({ saveFail: true });
      }
    });
  };

  getValidity = () => {
    const formIsValid = this.formRef && this.formRef.current && this.formRef.current.checkValidity();
    this.setState({ formIsValid });
  };

  UserInfoFormGroup = props => (
    <FormGroup>
      <Label for={`profile-${props.name}`}>
        {props.label}&nbsp;
        <small>
          ({props.required ? 'required' : 'optional'}
          {props.smallText || ''})
        </small>
      </Label>
      <InputGroup>
        {props.prepend ? <InputGroupAddon addonType="prepend">{props.prepend}</InputGroupAddon> : null}
        <Input
          className={props.className}
          type={props.type || 'text'}
          name={props.name}
          required={props.required}
          value={props.value}
          onChange={props.onChange}
          id={`profile-${props.name}`}
        />
      </InputGroup>
      {props.text ? <FormText color="muted">{props.text}</FormText> : null}
    </FormGroup>
  );

  render() {
    const {
      UserInfoFormGroup,
      props: { targetUser }
    } = this;
    const fields = [
      { name: 'firstName', label: 'First Name', required: true },
      {
        name: 'lastName',
        label: 'Last Name',
        required: true,
        text: 'Your full last name will not be shared publicy, only the first inital.'
      },
      { name: 'email', label: 'Email', required: true, type: 'email', smallText: ', never shared' },
      {
        name: 'facebook',
        label: 'Facebook Profile',
        prepend: 'facebook.com/',
        smallText: ', visible to the community'
      },
      { name: 'twitter', label: 'Twitter Handle', prepend: '@', smallText: ', visible to the community' },
      { name: 'city', label: 'City / Town', smallText: ', visible to the community' },
      { name: 'state', label: 'State / Province', smallText: ', visible to the community' },
      { name: 'country', label: 'Country', smallText: ', visible to the community' },
      {
        name: 'goal',
        label: "What's the main reason you've joined us here?",
        type: 'textarea',
        text: 'If you share this, it will be visible to the community.'
      }
    ];

    return (
      <Form innerRef={this.formRef}>
        {fields.map(f => (
          <UserInfoFormGroup {...f} value={this.state[f.name]} onChange={this.handleFieldChange} />
        ))}
        <Button onClick={e => this.handleSubmit(e)}>Save</Button>
      </Form>
    );
  }
}

EditUserInfo.propTypes = {
  onCancel: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
  }).isRequired
};

export default EditUserInfo;
