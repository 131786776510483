/* eslint-disable max-len */
/* eslint-disable jsx-a11y/media-has-caption */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/pro-regular-svg-icons/faBookmark';
import { faHeart } from '@fortawesome/pro-regular-svg-icons/faHeart';
import { faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons/faHeart';
import { faBookmark as faBookmarkSolid } from '@fortawesome/pro-solid-svg-icons/faBookmark';
import * as styles from 'styles/index.scss';
import Markdown from 'routes/Products/components/Markdown';

const {
  audioPlayerMobileHeaderContainer,
  apmhOne,
  apmhTwo,
  apmhThree,
  amphFullTitle,
  apmhTitle,
  apmhInterviewee
} = styles;

class Header extends PureComponent {
  constructor(props) {
    super(props);

    this.bio = props.Children.find(child => child.type === 'bio');
  }

  render() {
    const { isWide, liked, toggleLiked, bookmarked, toggleBookmarked, title } = this.props;
    const {
      title: interviewee,
      data: { description, img, linkto }
    } = this.bio;

    return (
      <div className={audioPlayerMobileHeaderContainer}>
        {isWide ? null : <div className={apmhOne} style={{ backgroundImage: `url('${img}')` }} />}
        <div className={apmhTwo}>
          <div className={apmhInterviewee}>
            <h5
              className={amphFullTitle}
              style={{ paddingRight: '8px', marginTop: 0, ...(!isWide ? { marginBottom: 0 } : {}) }}
            >
              <span>
                {interviewee}
                {isWide ? <span>&nbsp;&ndash;&nbsp;{title}</span> : ''}
              </span>
            </h5>
            {/*<div style={{maxWidth: '60px', position: 'absolute', right: '15px'}}>
              <FontAwesomeIcon
                style={{color: '#F5515F', marginRight: '6px', cursor: 'pointer'}}
                icon={liked ? faHeartSolid : faHeart}
                onClick={toggleLiked}
              />
              <FontAwesomeIcon
                style={{cursor: 'pointer'}}
                icon={bookmarked ? faBookmarkSolid : faBookmark}
                onClick={toggleBookmarked}
              />
              {linkto && linkto.url ? (
                <FontAwesomeIcon
                  style={{cursor: 'pointer', marginLeft: '6px'}}
                  icon={['far', 'globe']}
                  onClick={() => window.open(linkto.url)}
                />
              ) : null}
            </div>*/}
          </div>
          <div className={apmhTitle}>
            {isWide ? <Markdown source={description} escapeHtml={false} /> : <span>{title}</span>}
            {/* <span>{isWide ? description : title}</span> */}
          </div>
        </div>
      </div>
    );
  }
}

Header.propTypes = {};

export default Header;
