import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, InputGroup, Input } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { actions as userActions } from 'modules/user';

import queryString from 'query-string';
import * as styles from 'styles/index.scss';

const {
  inputGroupContainer,
  passwordLoginFormActions,
  passwordLoginFormContainer,
  passwordLoginFormInputClick
} = styles;

@connect(() => ({}), {
  loginDiscourse: userActions.loginUserDiscourse
})
class PasswordLoginForm extends Component {
  static propTypes = {
    email: PropTypes.string,
    password: PropTypes.string,
    loginUser: PropTypes.func,
    sendMagicLink: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      email: this.props.email,
      password: '',
      error: '',
      inFocus: null
    };
  }

  handleFieldChange = e => this.setState({ [e.target.name]: e.target.value });

  toggleFocus = e => {
    if (this.state.inFocus !== e.target.name) {
      this.setState({ inFocus: e.target.name });
    } else {
      this.setState({ inFocus: null });
    }
  };

  handleKeyDown = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handleLogin({ email: this.state.email, password: this.state.password });
    }
  };

  handleLogin = creds => {
    const { location } = this.props;
    // FIX default is to move to slug 'wlc' if there is no lastProductVisited saved in localStorage
    const appState = JSON.parse(localStorage.getItem('app'));
    const lastProductVisited = appState && appState.lastProductVisited ? appState.lastProductVisited : '';
    const lastProductVisitedUrl = `/products/${lastProductVisited || ''}`;
    if (this.state.password.length > 0) {
      this.props.loginUser({ email: this.state.email, password: this.state.password }).then(resp => {
        if (resp) {
          this.setState({ error: '' });
          this.props.hideModal();
          if (!this.props.opts || !this.props.opts.noRedirect) {
            const qvs = queryString.parse(location.search);
            if (qvs.sso && qvs.sig) {
              this.props.loginDiscourse({ email: this.props.email }, qvs, this, lastProductVisitedUrl);
            } else {
              this.props.history.push(qvs.redirect ? decodeURIComponent(qvs.redirect) : lastProductVisitedUrl);
            }
          }
        } else {
          this.setState({ error: 'Incorrect email or password.' });
        }
      });
    } else {
      this.setState({ error: 'Please enter your password' });
    }
  };

  navigateToSalesPage = () => {
    window.location = 'https://wlc.foodrevolution.org/whole';
  };

  componentDidMount() {
    if (this.props.opts && this.props.opts.magicLinkExpired) {
      //MagicLink expired, notify user
      this.setState({
        error: 'Your password reset link has expired.  Please request another one.'
      });
    }
  }

  render() {
    return (
      <div className={passwordLoginFormContainer}>
        <div className={inputGroupContainer}>
          <InputGroup>
            <Input
              id="loginEmail"
              className="login-input"
              type="email"
              name="email"
              placeholder={this.state.inFocus === 'email' ? '' : 'Email'}
              value={this.state.email}
              onChange={this.handleFieldChange}
              onFocus={this.toggleFocus}
              onBlur={this.toggleFocus}
            />
          </InputGroup>
          <InputGroup>
            <Input
              className="login-input"
              type="password"
              name="password"
              placeholder={this.state.inFocus === 'password' ? '' : 'Password'}
              value={this.state.password}
              onKeyDown={this.handleKeyDown}
              onChange={this.handleFieldChange}
              onFocus={this.toggleFocus}
              onBlur={this.toggleFocus}
            />
          </InputGroup>

          {this.state.error !== '' ? (
            <div className="text-center mt-2">
              <span className="text-danger">{this.state.error}</span>
            </div>
          ) : null}
        </div>

        <div className="text-center">
          <Button
            size="lg"
            block
            disabled={
              !this.state.email || !this.state.password || !document.getElementById('loginEmail').validity.valid
            }
            onClick={this.handleSubmit}
            onClick={() => this.handleLogin({ email: this.state.email, password: this.state.password })}
            className="mb-2 mt-1"
          >
            Log In
          </Button>
        </div>

        <div className={passwordLoginFormActions}>
          <div onClick={() => this.props.openModal('reset', 1, false)}>
            <span className={`${passwordLoginFormInputClick}`}>Having trouble logging in?</span>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(PasswordLoginForm);
