import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'lib/react-markdown';
import * as styles from 'styles/index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const { checklistItem, checklistItemCheckBox, checklistItemCheckImg, checklistItemCheckBoxChecked } = styles;

const ChecklistItem = props => {
  let {
    updateValue,
    name,
    data: { text, url },
    value,
    modalBodyIndex
  } = props;
  const icon = value ? ['fas', 'check-square'] : ['far', 'square'];
  const checkBoxClass = value ? checklistItemCheckBoxChecked : '';
  url = null;
  return (
    <div className={checklistItem} onClick={e => updateValue(name, url, modalBodyIndex, e)}>
      <div className={checklistItemCheckBox}>
        <FontAwesomeIcon icon={icon} className={checkBoxClass} color={value ? '#007BFF' : '#DDDDDD'} />
      </div>
      <div>
        <a href={url}>{url}</a>
        <span for={name}>
          <ReactMarkdown source={text} />
        </span>
      </div>
    </div>
  );
};

ChecklistItem.propTypes = {
  change: PropTypes.func.isRequired,
  data: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired
};

export default ChecklistItem;
