import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactAvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import atob from 'lib/atob';
import { Button, Col, Container, InputGroup, Input, Modal, Row } from 'reactstrap';
import { actions } from 'modules/user';
import * as styles from 'styles/index.scss';

const {
  editPicControls,
  editPicDropZone,
  editPicDropZoneActive,
  editPicDropZonePlaceholder,
  editPicDropZonePlaceholderTitle,
  editPicDropZonePlaceholderOr
} = styles;

@connect(() => ({}), {
  loadProfile: actions.loadProfile,
  refreshToken: actions.refreshToken,
  updateUserProfilePic: actions.updateUserProfilePic
})
class EditPic extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: null,
      saving: false,
      rotate: 0
    };

    this.dropzoneRef = React.createRef();
  }

  handleDrop = accepted => {
    this.setState({ image: accepted[0] });
  };
  handleScale = e => {
    const scale = parseFloat(e.target.value);
    this.setState({ scale });
  };
  handleRotate = e => {
    var rotate = this.state.rotate;
    rotate = rotate > 270 ? 0 : (rotate += 90);
    this.setState({ rotate });
  };

  updateImages = imgUrl => {
    const event = new CustomEvent('update-profile-image', { detail: { imgUrl } });
    document.dispatchEvent(event);
  };

  handleSubmit = async e => {
    const canvasScaled = this.editor.getImageScaledToCanvas();
    const pic = atob(canvasScaled.toDataURL('image/png'));
    this.setState({ saving: true });
    const avatarUrlOnSuccess = await this.props.updateUserProfilePic(pic);
    this.setState({ picSavedSuccess: !!avatarUrlOnSuccess });
    if (avatarUrlOnSuccess) {
      this.updateImages(avatarUrlOnSuccess);
      this.props.loadProfile();
      this.props.refreshToken();
      if (typeof this.props.onSuccess === 'function') {
        this.props.onSuccess();
      }
      // window.location.reload()
    }
  };

  handleCropData = croppedImageData => {
    this.setState({ croppedImageData });
  };

  setEditorRef = editor => (this.editor = editor);
  setDropZoneRef = dropzone => (this.dropzoneRef = dropzone);

  render() {
    const { image, scale, saving, rotate } = this.state;
    return (
      <div>
        <Dropzone
          ref={this.dropzoneRef}
          onDrop={this.handleDrop}
          disableClick={Boolean(image)}
          className={editPicDropZone}
          activeClassName={editPicDropZoneActive}
        >
          {image ? (
            <ReactAvatarEditor
              ref={this.setEditorRef}
              width={250}
              height={250}
              image={this.state.image}
              borderRadius={125}
              scale={scale}
              rotate={rotate}
            />
          ) : (
            <div className={editPicDropZonePlaceholder}>
              <FontAwesomeIcon icon={['fas', 'image']} size="3x" />
              <div className={editPicDropZonePlaceholderTitle}>Drag a profile photo here</div>
              <div className={editPicDropZonePlaceholderOr}>or</div>
              <Button onClick={this.dropzoneRef.open}>Select a photo from your computer</Button>
            </div>
          )}
        </Dropzone>
        {image ? (
          <Container fluid className={editPicControls}>
            <Row className="justify-content-center align-items-center">
              <div className="mr-2">
                <FontAwesomeIcon icon={['fas', 'image']} size="sm" className="mr-1" />
                <input
                  name="scale"
                  type="range"
                  onChange={this.handleScale}
                  min="1"
                  max="2"
                  step="0.01"
                  defaultValue="1"
                />
                <FontAwesomeIcon icon={['fas', 'image']} size="lg" className="ml-1" />
              </div>
              <div className="ml-2">
                <Button onClick={this.handleRotate} color="light">
                  <FontAwesomeIcon flip="horizontal" icon={['fas', 'undo']} />
                </Button>
              </div>
            </Row>
            <Row>
              <Col>
                <Button onClick={this.handleSubmit} disabled={saving}>
                  {saving ? 'Saving...' : 'Save'}
                </Button>
              </Col>
            </Row>
          </Container>
        ) : null}
      </div>
    );
  }
}

EditPic.propTypes = {};
//   onCancel: PropTypes.func.isRequired,
//   onSuccess: PropTypes.func.isRequired,
//   user: PropTypes.shape({
//     avatarKey: PropTypes.string.isRequired
//   }).isRequired
// };

export default EditPic;
