import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import * as styles from 'styles/index.scss';

const { specialReportItem, specialReportsSpacing } = styles;

const SpecialReportsListItem = props => (
  <div className={specialReportItem}>
    <img src={props.data.thumb.url} alt={props.data.thumb.alt} />
    <div className={specialReportsSpacing}>
      <span>{props.data.description}</span>
    </div>
    <Button color="primary" href={`${props.data.url}`}>
      Click to download
    </Button>
    <div>
      <span>{props.data.bonusDetails ? props.data.bonusDetails : null}</span>
    </div>
  </div>
);

SpecialReportsListItem.propTypes = {
  data: PropTypes.shape({
    description: PropTypes.string.isRequired,
    bonusDetails: PropTypes.string,
    thumb: PropTypes.shape({
      alt: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    }).isRequired,
    url: PropTypes.string.isRequired
  }).isRequired
};

export default SpecialReportsListItem;
