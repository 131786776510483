/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import * as styles from 'styles/index.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';

// IMPORTANT
// recipe or resource slugs MUST include 'recipe' or 'resources'
// or this component will not render

const { wlcNavContainer } = styles;

class WLCNav extends Component {
  constructor(props) {
    super(props);
    // this.state = { location: null };
    this.path = location.pathname.split('/');
    this.thisProductInPath = this.path[2];

    this.borderBottom = { borderBottom: '2px solid #007BFF' };
    this.state = {
      isOpen: false
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  move = loc => () => {
    if (loc === 'back') {
      this.props.history.goBack();
    } else {
      this.props.history.push(`/products/${this.thisProductInPath}/${loc}`);
    }
  };

  getSlugsForThisWeek = () => {
    const { sortedWeeksList, thisWeek } = this.props;
    const slug = location.pathname.split('/')[3];
    let slugs = { weekSlug: thisWeek.slug, recipes: 'recipes', resources: 'resources' };
    // 'This week's' slug
    if (slug === 'resources' || slug === 'recipes' || !slug) {
      return slugs;
    }

    let found = false;
    sortedWeeksList.every(week => {
      if (found) {
        //optimize/ end more loop if found
        return false;
      }
      const resourcesList = week.Children.find(child => child.type === 'resourcesList');
      const recipesList = week.Children.find(child => child.type === 'recipesList');

      // if we are on this week's recipeList or resourceList
      if (resourcesList.slug === slug || recipesList.slug === slug) {
        found = true;
      }

      if (!found) {
        const thisResource = resourcesList && resourcesList.Children.find(resource => resource.slug === slug);
        const thisRecipe = recipesList && recipesList.Children.find(recipe => recipe.slug === slug);

        // we are on this specific resource
        if (thisResource) {
          found = true;
          // we are on this specific recipe
        } else if (thisRecipe) {
          found = true;
        }
      }

      if (found) {
        slugs.resources = resourcesList.slug;
        slugs.recipes = recipesList.slug;
      }
    });

    return slugs;
  };

  getItemType = () => {
    const { thisWeek } = this.props;
    const slug = location.pathname.split('/')[3];
    if (!slug || slug.includes('week') || slug.includes('getting-started')) {
      return 'week';
    }

    const thisWeekRecipesList = thisWeek.Children.find(child => child.type === 'recipesList');
    const thisWeekResourcesList = thisWeek.Children.find(child => child.type === 'resourcesList');

    if (slug === 'resources' || (thisWeekResourcesList && thisWeekResourcesList.slug === slug)) {
      return 'resourcesList';
    }

    if (slug === 'recipes' || (thisWeekRecipesList && thisWeekRecipesList.slug === slug)) {
      return 'recipesList';
    }

    return null;
  };

  render() {
    const {
      move,
      props: { location }
    } = this;
    const thisLocation = location.pathname.split('/')[3];
    // const isActive = (slug) => thisLocation && thisLocation.includes(slug) && thisLocation !== slug;
    const { recipes, resources, weekSlug } = this.getSlugsForThisWeek();
    const contentItemType = this.getItemType();

    // no display on a week
    if (contentItemType === 'week' || thisLocation === 'community') {
      return null;
    }

    const items = [
      { text: 'Action Guide', slug: weekSlug, active: false },
      { text: 'Recipes', slug: recipes, active: contentItemType === 'recipesList' },
      { text: 'Resources', slug: resources, active: contentItemType === 'resourcesList' }
    ];

    //Fix hack turns off nav bar on video resource page

    if (thisLocation.includes('video-resource')) {
      return null;
    }

    return (
      <div className={wlcNavContainer} fluid>
        <Navbar light expand="md">
          <Collapse isOpen navbar>
            <Nav navbar>
              <NavItem onClick={move('back')} className="pl-md-5 pr-5 py-3">
                &lt; Back
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

WLCNav.propTypes = {
  title: PropTypes.string.isRequired
};

export default withRouter(WLCNav);
