import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { actions } from 'modules/user';
import * as styles from 'styles/index.scss';
import { faGrid2 } from '@fortawesome/pro-solid-svg-icons/faGrid2';
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser';
const {
  sideBarLogo,
  headerUserInfoV2Container,
  headerUserInfoV2Dropdown,
  headerUserInfoImg,
  headerUserInfoV2Logo,
  headerUserInfoText,
  headerUserInfoV2ProductContainer,
  flexCenter,
  dropDownZ
} = styles;

const mapStateToProps = state => state;

@connect(state => ({ user: state.user }), { logoutUser: actions.logoutUser, getUserProducts: actions.getUserProducts })
class HeaderUserInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      img: props.img ? props.img : '',
      open: false,
      openProducts: false,
      windowWidth: window.innerWidth
    };
  }

  //Force reload image when user updates
  componentDidMount() {
    document.addEventListener('update-profile-image', e => {
      const { imgUrl } = e.detail;
      this.setState({ img: imgUrl + '?akey=' + new Date().getTime() });
    });
    if (this.props.user.isAuthenticated) {
      this.props.getUserProducts();
    }

    window.Beacon && Beacon('config', { text: 'Your button text"' });

    window.addEventListener('resize', this.updateDimensions);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.user.isAuthenticated && this.props.user.isAuthenticated) {
      this.props.getUserProducts();
    }
  }
  openBeacon = () => window.Beacon && Beacon('open');

  toggleOpen = () => {
    this.setState({ open: !this.state.open });
  };
  toggleOpenProducts = () => {
    this.setState({ openProducts: !this.state.openProducts });
  };

  navigateToProfile = () => {
    this.props.history.push('/profile/');
  };
  navigateToProduct = productSlug => {
    if (productSlug) this.props.history.push('/products/' + productSlug);
  };

  handleLogout = () => {
    this.props.logoutUser();
  };

  updateDimensions = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateDimensions);
  };

  render() {
    const { open, openProducts } = this.state;
    let {
      user: { products }
    } = this.props;
    //filtering out bonuses, these are displayed within products,  they are products within prodcuts
    products = products.filter(item => {
      return item.name.search(/bonuses/i) === -1;
    });
    const path = location.pathname.split('/');
    const productInPath = path[2];

    return (
      <div className="flexCenter">
        <NavLink to="/products">
          <Button className="mr-3" style={{ borderRadius: '40px' }}>
            <FontAwesomeIcon icon={faGrid2} />{' '}
            <span className={' headerUserInfoV2ProductContainerText'}>&nbsp;My Products</span>
          </Button>
        </NavLink>
        {/* for user account */}
        <Dropdown isOpen={open} toggle={this.toggleOpen} className={headerUserInfoV2Dropdown}>
          <DropdownToggle tag="div" className={headerUserInfoV2Container}>
            <span className={headerUserInfoText}>Hello, {this.props.firstName}</span>&nbsp;
            <span className={headerUserInfoText}>
              <FontAwesomeIcon icon="caret-down" />
            </span>
            <div
              className={headerUserInfoImg}
              style={this.state.img ? { backgroundImage: `url('${this.state.img}')` } : { background: '#CCC' }}
            >
              {!this.state.img ? <FontAwesomeIcon icon={faUser} /> : null}
            </div>
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem onClick={this.navigateToProfile}>Account Settings</DropdownItem>
            {window.Beacon ? <DropdownItem onClick={this.openBeacon}>Need Help?</DropdownItem> : null}
            <DropdownItem divider />
            <DropdownItem onClick={this.handleLogout}>Sign Out</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

HeaderUserInfo.propTypes = {
  firstName: PropTypes.string.isRequired,
  img: PropTypes.string
};

export default withRouter(HeaderUserInfo);
