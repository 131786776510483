//Interview Category create the headshot rows
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as styles from './styles.scss';

const { ICCircleImage } = styles;
//CategoryWrapper --> CategoryList --> InterviewCategory
//InterviewCategory shows a list of speakers (names and pics) for a given category on the home page (CategoryWrapper)
//A better name might be SpeakerList
class InterviewCategory extends Component {
  constructor(props) {
    super(props);
    this.state = { home: false };
  }
  componentDidMount() {
    this.setState({ loaded: true });
  }
  render() {
    return this.state.loaded ? (
      <div
        onClick={() => {
          this.props.handleCategoryClick(this.props.category.id);
        }}
        className="col-xs-12 col-sm-4 mt-4 btn InterviewCategory"
      >
        <div className="row px-1 px-sm-4 my-2">
          <div className="card h-100 w-100 ">
            <div className="EP-broadcastTitle d-flex align-items-center justify-content-center w-100 px-md-5 text-center">
              <div className="EP-textCenter p-0">
                {' '}
                {this.props.category && this.props.category.title ? this.props.category.title : null}
              </div>
            </div>
            <div className="card-body" role="list">
              {this.props.category && this.props.category.bios
                ? this.props.category.bios.map((bio, index) => {
                    return (
                      <div key={bio.id} className="d-flex align-items-center pl-2 py-1" role="listitem">
                        <div className={`${ICCircleImage} pr-3 text-center`}>
                          <img src={bio.data.img} alt={`${bio.name} + profile image`} />
                        </div>
                        <div className="pl-2 InterviewCategorySpeakerName"> {bio.name}</div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div>Loading</div>
    );
  }
}
InterviewCategory.propTypes = {
  category: PropTypes.object.isRequired,
  handleCategoryClick: PropTypes.func.isRequired
};
export default InterviewCategory;
