/* eslint-disable max-len */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import PropTypes from 'prop-types';
import Data from 'containers/Data';
import { Col, Container, Row } from 'reactstrap';
import * as styles from 'styles/index.scss';
import Markdown from '../Markdown';

const { guidelinesContainer } = styles;

const Guidelines = props => {
  const {
    data: { text }
  } = props;

  return (
    <Container className={guidelinesContainer}>
      <Row>
        <Col>
          <Markdown source={text} />
        </Col>
      </Row>
    </Container>
  );
};

Guidelines.propTypes = {
  data: PropTypes.shape({
    text: PropTypes.string.isRequired
  }).isRequired
};

Guidelines.displayname = 'Guidelines';

export default Data(Guidelines, '/content/wlc-community-guidelines/wlc');
