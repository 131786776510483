import client from 'braintree-web/client';
import hostedFields from 'braintree-web/hosted-fields';

//FIX Error handling

// eslint-disable-next-line
const env = process.env.nodeEnv.SANDBOX ? 'sandbox' : 'production';

export default actions => {
  const { closeModal, disableUI, reenableUI, onReceiveToken, setBraintreeValidity, blurBraintreeField } = actions;

  client.create({ authorization: process.env.BRAINTREE_TOKENIZATION_KEY }, (err, clientInstance) => {
    if (err) {
      console.log('doing a message: ', 999);
    }

    hostedFields.create(
      {
        client: clientInstance,
        styles: {
          input: {
            margin: '0 0 8px 0',
            'font-size': '12px',
            'font-family': 'Proxima Nova, Helvetica, Arial',
            color: 'black'
          },
          '.placeholder': {
            color: 'lightgrey'
          },
          '.invalid': {
            color: 'red'
          }
        },
        fields: {
          cvv: {
            selector: '#existing-cvv',
            placeholder: 'CVV'
          },
          expirationDate: {
            selector: '#existing-expiration-date',
            placeholder: 'Exp. date (MM/YY)'
          }
        }
      },
      (hostedFieldsErr, hostedFieldsInstance) => {
        if (hostedFieldsErr) {
          console.log('hostedFieldsErr:', hostedFieldsErr);
          closeModal();
        }

        if (!hostedFieldsInstance) {
          console.log('doing an error message: ', 999);
          closeModal();
        }

        if (hostedFieldsInstance) {
          // hostedFieldsInstance.on('focus', () => {
          //   setPaymentMethod('braintree');
          //   setSelectedUserPaymentId('0');
          // });

          hostedFieldsInstance.on('validityChange', e => {
            const { fields, emittedBy } = e;
            const field = emittedBy;
            const thisField = fields[emittedBy];
            const validity = thisField.isValid;
            console.log('validity change: ', emittedBy);
            setBraintreeValidity('Existing', field, validity);
          });

          hostedFieldsInstance.on('blur', e => {
            const { emittedBy } = e;
            console.log('blur: ', emittedBy);
            blurBraintreeField('Existing', emittedBy);
          });

          const button = document.getElementById('updateExistingCreditCard');
          button.addEventListener('click', () => {
            disableUI();
            hostedFieldsInstance.tokenize((tokenizeErr, payload) => {
              if (tokenizeErr) {
                console.log('doing a message: ', 600);
                closeModal();
              } else {
                console.log('process order with payload.nonce: ', payload.nonce);
                // reenableUI();
                console.log('calling onReceiveToken');
                onReceiveToken(payload);
                // processOrder(payload.nonce)
                //   .then((resp) => {
                //     if (resp) addProducts(resp);
                //   })
                //   .catch(() => {
                //     console.log('doing a message: ', 600);
                //   });
              }
            });
          });
        }
      }
    );
  });
};
