/* eslint-disable max-len */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Card, Modal, Container, Col, Row } from 'reactstrap';
import { actions } from 'modules/user';
import BraintreeForm from '../BraintreeForm';
import ExpirationDateField from '../BraintreeForm/components/ExpirationDate';
import CVVField from '../BraintreeForm/components/CVV';
import createNew from 'braintree/createNew';
import updateExisting from 'braintree/updateExisting';
import * as styles from 'styles/index.scss';

const {
  updateModalContainer,
  updateNewModalButtons,
  updateExistingModalButtons,
  updateModalUserPaymentMethod,
  updateModalStateButtons,
  updateModalStateContainer,
  profileBraintreeFormContainer
} = styles;

@connect(() => ({}), {
  createPaymentMethod: actions.createPaymentMethod,
  getPaymentMethods: actions.getPaymentMethods,
  setPreferredPaymentMethodForASubscription: actions.setPreferredPaymentMethodForASubscription,
  updatePaymentMethod: actions.updatePaymentMethod
})
class UpdateModal extends PureComponent {
  constructor(props) {
    super(props);
    this.defaultState = {
      cvvValid: false,
      numberValid: false,
      expirationDateValid: false,
      cvvBlurred: false,
      numberBlurred: false,
      expirationDateBlurred: false,
      uiDisabled: false,
      cvvValidExisting: false,
      cvvBlurredExisting: false,
      expirationDateValidExisting: false,
      expirationDateBlurredExisting: false,
      success: false,
      error: false,
      errorMessage: null,
      update: false
    };
    this.state = this.defaultState;
    this.processNewPaymentMethod = this.processNewPaymentMethod.bind(this);
    this.updatePaymentMethod = this.updatePaymentMethod.bind(this);
    this.textInput = React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen && this.props.isOpen) {
      createNew({
        blurBraintreeField: this.blurBraintreeField,
        disableUI: this.disableUI,
        reenableUI: this.reenableUI,
        onReceiveToken: this.processNewPaymentMethod,
        setBraintreeValidity: this.setBraintreeValidity,
        closeModal: this.props.close
      });
      updateExisting({
        blurBraintreeField: this.blurBraintreeField,
        disableUI: this.disableUI,
        reenableUI: this.reenableUI,
        onReceiveToken: this.updatePaymentMethod,
        setBraintreeValidity: this.setBraintreeValidity,
        closeModal: this.props.close
      });
    } else if (prevProps.isOpen && !this.props.isOpen) {
      // reset state on close
      this.reset();
    }
  }

  blurBraintreeField = (cardType, field) => {
    if (!this.state[`${field}Blurred${cardType}`]) {
      this.setState({ [`${field}Blurred${cardType}`]: true });
    }
  };

  setBraintreeValidity = (cardType, field, validity) => {
    this.setState({ [`${field}Valid${cardType}`]: validity });
  };

  async processNewPaymentMethod(nonce) {
    const { createPaymentMethod, orderId } = this.props;
    const response = await createPaymentMethod(nonce, orderId);
    if (response && !response.message) {
      this.setState({
        success: true,
        uiDisabled: false
      });
      this.props.refreshSubscriptions();
      this.props.getPaymentMethods();
    } else {
      this.setState({ error: true, ...(response.message && { errorMessage: response.message }) });
    }
  }

  selectExistingPaymentMethod = paymentMethodId => async () => {
    this.setState({ uiDisabled: true });
    const { setPreferredPaymentMethodForASubscription, orderId } = this.props;
    const response = await setPreferredPaymentMethodForASubscription(orderId, paymentMethodId);
    if (response && !response.message) {
      this.props.refreshSubscriptions();
      this.setState({
        success: true,
        uiDisabled: false
      });
    } else {
      this.setState({ error: true, ...(response.message && { errorMessage: response.message }) });
    }
  };

  async updatePaymentMethod(nonce) {
    this.setState({ uiDisabled: true });
    const { updatePaymentMethod, updatingPaymentMethodId, orderId } = this.props;
    const response = await updatePaymentMethod(nonce, updatingPaymentMethodId, orderId);
    if (typeof response === 'string') {
      this.setState({
        update: true,
        updateStatus: response
      });
    } else if (response && !response.message) {
      this.props.getPaymentMethods();
      this.setState({
        success: true,
        uiDisabled: false
      });
    } else {
      this.setState({ error: true, ...(response.message && { errorMessage: response.message }) });
    }
  }

  disableUI = () => {
    this.setState({ uiDisabled: true });
  };

  reenableUI = () => {
    this.setState({ uiDisabled: false });
  };

  reset = () => {
    this.setState(this.defaultState);
  };

  close = () => {
    const { toggleUpdate } = this.props;
    this.reset();
    toggleUpdate();
  };

  renderModalContainer = () => {
    const {
      updatingPaymentMethodId,
      isOpen,
      paymentString,
      status,
      toggleUpdate,
      type,
      updatingProduct,
      updatingNumber,
      UserPaymentMethods
    } = this.props;
    const {
      success,
      uiDisabled,
      error,
      update,
      numberValid,
      cvvValid,
      expirationDateValid,
      cvvValidExisting,
      expirationDateValidExisting
    } = this.state;

    const disableNewCardButton = !numberValid || !cvvValid || !expirationDateValid;
    const disableUpdateCardButton = !expirationDateValidExisting || !cvvValidExisting;

    let thisCard = UserPaymentMethods.filter(uPM => uPM.id === updatingPaymentMethodId);

    return (
      <div
        style={uiDisabled ? { visibility: 'hidden', height: '0', padding: '0' } : { padding: '16px' }}
        className={updateModalContainer}
      >
        <h5>Update Payment Method</h5>
        <div style={{ textAlign: 'center' }}>
          <span>{updatingProduct}</span>
        </div>
        {status === 'pastDue' ? (
          <div style={{ textAlign: 'center' }}>
            <span>
              <strong>
                Your {type === 'installments' ? 'payment plan' : 'subscription'} is past due. You will be charged{' '}
                {paymentString} immediately after changing your payment method.
              </strong>
            </span>
          </div>
        ) : null}

        {thisCard.map(paymentMethod => {
          const { brand, expMonth, expYear, id, last4 } = paymentMethod || {};

          return brand === 'paypal' ? null : (
            <React.Fragment>
              <div style={{ margin: '16px 0 8px 0' }}>
                <span>
                  {brand} card ending {last4}
                </span>
              </div>
              <Card className={updateModalUserPaymentMethod} key={id} style={{ padding: '16px 24px 16px 8px' }}>
                <div>
                  <span>Update expiration date and/or CVV:</span>
                </div>
                <div style={{ marginTop: '8px' }}>
                  <Container fluid className={profileBraintreeFormContainer}>
                    <Row>
                      <Col xs={6} style={{ padding: 0 }}>
                        <ExpirationDateField expirationDateValidity={this.state} isExisting={true} />
                      </Col>
                      <Col xs={6} style={{ padding: 0 }}>
                        <CVVField cvvValidity={this.state} isExisting={true} />
                      </Col>
                    </Row>
                  </Container>
                </div>
                <div className={updateExistingModalButtons} style={{ textAlign: 'center' }}>
                  <Button
                    id="updateExistingCreditCard"
                    disabled={disableUpdateCardButton}
                    color="primary"
                    style={{ marginBottom: '8px' }}
                  >
                    Update
                  </Button>
                </div>
              </Card>
            </React.Fragment>
          );
        })}

        <div style={{ margin: '16px 0 8px 0' }}>
          <span>Enter a new card:</span>
        </div>

        {isOpen && (
          <Card style={{ padding: '16px 24px 16px 8px' }}>
            <BraintreeForm braintreeValidity={this.state} isExisting={false} />

            <div style={{ textAlign: 'center' }} className={updateNewModalButtons}>
              <Button
                id="addNewCreditCard"
                disabled={disableNewCardButton}
                color="primary"
                style={{ marginBottom: '8px' }}
              >
                Save
              </Button>
              <Button color="purple" onClick={this.close} style={{ marginBottom: '8px' }}>
                Cancel
              </Button>
            </div>
          </Card>
        )}

        <div style={{ margin: '36px 0 8px 0' }}>
          <span>Or select from the following payment options:</span>
        </div>
        <div style={{ padding: '16px', backgroundColor: 'rgba(0, 0, 0, 0.03)', borderRadius: '4px' }}>
          <div style={{ marginBottom: '16px' }}>
            <div id="paypal-button" />
          </div>
          <div style={{ margin: '16px 0 8px 0' }}>
            <span>Existing payment options:</span>
          </div>

          {UserPaymentMethods.map(paymentMethod => {
            const { brand, expMonth, expYear, id, last4 } = paymentMethod || {};

            return (
              <Card className={updateModalUserPaymentMethod} key={id}>
                <div>
                  <span>{brand === 'paypal' ? 'PayPal Billing Agreement' : `${brand} card ending ${last4}`}</span>
                </div>
                {brand !== 'paypal' ? (
                  <div style={{ marginTop: '8px' }}>
                    <span>
                      Expires {expMonth}/{String(expYear).slice(2)}{' '}
                    </span>
                  </div>
                ) : null}
                {id !== updatingPaymentMethodId ? (
                  <div className={updateExistingModalButtons} style={{ marginTop: '8px' }}>
                    <Button onClick={this.selectExistingPaymentMethod(id)} color="purple">
                      Select
                    </Button>
                  </div>
                ) : (
                  <div>
                    <span style={{ color: 'green' }}>Current payment method</span>
                  </div>
                )}
              </Card>
            );
          })}
        </div>

        <div style={{ textAlign: 'right', marginTop: '24px' }} className={updateExistingModalButtons}>
          <Button color="purple" onClick={this.close}>
            Cancel
          </Button>
        </div>
      </div>
    );
  };

  renderProcessing = () => {
    return (
      <div className={updateModalStateContainer}>
        <div>
          <span>One moment while we process your request.</span>
        </div>
      </div>
    );
  };

  renderSuccess = product => {
    return (
      <div className={updateModalStateContainer}>
        <div>
          <span>Success! Your payment preference has been updated.</span>
        </div>
        <div>
          <span>Your change will be reflected in your next payment for {product}.</span>
        </div>
        <div style={{ textAlign: 'center', marginTop: '24px' }} className={updateModalStateButtons}>
          <Button color="primary" onClick={this.close}>
            Ok
          </Button>
        </div>
      </div>
    );
  };

  renderError = message => {
    return (
      <div className={updateModalStateContainer}>
        {message ? (
          <div>
            <span>{message}</span>
          </div>
        ) : (
          <div>
            <div>
              <span>Oh no! We were not able to process your request.</span>
            </div>
            <div>
              <span>Please call support to complete your payment update.</span>
            </div>
          </div>
        )}
        <div style={{ textAlign: 'right', marginTop: '24px' }} className={updateModalStateButtons}>
          <Button color="purple" onClick={this.close}>
            Ok
          </Button>
        </div>
      </div>
    );
  };

  renderDuplicate = () => {
    return (
      <div className={updateModalStateContainer}>
        <div>
          <span>We did not update the expiration date on your payment method.</span>
        </div>
        <div>
          <span>{this.state.updateStatus}</span>
        </div>
        <div style={{ textAlign: 'right', marginTop: '24px' }} className={updateModalStateButtons}>
          <Button color="purple" onClick={this.close}>
            Ok
          </Button>
        </div>
      </div>
    );
  };

  render() {
    const { isOpen, updatingProduct } = this.props;
    const { error, errorMessage, update, success, uiDisabled } = this.state;

    return (
      <Modal onClosed={this.reset} isOpen={isOpen} centered>
        {!success && !error ? this.renderModalContainer() : null}
        {success ? this.renderSuccess(updatingProduct) : null}
        {uiDisabled && !error && !update ? this.renderProcessing() : null}
        {error ? this.renderError(errorMessage) : null}
        {update ? this.renderDuplicate() : null}
      </Modal>
    );
  }
}

UpdateModal.propTypes = {};
//   isOpen: PropTypes.bool.isRequired,
//   updatingNumber: PropTypes.number.isRequired,
//   toggleUpdate: PropTypes.func.isRequired,
//   UserPaymentMethods: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired
// };

export default UpdateModal;
