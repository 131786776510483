import React, { Component } from 'react';
import { Button, InputGroup, Input } from 'reactstrap';
import * as styles from 'styles/index.scss';

const { resetFormContainer, resetFormBack } = styles;

class RegistrationForm extends Component {
  constructor(props) {
    super(props);
    this.state = { email: this.props.email, message: '', linkSent: false, inFocus: null };
  }

  handleFieldChange = e => this.setState({ [e.target.name]: e.target.value, linkSent: false });

  toggleFocus = e => {
    if (this.state.inFocus !== e.target.name) {
      this.setState({ inFocus: e.target.name });
    } else {
      this.setState({ inFocus: null });
    }
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.sendMagicLink(this.state.email).then(response => {
      if (response) {
        this.setState({
          linkSent: true,
          message:
            'Link sent. Please check your email right away. This link will expire in 30 minutes from when we send it.'
        });
      } else {
        this.setState({ linkSent: false, message: 'Sorry!  There was a problem on our end.  Please try again.' });
      }
    });
  };

  handleKeyDown = e => {
    if (e.key === 'Enter') {
      this.handleSubmit(e);
    }
  };

  render() {
    return (
      <div className={resetFormContainer}>
        <h4 style={{ textAlign: 'center' }}>Find your account</h4>
        <p>Tell us your email address and we'll get you signed in.</p>
        <InputGroup>
          <Input
            id="magicEmail"
            className="login-input"
            type="email"
            name="email"
            placeholder={this.state.inFocus === 'email' ? '' : 'Email'}
            value={this.state.email}
            onChange={this.handleFieldChange}
            onKeyDown={this.handleKeyDown}
            onFocus={this.toggleFocus}
            onBlur={this.toggleFocus}
          />
        </InputGroup>

        {this.state.message.length ? (
          <div style={{ marginLeft: '-16px', textAlign: 'center' }}>
            <span>{this.state.message}</span>
          </div>
        ) : null}

        {!this.state.linkSent ? (
          <Button
            size="lg"
            block
            style={{ width: '100%' }}
            onClick={this.handleSubmit}
            disabled={
              !this.state.email ||
              !document.getElementById('magicEmail') ||
              !document.getElementById('magicEmail').validity.valid
            }
          >
            Send me a login link
          </Button>
        ) : null}

        <div className={resetFormBack}>
          {!this.state.linkSent ? <span onClick={() => this.props.openModal('login')}>Back</span> : null}
          {this.state.linkSent ? <span onClick={this.props.hideModal}>Close</span> : null}
        </div>
      </div>
    );
  }
}

export default RegistrationForm;
