/* eslint-disable */
import Api from 'lib/api';
import React, { Component } from 'react';
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';

import PropTypes from 'prop-types';
import { Button, Card, CardBody, CardHeader, CardText, Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Markdown from 'routes/Products/components/Markdown';
import CountdownTimer from 'routes/Products/components/CountdownTimer';
import Video from 'routes/Products/components/Video';
import * as styles from 'styles/index.scss';
const { zoomWebinarContainer, videoResourceDownload } = styles;

class ZoomWebinar extends Component {
  constructor(props) {
    super(props);
    const currTime = new Date().getTime();
    const { startTime, endTime } = props.data;
    this.tourRefs = {
      menu: React.createRef()
    };
    this.state = {
      inProgress: currTime >= startTime && currTime < endTime,
      complete: currTime >= endTime,
      run: false,
      steps: [],
      stepIndex: 0
    };
    this.video = props.Children.find(child => child.type === 'video');
    this.downloads = props.Children.filter(child => child.type === 'download');
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    console.log('tourRefs', this.tourRefs);
    this.setState({
      run: true,
      steps: [
        {
          target: 'body',
          content: 'Hello World',
          placement: 'center'
        },
        {
          target: this.tourRefs.menu.current,
          content: (
            <div>
              You can interact with your own components through the spotlight.
              <br />
              Click the menu above!
            </div>
          ),
          title: 'Menu'
        }
      ]
    });
  }
  downloadIcon = type => {
    switch (type) {
      case 'MP3':
        return ['far', 'file-audio'];
      case 'PDF':
        return ['far', 'file-pdf'];
      default:
        return ['far', 'file'];
    }
  };

  handleJoyrideCallback = data => {
    const { sidebarOpen } = this.state;
    const { action, index, type, status } = data;

    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      this.setState({ run: false, stepIndex: 0 });
    }

    console.groupCollapsed(type === EVENTS.TOUR_STATUS ? `${type}:${status}` : type);
    console.log(data); //eslint-disable-line no-console
    console.groupEnd();
  };

  setRef = (name, el) => {
    console.log('setRef', name, el);
    if (!el) return;
    this.tourRefs[name] = el;
    console.log(this.tourRefs);
  };

  render() {
    console.log(this);
    const {
      data: { startTime, zoomId, description, img },
      title,
      Children
    } = this.props;
    const { complete, inProgress, run, steps } = this.state;
    const { video, downloads } = this;
    return (
      <Container className={zoomWebinarContainer}>
        <ReactJoyride
          callback={this.handleJoyrideCallback}
          continuous
          run={run}
          scrollToFirstStep
          showProgress
          showSkipButton
          steps={steps}
          styles={{
            options: {
              zIndex: 10000
            }
          }}
        />
        <Row>
          <Col>
            <Card>
              <CardHeader data-name="menu">
                <div className="float-right pl-5" />
                <h4 ref={this.tourRefs.menu}>{title}</h4>
              </CardHeader>
              <CardBody>
                <CardText>
                  {complete && video ? (
                    <Container fluid>
                      <Row>
                        <Col>
                          <Video save {...video} />
                        </Col>
                      </Row>
                      <Row className="d-flex align-items-center">
                        {img ? (
                          <Col xs="12" md="3">
                            <img src={img.url} alt={img.alt} className="img-responsive" />
                          </Col>
                        ) : null}
                        <Col xs="12" md={img ? '9' : '12'}>
                          <Markdown source={description} />
                        </Col>
                      </Row>
                      {downloads ? (
                        <Row className="d-flex justify-content-center">
                          {this.downloads.map(download => (
                            <Col className={videoResourceDownload} xs="auto">
                              <a href={download.data.url} download>
                                <FontAwesomeIcon icon={this.downloadIcon(download.data.type)} />
                                <div>
                                  <span>{download.data.text}</span>
                                </div>
                              </a>
                            </Col>
                          ))}
                        </Row>
                      ) : null}
                    </Container>
                  ) : (
                    <Container fluid>
                      <Row className="d-flex align-items-center">
                        <Col xs="12">
                          <h5>About the Webinar</h5>
                        </Col>
                        <Col xs="12" md={img ? '8' : '12'}>
                          <Markdown source={description} />
                        </Col>
                        {img ? (
                          <Col xs="12" md="4">
                            <img src={img.url} alt={img.alt} className="img-responsive" />
                          </Col>
                        ) : null}
                      </Row>
                      {complete ? (
                        <Row className="mt-5 mb-3">
                          <Col>
                            <h3>The webinar is complete, a replay will be available on this page shortly.</h3>
                          </Col>
                        </Row>
                      ) : null}
                      <Row className="my-2">
                        <Col>
                          <h5>How to Join</h5>
                          <p>
                            <strong>To access via computer, tablet or other mobile device:</strong>
                          </p>
                          <p>
                            Download the latest Zoom.us webinar client from{' '}
                            <a href="https://zoom.us/support/download" target="_blank">
                              https://zoom.us/support/download
                            </a>
                          </p>
                          <p>
                            The webinar can be accessed using the following link:{' '}
                            <a href={`https://zoom.us/j/${zoomId}`} target="_blank">
                              https://zoom.us/j/{zoomId}
                            </a>
                          </p>
                          <p>
                            <strong>To access via telephone:</strong>
                          </p>
                          <p>
                            iPhone one-tap: US: <a href={`tel:+16699006833,,${zoomId}#`}>+16699006833,,{zoomId}#</a> or{' '}
                            <a href={`tel:+16465588656,,${zoomId}#`}>+16465588656,,{zoomId}#</a>
                          </p>
                          <p>
                            Telephone: (for higher quality, dial a number based on your current location): US: +1 669
                            900 6833 or +1 646 558 8656 Webinar ID: {zoomId} <br />
                            International numbers available:{' '}
                            <a href="https://zoom.us/u/acUOQF2Euh">https://zoom.us/u/acUOQF2Euh</a>
                          </p>
                        </Col>
                      </Row>
                      <Row className="my-2">
                        <Col>
                          <h5>How to Ask Questions</h5>
                          <p>
                            You'll be able to ask questions during the event using the chat and webinar Q&A feature.
                            Please visit{' '}
                            <a
                              href="https://support.zoom.us/hc/en-us/articles/115004954946-Joining-a-Webinar-Attendee-"
                              target="_blank"
                            >
                              Zoom's support page here
                            </a>{' '}
                            if you would like to learn more about how to use the webinar software.
                          </p>
                        </Col>
                      </Row>
                      <Row className="my-5">
                        <Col xs="12" md={{ size: 8, offset: 2 }}>
                          {!complete && !inProgress
                            ? [
                                <h4 className="text-center">The webinar starts in:</h4>,
                                <CountdownTimer
                                  time={startTime}
                                  onComplete={() => this.setState({ inProgress: true })}
                                />
                              ]
                            : inProgress
                            ? [
                                <h4 className="text-center">The webinar is in progress.</h4>,
                                <Button size="lg" block href={`https://zoom.us/j/${zoomId}`} target="_blank">
                                  Join Now
                                </Button>
                              ]
                            : null}
                        </Col>
                      </Row>
                    </Container>
                  )}
                </CardText>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

ZoomWebinar.propTypes = {
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  Children: PropTypes.arrayOf(PropTypes.shape({})),
  data: PropTypes.shape({})
};

ZoomWebinar.defaultProps = {
  data: { startTime: null, endTime: null, description: '' }
};

ZoomWebinar.displayname = 'ZoomWebinar';

export default ZoomWebinar;
