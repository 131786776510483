import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import Video from 'routes/Products/components/Video';
import * as styles from 'styles/index.scss';

const { landingContainer } = styles;

class Landing extends PureComponent {
  constructor(props) {
    super(props);

    this.Children = {};
    props.Children &&
      props.Children.map(child => {
        this.Children[`${child.type}`] = child;
      });

    // console.log('this.Children in course: ', this.Children);
  }

  render() {
    if (this.props.data) {
      const {
        title,
        data: {
          optInText,
          smsText,
          details,
          eventDetails
          // description,
        }
      } = this.props;
    }
    const {
      // daysList,
      video
    } = this.Children;

    return this.props.data ? (
      <div className={landingContainer}>
        <div>
          <h1>{title}</h1>
        </div>

        {video ? <Video save {...video} /> : null}

        <div>
          <span>Details: {details} </span>
        </div>

        <div>
          <Button>{smsText}</Button>
        </div>
      </div>
    ) : (
      <div>Nothing to see here yet...</div>
    );
  }
}

Landing.propTypes = {
  Children: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  data: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired
};

export default Landing;
