/* eslint-disable max-len */

import React, { PureComponent, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { actions } from 'modules/user';
import ChecklistItem from './components/ChecklistItem';
import { CardHeader, Col, Row } from 'reactstrap';
import * as styles from 'styles/index.scss';
import track from '../../../../lib/track';

const ROOT_URL = process.env.ROOT_URL;

const { checklistContainer, checklistContainerReadOnly, checklistItemsStyles, checklistTitle } = styles;

class Checklist extends PureComponent {
  constructor(props) {
    super(props);

    const {
      data: { checklistItems },
      id
    } = props; // is this right?

    // id is contentItem id for the checklist
    this.id = id;
    const initialValues = {};
    checklistItems.map(checklistItem => {
      initialValues[checklistItem.name] = false;
    });
    // this will be rewritten if there is stored userState data
    this.state = {
      start: {
        timeCompleted: null,
        values: initialValues
      },
      end: {
        timeCompleted: null,
        values: initialValues
      }
    };
  }

  componentDidMount() {
    if (!this.props.test) {
      this.getChecklistResponses();
    }
  }

  async getChecklistResponses() {
    const savedData = await this.props.get(this.id);

    if (savedData) {
      this.setState({ ...savedData });
      // optional - only if parent needs updating on complete, ie onboard
      if (savedData.complete && this.props.handleComplete) {
        this.props.handleComplete(this.id);
      }
    } else {
      // No saved values - do something?
    }
  }

  isStartOrEnd = () => {
    if (!this.state.start.timeCompleted) {
      return 'start';
    }
    return 'end';
  };

  markCompleteByIndex(idx) {
    const {
      data: { checklistItems }
    } = this.props;
    this.updateValue(checklistItems[idx].name);
  }

  updateValue = (name, e) => {
    if (e) {
      e.preventDefault();
    }

    const { userAnalytics } = this.props;

    track(
      'Checklist',
      userAnalytics ? { ...userAnalytics, ...{ ux: 'checked' } } : { service: 'default', ux: 'checked' }
    );

    if (e && e.target && e.target.href) {
      window.location = e.target.href;
    } else {
      const newState = {
        ...this.state,
        [this.isStartOrEnd()]: {
          ...this.state[this.isStartOrEnd()],
          values: { ...this.state[this.isStartOrEnd()].values, [name]: !this.state[this.isStartOrEnd()].values[name] }
        }
      };
      // optional - if this is an onboard
      if (typeof this.props.handleComplete === 'function') {
        let isComplete = true;
        const fields = newState.start.values;
        for (let field in fields) {
          if (!fields[field]) {
            isComplete = false;
            break;
          }
        }

        if (isComplete) {
          this.props.handleComplete(this.id);
          newState.complete = true;
          track(
            'Checklist',
            userAnalytics ? { ...userAnalytics, ...{ ux: 'completed' } } : { service: 'default', ux: 'completed' }
          );
        }
      }

      this.setState(newState);

      this.props.post(this.id, newState);

      //update the any parent wanting progress report on this checklist

      const checkboxProgress = Object.values(newState.start.values);

      const finished = checkboxProgress.filter(item => item);
      //{id: 888888894, numCompleted: 0, numPossible: 0}
      //parentID is the moduleID that this checklist belongs to

      this.props.updateModuleStates && this.props.parentID
        ? this.props.updateModuleStates(this.props.parentID, null, {
            id: this.id,
            numCompleted: finished.length,
            numPossible: checkboxProgress.length
          })
        : null;
    }
  };

  render() {
    const {
      title,
      overrideTitle,
      readonly,
      HeaderWrapperTag = React.Fragment,
      data: { checklistItems }
    } = this.props;

    //values are userState of the checklist
    //mapped to the contentItem of checklist using the name of the checklist item
    const { values } = this.state[this.isStartOrEnd()];
    let classes = [checklistContainer];

    if (readonly) {
      classes.push(checklistContainerReadOnly);
    }

    const checkboxProgress = Object.values(values);
    const finished = checkboxProgress.filter(item => item);
    return (
      <React.Fragment>
        <HeaderWrapperTag>
          <Row className="align-items-center">
            <Col>
              <h4>{overrideTitle || 'Checklist'}</h4>
            </Col>
            <Col xs="12" md="auto">
              {finished.length}/{checkboxProgress.length} completed
            </Col>
          </Row>
        </HeaderWrapperTag>
        {checklistItems.map((checklistItem, index) => (
          <ChecklistItem
            key={checklistItem.name}
            isLast={index === checklistItems.length - 1}
            value={values[checklistItem.name] || false}
            change={!readonly ? this.updateValue : () => null}
            post={this.postChecklistResponses}
            key={checklistItem.name}
            {...checklistItem}
          />
        ))}
      </React.Fragment>
    );
  }
}

Checklist.propTypes = {
  data: PropTypes.shape({}).isRequired,
  get: PropTypes.func.isRequired,
  id: PropTypes.number,
  post: PropTypes.func.isRequired,
  test: PropTypes.bool,
  title: PropTypes.string.isRequired
};

Checklist.defaultProps = {
  id: 88888888,
  test: false
};

const mapDispatchToProps = dispatch => ({
  get: contendId => dispatch(actions.getUserData(contendId)),
  post: (contentId, data) => dispatch(actions.postUserData(contentId, data))
});

const ConnectedChecklist = connect(null, mapDispatchToProps, null, { forwardRef: true })(Checklist);

export default ConnectedChecklist;
