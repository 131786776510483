/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'lib/react-markdown';
import { Button, Col, Container, Row } from 'reactstrap';
import Tag from 'components/Tag';
import Video from 'routes/Products/components/Video';
import * as styles from 'styles/index.scss';

const {
  wlcVideoContainer,
  wlcVideoRow,
  wlcVideoButtons,
  wlcVideoTags,
  wlcVideoTitleText,
  wlcVideoDescriptionText
} = styles;

class WLCVideo extends Component {
  render() {
    return (
      <React.Fragment>
        <h4>Action of the Week</h4>
        <Row>
          <Col xs={12} lg={7}>
            <Video save {...this.props} />
          </Col>
          <Col xs={12} lg={5}>
            <ReactMarkdown source={this.props.data.description} />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

WLCVideo.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
  // title: PropTypes.string.isRequired
};

export default WLCVideo;
