/* eslint-disable */
import Api from 'lib/api';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Data from 'containers/Data';
import * as styles from 'styles/index.scss';

import { Container, Row, Col, Button } from 'reactstrap';
import Video from 'routes/Products/components/Video';
import VideoPlayList from 'routes/Products/components/VideoPlayList';

import AudioPlayerMobile from 'routes/Products/components/AudioPlayerMobile';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/pro-regular-svg-icons/faFilePdf';
import { faFileAudio } from '@fortawesome/pro-regular-svg-icons/faFileAudio';
import testJSON from './videos.json'; //used to for testing
const { expander } = styles;
import recipesListTest from '../../realData/recipeListData';
import RecipesGrid from '../../../../components/RecipesGrid';

@connect(state => ({ user: state.user }), null)

//Displays the whole DAY
//InterviewContentItem, Replay
class SummitBroadcastDay extends Component {
  constructor(props) {
    super(props);

    this.state = { day: this.props.day };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const day = this.props.day; //one summit broadcast day
    const interviewCollectionItems = day.Children.filter(item => {
      return item.type === 'InterviewCollectionItem';
    });

    const recipesList = day.Children.find(item => item.type === 'recipesList');

    // let summitReplay = day.Children.filter(item => {
    //   return item.type === 'SummitReplay';
    // })[0]; //only one

    //TODO clean up so better synced with current data model,  originally design to have a SummitReplay content item
    let summitReplay =
      day.data && day.data.video
        ? {
            title: day.title,
            data: {
              title: day.title,
              description: day.data.replayDescription,
              archive: day.data.archive,
              videos: [
                {
                  data: {
                    url: day.data.video
                  },
                  title: `Replay ${day.title}: ${day.name.split('-')[1]}`
                }
              ]
            }
          }
        : null;
    if (day.data.dailyWrapUrl)
      summitReplay.data.videos.push({
        data: { url: day.data.dailyWrapUrl },
        title: 'Daily Wrap with Ocean Robbins'
      });
    // const downloadsList = summitReplay.Children.find(item => {
    //   return item.type === 'downloadsList';
    // });

    //UPDATED TO PUT THE DOWNLOAD INFO INSIDE THE SUMMITBROADCAST content item instead of a child of it
    let mp3Download =
      day.data && day.data.audioDownload
        ? {
            data: {
              type: 'MP3',
              url: day.data.audioDownload,
              text: day.name + ' MP3 - Download'
            }
          }
        : null;

    /*
     * This isn't right at all, as an audioDownload attribute
     * shouldn't lead to a PDF download
     * I'm going to comment this out in case we want it in the future
    let transcriptDownload =
      day.data && day.data.audioDownload
        ? {
            data: {
              type: 'PDF',
              url: day.data.audioDownload,
              text: day.name + ' PDF - Download'
            }
          }
        : null;
    */

    let archiveDownload =
      day.data && day.data.archive
        ? {
            data: {
              type: 'ZIP',
              url: day.data.archive,
              text: day.name + ' ZIP - Download'
            }
          }
        : null;
    const downloads = [];
    mp3Download ? downloads.push(mp3Download) : null;
    //transcriptDownload ? downloads.push(transcriptDownload) : null;
    archiveDownload ? downloads.push(archiveDownload) : null;

    // const downloads = downloadsList.Children.filter(item => {
    //   return item.data && item.data.type === 'MP3' && item.type === 'download';
    // });
    //may put this back in
    const downloadsList = [];
    let videos = day.data.videos ? day.data.videos : null; //populates the vidoes at top of page, also handles downloads (FOR NOW TODO - workaround)
    //videos = testJSON.videos; //for testing
    this.setState({ interviewCollectionItems, summitReplay, downloadsList, downloads, videos, recipesList });
  }

  render() {
    let { videos, recipesList } = this.state;
    // recipesList = recipesListTest;
    return (
      <Container>
        <Row>
          <Col sm="12">
            <h3>
              {' '}
              {this.state.summitReplay ? this.state.summitReplay.title + ': ' : null}{' '}
              {/* data massaging, example: EP18-Vitality and Longevity to Vitality and Longevity*/}
              {this.state.day ? this.state.day.name.split('-')[1] : null}
            </h3>
          </Col>
        </Row>
        <Row className="my-5 EPWrapper">
          {videos ||
          (this.state.summitReplay && this.state.summitReplay.data && this.state.summitReplay.data.videos) ? (
            <VideoPlayList videoArray={this.state.summitReplay.data.videos} videoArrayV2={videos} />
          ) : null}
        </Row>
        {/* if there are videos from new format, the downloads are handled above */}
        {videos ? null : (
          <Row className="mt-2 EPWrapper">
            <Col sm="12">
              {this.state.downloads
                ? this.state.downloads.map(item => {
                    return (
                      <a href={item.data.url} download>
                        <div className="btn btn-secondary Day-button">
                          {/* Download Day XX Replay MP3  */}
                          <FontAwesomeIcon icon={item.data.type === 'MP3' ? faFileAudio : faFilePdf} />{' '}
                          {/*` Download ${
                            this.props.day
                              ? this.props.day.title.charAt(0).toUpperCase() +
                                this.props.day.title.slice(1).toLowerCase()
                              : 'Day'
                          } Replay MP3`*/
                          ` Download ${item.data.text}`}
                        </div>
                      </a>
                    );
                  })
                : 'Downloads Loading'}
            </Col>
          </Row>
        )}

        <Row className="mt-2 EPWrapper">
          <Col sm="12">
            {/* {this.state.summitReplay ? this.state.summitReplay.title : null}{' '}
            {this.state.summitReplay ? this.state.summitReplay.data.description : null} */}
          </Col>
        </Row>
        {videos ? null : (
          <Row className="mt-2 EPWrapper">
            <Col sm="12">
              {this.state.summitReplay && this.state.summitReplay.data ? (
                <a className="py-5" href={this.state.summitReplay.data.archive} download>
                  Click here to download all zip files
                </a>
              ) : null}
            </Col>
          </Row>
        )}

        <Row className="mt-2 EPWrapper">
          <Col sm="12">
            {/* AudioPlayerMobile takes an interviewCollectionItem */}
            {this.state.interviewCollectionItems ? (
              this.state.interviewCollectionItems.map(interviewCollectionItem => {
                return <AudioPlayerMobile save {...interviewCollectionItem} />;
              })
            ) : (
              <div>This day is currently not available. Please check back soon. </div>
            )}
          </Col>
        </Row>
        <Row className="mt-2 EPWrapper">
          <Col sm="12">
            {recipesList ? (
              <RecipesGrid
                singleRecipeInUrl={null}
                disableRouter={false}
                disableViewAll={true}
                colSizes={{ xs: 6, sm: 4, md: 4 }}
                recipes={recipesList.Children}
              />
            ) : (
              ''
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

SummitBroadcastDay.propTypes = {
  id: PropTypes.number.isRequired
};

SummitBroadcastDay.displayname = 'SummitBroadcastDay';

export default withRouter(SummitBroadcastDay);
