/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'reactstrap';
import ReactMarkdown from 'lib/react-markdown';
import Stars from './components/Stars';
import Ingredients from './components/Ingredients';
import Directions from './components/Directions';
import Notes from './components/Notes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { actions } from 'modules/user';
import * as styles from 'styles/index.scss';
import { track, page } from '../../../../lib/track';
//minutesToHours used for cookTime and totalTime displays
import { minutesToHours } from '../../../../lib/time'; //set up shorthand for long imports?
import Video from '../../../Products/components/Video';
import CookMode from './components/CookMode';
import Favorites from '../../components/Favorites';
const {
  recipeContainer,
  recipeImage,
  recipeMenuBar,
  recipeMenuBars,
  recipeMenuIcon,
  recipeMenuStarsAndBars,
  recipeMenuAllergens,
  recipeMenuAllergensTitle,
  recipeMetaTitle,
  recipeMetaValue,
  recipeServingsArrow,
  recipeStars,
  recipeTitleRow,
  recipePrintCol,
  recipePrintTitle,
  recipeIngredientsDirections
} = styles;

class RecipeContainer extends PureComponent {
  constructor(props) {
    super(props);
    const { id, data, slug, title } = props;

    const yieldString = data && data.yieldString ? data.yieldString : null;
    this.state = {
      servings: Number(yieldString),
      stars: 0,
      tempStars: 0,
      yieldString
    };

    this.id = id;
    this.userAnalytics = {
      service: 'recipe', //the kind of service, like recipe, weekly guide, etcs
      content_id: id,
      slug: slug,
      title,
      uuid: props.user && props.user.UUID
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.props.test) {
      this.getState();
    }

    page('recipe', this.userAnalytics);
  }

  async getState() {
    const savedData = await this.props.get(this.id);
    if (savedData) {
      this.setState({ ...this.state, ...savedData });
    } else {
      // No saved values - do something?
    }
  }

  trackLink = link => {
    //maybe add some info about the link?
    track('LINK_CLICK', this.userAnalytics);
  };

  postState = newState => {
    this.props.post(this.id, newState);
  };

  toggleHoverStar = tempStars => {
    this.setState({ tempStars });
  };

  handleClickStar = stars => {
    const newState = { ...this.state, stars, tempStars: 0 };
    this.setState(newState);
    this.postState(newState);
  };

  handleInputPortion = servings => e => {
    e.preventDefault();
    if (servings > 0) {
      const newState = { ...this.state, servings };
      this.setState(newState);
      this.postState(newState);
    }
  };

  handleClickPrint = () => {
    track('Print', this.userAnalytics, {}, res => {});

    try {
      let printCommand = document.execCommand('print', false, null);
      // zp .execCommand returns false on 'successful failure'
      if (!printCommand) window.print();
    } catch (e) {
      window.print();
    }
  };

  render() {
    if (!this.props.Children) {
      return <div>Recipe Loading...</div>;
    }
    const {
      data: {
        allergensUrls,
        description,
        prepTime,
        cookTime,
        totalTime,
        directions,
        recipeNotes,
        attribution,
        nutritionalFacts,
        image: { alt, url } = {}
      } = {},

      Children,
      childrenIndexes,

      title,
      id,
      slug,
      type
    } = this.props;
    const favoritesContent = { id, slug, type, displayProps: { title, image: { url } } }; //passed into the favorites button
    const { yieldString } = this.state;
    const ingredients = Children.filter(
      (item, i) => childrenIndexes && childrenIndexes.recipeIngredient.includes(i)
    ).map(item => ({
      ...item.data,
      name: item.Children[0].title,
      title: item.Children[0].title,
      url: item.Children[0].data && item.Children[0].data.url
    }));
    const video = Children.find(item => item.type === 'video');

    const { servings } = this.state;

    if (!ingredients) {
      return <div>Recipe, no data</div>;
    }

    const { stars, tempStars } = this.state;
    const displayStars = tempStars || stars;

    return (
      <div id="recipe" className={recipeContainer}>
        <div className={recipePrintTitle}>{title}</div>

        <div className={recipeImage} style={{ backgroundImage: `url(${url})`, content: `url(${url})` }} />
        <Container className={recipeMenuBar} fluid>
          <Container>
            <Row className={`${recipeTitleRow}`}>
              <Col xs={12} sm={10}>
                <h4>{title}</h4>
              </Col>
              <Col xs={12} sm={2} className={`${recipePrintCol}`}>
                <FontAwesomeIcon onClick={this.handleClickPrint} icon={['far', 'print']} />
              </Col>
            </Row>
            <Row className={recipeMenuStarsAndBars}>
              <Col xs={12} lg={3} className={recipeStars}>
                <Stars
                  toggleHoverStar={this.toggleHoverStar}
                  handleClickStar={this.handleClickStar}
                  stars={displayStars}
                />
              </Col>
              <Col xs={12} lg={9}>
                <Container className={recipeMenuBars}>
                  <Row>
                    <Col xs={6} sm={3}>
                      <div className={recipeMenuIcon}>
                        <FontAwesomeIcon onClick={this.handleClickPrint} icon={['frn', 'spoonForkCrossed']} />
                      </div>
                      <div>
                        <div className={recipeMetaTitle}>
                          <span>Yields</span>
                        </div>
                        <div className={recipeMetaValue}>
                          <span>{servings}&nbsp;Servings</span>
                          <FontAwesomeIcon
                            icon={['fas', 'arrow-alt-up']}
                            onClick={this.handleInputPortion(servings + 1)}
                            className={recipeServingsArrow}
                          />
                          <FontAwesomeIcon
                            icon={['fas', 'arrow-alt-down']}
                            onClick={this.handleInputPortion(servings - 1)}
                            className={recipeServingsArrow}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} sm={3}>
                      <div className={recipeMenuIcon}>
                        <FontAwesomeIcon icon={['far', 'clock']} />
                      </div>
                      <div>
                        <div className={recipeMetaTitle}>
                          <span>Prep Time</span>
                        </div>
                        <div className={recipeMetaValue}>
                          <span>{minutesToHours(prepTime, 'abbreviated')}</span>
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} sm={3}>
                      <div className={recipeMenuIcon}>
                        <FontAwesomeIcon icon={['far', 'clock']} />
                      </div>
                      <div>
                        <div className={recipeMetaTitle}>
                          <span>Cook Time</span>
                        </div>
                        <div className={recipeMetaValue}>
                          <span>{minutesToHours(cookTime, 'abbreviated')}</span>
                          &nbsp;
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} sm={3}>
                      <div className={recipeMenuIcon}>
                        <FontAwesomeIcon icon={['far', 'clock']} />
                      </div>
                      <div>
                        <div className={recipeMetaTitle}>
                          <span>Total Time</span>
                        </div>
                        <div className={recipeMetaValue}>
                          <span>{minutesToHours(totalTime, 'abbreviated')}</span>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
            </Row>
            {allergensUrls && [
              <Row className={recipeMenuAllergensTitle}>
                <Col>
                  <h5>Allergens</h5>
                </Col>
              </Row>,
              <Row className={recipeMenuAllergens}>
                <Col>
                  {allergensUrls.map(allergenImgUrl => (
                    <div style={{ backgroundImage: `url(${allergenImgUrl})` }} />
                  ))}
                </Col>
              </Row>
            ]}
            {description ? (
              <Row>
                <Col xs="12" lg="9">
                  <ReactMarkdown source={description} />
                </Col>
              </Row>
            ) : null}
            <Row className="d-flex justify-content-lg-between flex-column flex-lg-row">
              <Col xs="12" lg="6" className="text-left" style={{ marginLeft: '-15px' }}>
                <CookMode />
              </Col>
              <Col xs="12" lg="4" className="text-left mt-3 mt-lg-0">
                {favoritesContent ? <Favorites contentItem={favoritesContent} /> : null}
              </Col>
            </Row>
          </Container>
        </Container>

        {/*<Ingredients ingredients={ingredients} servings={servings} />*/}

        <Container className={recipeIngredientsDirections}>
          <Row>
            <Col xs={12} lg={6} style={{ marginBottom: '48px' }}>
              <h4>Ingredients</h4>
              <Ingredients ingredients={ingredients} currentServings={servings} originalServings={yieldString} />
              {nutritionalFacts ? (
                <div>
                  <h4>Nutritional facts:</h4>
                  <div>{nutritionalFacts}</div>
                </div>
              ) : null}
            </Col>
            <Col xs={12} lg={6} style={{ marginBottom: '48px' }}>
              <h4>Directions</h4>
              {directions ? (
                <Directions key="directionsLoaded" directions={directions} />
              ) : (
                <Directions key="directionsLoading" />
              )}
              {recipeNotes || attribution ? (
                <div onClick={this.trackLink}>
                  <h4 className="mt-5">Chef's Notes</h4> <Notes notes={recipeNotes} attribution={attribution} />
                </div>
              ) : null}
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col xs={12} style={{ marginBottom: '48px' }}>
              {video ? <h4>Cooking Demonstration</h4> : ''}
              {video ? <Video {...video} /> : ''}
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

RecipeContainer.propTypes = {
  data: PropTypes.shape({
    ingredients: PropTypes.arrayOf(PropTypes.shape({})).isRequired
  }).isRequired,
  get: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  post: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};
const mapStateToProps = state => ({
  user: state.user
});
const mapDispatchToProps = dispatch => ({
  get: contendId => dispatch(actions.getUserData(contendId)),
  post: (contentId, data) => dispatch(actions.postUserData(contentId, data))
});

export default connect(mapStateToProps, mapDispatchToProps)(RecipeContainer);
