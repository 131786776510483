/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { actions } from 'modules/application';
import { Container, Col, Collapse, Row } from 'reactstrap';
import { actions as applicationActions } from 'modules/application';
import * as styles from 'styles/index.scss';

const { accordionPageHeader } = styles;

@connect(state => ({ navigateTo: state.application.navigateTo }), { loadPublicProfile: actions.loadPublicProfile })
class Accordion extends Component {
  constructor(props) {
    super(props);
    this.state = { open: props.navigateTo && props.navigateTo.includes(props.title) };

    this.myRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.navigateTo.includes(this.props.title)) {
      this.scrollToDomRef();
    }
  }

  toggle = () => {
    this.setState({ open: !this.state.open });
  };

  scrollToDomRef = () => {
    window.scrollTo(0, this.myRef);
  };

  render() {
    const lastCollapseStyle = { boxShadow: '0 4px 2px -2px lightgrey' };

    return (
      <div style={this.props.isLast ? lastCollapseStyle : null} ref={this.myRef}>
        <div className={accordionPageHeader} onClick={this.toggle}>
          {this.props.title}
        </div>
        <Collapse isOpen={this.state.open}>
          <Container>
            <Row>
              <Col>
                <div style={{ paddingBottom: '8px', margin: '16px 0 32px 0' }}>
                  {this.props.sections.map(renderComponent => renderComponent())}
                </div>
                <div style={{ textAlign: 'center', cursor: 'pointer', lineHeight: '2.5' }} onClick={this.toggle}>
                  <span>
                    <b>X</b>
                  </span>
                </div>
              </Col>
            </Row>
          </Container>
        </Collapse>
      </div>
    );
  }
}

Accordion.propTypes = {
  isLast: PropTypes.bool.isRequired,
  sections: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  title: PropTypes.string.isRequired
};

export default Accordion;
